import { createRoot } from "react-dom/client";
import { useHereMap } from "map/Context";
import {
  Position,
  decode as decodePolyline,
  encode as encodePolyline,
} from "util/flexible-polyline";
import React from "react";
import { useAppDispatch } from "stores/hooks";
import { addDestination, addOrigin } from "stores/routing";
import { setEditedAvoidArea } from "stores/mapGrid";
import { IAvoidArea } from "stores/types";
import { AvoidAreaInfoBubbleContent } from "./AvoidAreaInfoBubble";
import _ from "lodash";

let linkDataInfoBubble: H.ui.InfoBubble;

const AvoidAreaRect: React.FC<{
  avoidArea: IAvoidArea;
  editable?: boolean;
}> = ({ avoidArea, editable = false }) => {
  const dispatch = useAppDispatch();
  const { ui, map, behavior } = useHereMap();
  React.useEffect(() => {
    let mapRectangle = createResizableRect();

    mapRectangle.addEventListener(
      "tap",
      createTapLinkHandlerRect(mapRectangle)
    );

    const rectangle = H.geo.LineString.fromFlexiblePolyline(
      avoidArea.coordinates
    );
    const points = rectangle.getLatLngAltArray();
    const rectPoints = [points[0], points[4], points[3], points[1]];
    if (rectPoints.some((val) => val === 0)) {
      mapRectangle = new H.map.Group();
    }
    map?.addObject(mapRectangle);

    return () => {
      map?.removeObject(mapRectangle);
      linkDataInfoBubble?.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avoidArea]);

  const createResizableRect = () => {
    const rectangle = H.geo.LineString.fromFlexiblePolyline(
      avoidArea.coordinates
    );
    const points = rectangle.getLatLngAltArray();
    const rectPoints = [points[0], points[4], points[3], points[1]];

    const rect = new H.map.Rect(
      new H.geo.Rect(
        rectPoints[0],
        rectPoints[1],
        rectPoints[2],
        rectPoints[3]
      ),
      {
        data: {},
        style: { fillColor: "rgba(100, 100, 100, 0.5)", lineWidth: 0 },
      }
    );
    var //@ts-ignore
      rectOutline = new H.map.Polyline(rect.getGeometry().getExterior(), {
        data: {},
        style: {
          lineWidth: 8,
          strokeColor: "rgba(255, 0, 0, 0)",
          fillColor: "rgba(0, 0, 0, 0)",
          lineCap: "square",
        },
      }),
      rectGroup = new H.map.Group({
        data: {},
        volatility: true, // mark the group as volatile for smooth dragging of all it's objects
        objects: [rect, rectOutline],
      }),
      rectTimeout: any;

    // ensure that the objects can receive drag events
    rect.draggable = true;
    rectOutline.draggable = true;

    // extract first point of the rect's outline polyline's LineString and
    // push it to the end, so the outline has a closed geometry
    rectOutline
      .getGeometry()
      //@ts-ignore
      .pushPoint(rectOutline.getGeometry().extractPoint(0));

    // event listener for rectangle group to show outline (polyline) if moved in with mouse (or touched on touch devices)
    rectGroup.addEventListener(
      "pointerenter",
      function () {
        var currentStyle = rectOutline.getStyle(),
          newStyle = currentStyle.getCopy({
            strokeColor: "rgb(255, 0, 0)",
          });

        if (rectTimeout) {
          clearTimeout(rectTimeout);
          rectTimeout = null;
        }
        // show outline
        rectOutline.setStyle(newStyle);
      },
      true
    );

    // event listener for rectangle group to hide outline if moved out with mouse (or released finger on touch devices)
    // the outline is hidden on touch devices after specific timeout
    rectGroup.addEventListener(
      "pointerleave",
      function (evt: any) {
        var currentStyle = rectOutline.getStyle(),
          newStyle = currentStyle.getCopy({
            strokeColor: "rgba(255, 0, 0, 0)",
          }),
          timeout = evt.currentPointer.type === "touch" ? 1000 : 0;

        rectTimeout = setTimeout(function () {
          rectOutline.setStyle(newStyle);
        }, timeout);

        document.body.style.cursor = "default";
      },
      true
    );

    // event listener for rectangle group to change the cursor if mouse position is over the outline polyline (resizing is allowed)
    rectGroup.addEventListener(
      "pointermove",
      function (evt: any) {
        var pointer = evt.currentPointer,
          objectTopLeftScreen = map?.geoToScreen(
            evt.target.getGeometry().getBoundingBox().getTopLeft()
          ),
          objectBottomRightScreen = map?.geoToScreen(
            evt.target.getGeometry().getBoundingBox().getBottomRight()
          ),
          draggingType = "";

        // only set cursor and draggingType if target is outline polyline
        if (evt.target !== rectOutline) {
          return;
        }

        // change document cursor depending on the mouse position
        if (pointer.viewportX < objectTopLeftScreen!.x + 4) {
          document.body.style.cursor = "ew-resize"; // mouse position is at left side
          draggingType = "left";
        } else if (pointer.viewportX > objectBottomRightScreen!.x - 4) {
          document.body.style.cursor = "ew-resize"; // mouse position is at right side
          draggingType = "right";
        } else if (pointer.viewportY < objectTopLeftScreen!.y + 4) {
          document.body.style.cursor = "ns-resize"; // mouse position is at top side
          draggingType = "top";
        } else if (pointer.viewportY > objectBottomRightScreen!.y - 4) {
          document.body.style.cursor = "ns-resize"; // mouse position is at the bottom side
          draggingType = "bottom";
        } else {
          document.body.style.cursor = "default";
        }

        if (draggingType === "left") {
          if (pointer.viewportY < objectTopLeftScreen!.y + 4) {
            document.body.style.cursor = "nwse-resize"; // mouse position is at the top-left corner
            draggingType = "left-top";
          } else if (pointer.viewportY > objectBottomRightScreen!.y - 4) {
            document.body.style.cursor = "nesw-resize"; // mouse position is at the bottom-left corner
            draggingType = "left-bottom";
          }
        } else if (draggingType === "right") {
          if (pointer.viewportY < objectTopLeftScreen!.y + 4) {
            document.body.style.cursor = "nesw-resize"; // mouse position is at the top-right corner
            draggingType = "right-top";
          } else if (pointer.viewportY > objectBottomRightScreen!.y - 4) {
            document.body.style.cursor = "nwse-resize"; // mouse position is at the bottom-right corner
            draggingType = "right-bottom";
          }
        }
        rectGroup.setData({ draggingType: draggingType });
      },
      true
    );

    if (editable) {
      // disable the map's behavior if resizing started so map doesn't pan in the situation
      // when we try to set rect size to 0 or negative and mouse cursor leaves the map object
      rectGroup.addEventListener(
        "dragstart",
        function (evt: any) {
          if (evt.target === rectOutline) {
            behavior?.disable();
          }
        },
        true
      );

      // event listener for rect group to resize the geo rect object if dragging over outline polyline
      rectGroup.addEventListener(
        "drag",
        function (evt: any) {
          const pointer = evt.currentPointer;
          const pointerGeoPoint = map!.screenToGeo(
            pointer.viewportX,
            pointer.viewportY
          )!;
          const currentGeoRect = rect!.getGeometry()!.getBoundingBox()!;
          // const objectTopLeftScreen = map.geoToScreen(currentGeoRect!.getTopLeft());
          // const objectBottomRightScreen = map.geoToScreen(
          //   currentGeoRect!.getBottomRight()
          // );

          // if pointer is over outline, resize the geo rect object
          if (evt.target instanceof H.map.Polyline) {
            var currentTopLeft = currentGeoRect.getTopLeft(),
              currentBottomRight = currentGeoRect.getBottomRight(),
              newGeoRect: any,
              outlineLinestring;

            // update rect's size depending on dragging type:
            switch (rectGroup.getData()["draggingType"]) {
              case "left-top":
                // we don't allow resizing to 0 or to negative values
                if (
                  pointerGeoPoint.lng >= currentBottomRight.lng ||
                  pointerGeoPoint.lat <= currentBottomRight.lat
                ) {
                  return;
                }
                newGeoRect = H.geo.Rect.fromPoints(
                  pointerGeoPoint,
                  currentGeoRect.getBottomRight()
                );
                break;
              case "left-bottom":
                // we don't allow resizing to 0 or to negative values
                if (
                  pointerGeoPoint.lng >= currentBottomRight.lng ||
                  pointerGeoPoint.lat >= currentTopLeft.lat
                ) {
                  return;
                }
                currentTopLeft.lng = pointerGeoPoint.lng;
                currentBottomRight.lat = pointerGeoPoint.lat;
                newGeoRect = H.geo.Rect.fromPoints(
                  currentTopLeft,
                  currentBottomRight
                );
                break;
              case "right-top":
                // we don't allow resizing to 0 or to negative values
                if (
                  pointerGeoPoint.lng <= currentTopLeft.lng ||
                  pointerGeoPoint.lat <= currentBottomRight.lat
                ) {
                  return;
                }
                currentTopLeft.lat = pointerGeoPoint.lat;
                currentBottomRight.lng = pointerGeoPoint.lng;
                newGeoRect = H.geo.Rect.fromPoints(
                  currentTopLeft,
                  currentBottomRight
                );
                break;
              case "right-bottom":
                // we don't allow resizing to 0 or to negative values
                if (
                  pointerGeoPoint.lng <= currentTopLeft.lng ||
                  pointerGeoPoint.lat >= currentTopLeft.lat
                ) {
                  return;
                }
                newGeoRect = H.geo.Rect.fromPoints(
                  currentGeoRect.getTopLeft(),
                  pointerGeoPoint
                );
                break;
              case "left":
                // we don't allow resizing to 0 or to negative values
                if (pointerGeoPoint.lng >= currentBottomRight.lng) {
                  return;
                }
                currentTopLeft.lng = pointerGeoPoint.lng;
                newGeoRect = H.geo.Rect.fromPoints(
                  currentTopLeft,
                  currentGeoRect.getBottomRight()
                );
                break;
              case "right":
                // we don't allow resizing to 0 or to negative values
                if (pointerGeoPoint.lng <= currentTopLeft.lng) {
                  return;
                }
                currentBottomRight.lng = pointerGeoPoint.lng;
                newGeoRect = H.geo.Rect.fromPoints(
                  currentGeoRect.getTopLeft(),
                  currentBottomRight
                );
                break;
              case "top":
                // we don't allow resizing to 0 or to negative values
                if (pointerGeoPoint.lat <= currentBottomRight.lat) {
                  return;
                }
                currentTopLeft.lat = pointerGeoPoint.lat;
                newGeoRect = H.geo.Rect.fromPoints(
                  currentTopLeft,
                  currentGeoRect.getBottomRight()
                );
                break;
              case "bottom":
                // we don't allow resizing to 0 or to negative values
                if (pointerGeoPoint.lat >= currentTopLeft.lat) {
                  return;
                }
                currentBottomRight.lat = pointerGeoPoint.lat;
                newGeoRect = H.geo.Rect.fromPoints(
                  currentGeoRect.getTopLeft(),
                  currentBottomRight
                );
                break;
            }

            // set the new bounding box for rect object
            rect.setBoundingBox(newGeoRect!);

            // extract first point of the outline LineString and push it to the end, so the outline has a closed geometry
            //@ts-ignore
            outlineLinestring = rect.getGeometry().getExterior();
            outlineLinestring.pushPoint(outlineLinestring.extractPoint(0));
            rectOutline.setGeometry(outlineLinestring);

            // prevent event from bubling, so map doesn't receive this event and doesn't pan
            evt.stopPropagation();
          }
        },
        true
      );

      // event listener for rect group to enable map's behavior
      rectGroup.addEventListener(
        "dragend",
        function () {
          // enable behavior
          behavior?.enable();

          const bottomRight = rectGroup.getBoundingBox().getBottomRight();
          const topLeft = rectGroup.getBoundingBox().getTopLeft();
          const returnCoordinates = [
            [topLeft.lat, bottomRight.lng],
            [bottomRight.lat, topLeft.lng],
          ] as Position[];

          if (editable) {
            const newAvoidArea = _.cloneDeep(avoidArea);
            newAvoidArea.coordinates = encodePolyline({
              polyline: returnCoordinates,
            } as any);

            dispatch(setEditedAvoidArea(newAvoidArea));
          }
        },
        true
      );
    }

    return rectGroup;
  };

  const createTapLinkHandlerRect = (rect: H.map.Group) => {
    return () => {
      const bounds = rect.getBoundingBox()!;
      const center = bounds.getCenter();

      const avoidance = _.cloneDeep(avoidArea);

      let coordinates: Position[];

      if (avoidance.testCoordinates) {
        coordinates = decodePolyline(avoidance.testCoordinates)?.polyline;
      }

      if (!linkDataInfoBubble) {
        linkDataInfoBubble = new H.ui.InfoBubble(center);
        ui?.addBubble(linkDataInfoBubble);
      }

      const onEditButtonClick = () => {
        linkDataInfoBubble.close();
        dispatch(setEditedAvoidArea(avoidance));
      };

      const onTestCoordinatesButtonClick = () => {
        if (coordinates) {
          dispatch(
            addOrigin({ lat: coordinates[0][0], lng: coordinates[0][1] })
          );
          dispatch(
            addDestination({ lat: coordinates[1][0], lng: coordinates[1][1] })
          );
          linkDataInfoBubble.close();
        }
      };

      const renderInfoBubbleContent = () => {
        const container = document.createElement("div");
        const root = createRoot(container);
        root.render(
          <AvoidAreaInfoBubbleContent
            avoidance={avoidance}
            coordinates={coordinates}
            onEditButtonClick={editable ? undefined : onEditButtonClick}
            onTestCoordinatesButtonClick={onTestCoordinatesButtonClick}
          />
        );
        return container;
      };

      const contentNode = renderInfoBubbleContent();

      // Adding Link data to a Infobubble with text area formatting

      linkDataInfoBubble.setPosition(center);
      linkDataInfoBubble.setContent(contentNode);

      linkDataInfoBubble.open();
    };
  };

  return null;
};

export default AvoidAreaRect;
