import H from "@here/maps-api-for-javascript";
import { NormalRouteStyle } from "support/styles/MapStyles";
import { RoutePointType } from "support/models/Types";
import StartMarker from "../assets/svg/start-marker.svg";
import FinishMarker from "../assets/svg/finish-marker.svg";

export function createMarker(
  lat: number,
  lng: number,
  iconName: any,
  data: any,
  widthIcon = 36,
  heightIcon = 42
) {
  return new H.map.Marker(
    { lat, lng },
    {
      icon: new H.map.Icon(iconName as any, {
        size: { w: widthIcon, h: heightIcon },
      }),
      data,
    }
  );
}

export function createGeoRect(
  startLat: number,
  startLng: number,
  destinationLat: number,
  destinationLng: number
) {
  // Determine the top-most and bottom-most latitudes.
  const topLatitude = Math.min(startLat, destinationLat);
  const bottomLatitude = Math.max(startLat, destinationLat);

  // Determine the left-most and right-most longitudes.
  const leftLongitude = Math.min(startLng, destinationLng);
  const rightLongitude = Math.max(startLng, destinationLng);

  // Return the H.geo.Rect object with the coordinates in the correct order.
  return new H.geo.Rect(
    topLatitude,
    leftLongitude,
    bottomLatitude,
    rightLongitude
  );
}

export function displayOnMap(data: RoutePointType[]): {
  polyLine: H.map.Polyline;
  startMarker: H.map.Marker;
  endMarker: H.map.Marker;
  bounds: H.geo.Rect | null;
} {
  const lineString = new H.geo.LineString();
  data.forEach((it: any) => {
    lineString.pushPoint({ lat: it.lat, lng: it.lng });
  });

  const polyLine = new H.map.Polyline(lineString, {
    style: NormalRouteStyle,
    data: undefined,
  });

  const startMarker = createMarker(
    data[0].lat,
    data[0].lng,
    StartMarker,
    undefined,
    48,
    56
  );
  const endMarker = createMarker(
    data[data.length - 1].lat,
    data[data.length - 1].lng,
    FinishMarker,
    undefined,
    48,
    56
  );

  const bounds = polyLine.getBoundingBox();

  return { polyLine, startMarker, endMarker, bounds };
}
