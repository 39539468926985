// AdjustValueComponent.js
import { SidebarContainer } from "map/Sidebar/SidebarContainer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createEditedAvoidArea } from "stores/mapGrid";

export const AddAvoidAreas: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createEditedAvoidArea());
  }, [dispatch]);

  return <SidebarContainer />;
};
