
import { RootState } from "stores/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Point {
  lat: number;
  lng: number;
  opacity: number;
  icon: H.map.Icon;
  route_hash_id?: string;
}


const initialState: {
  oneHourTrips: number;
  twentyFourHourTrips: number;
  stream: boolean,
  locationPoints: Point[]
} = {
  oneHourTrips: 0,
  twentyFourHourTrips: 0,
  stream: false,
  locationPoints: []
}

var svg = `<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 100 100" style="width: 14px; height: 14px;">
<circle cx="50" cy="50" r="50" fill="FILL_COLOR" opacity="1.0">
<animate attributeName="opacity" from="1" to=".5" dur="30s" fill="freeze" />
</circle>
</svg>`

export const realTimeReducer = createSlice({
  name: 'realTime',
  initialState, 
  reducers: {
    setOneHourTrip: (state, action: PayloadAction<number>) => {
      state.oneHourTrips = action.payload
    },
    setTwentyFourHourTrips: (state, action: PayloadAction<number>) => {
      state.twentyFourHourTrips = action.payload
    },
    toggleStream: (state) => {
      console.log("Toggling", state.stream)
      state.stream = !state.stream
    },
    setPoints: (state, action) => {
      if (action.payload === "") {
        state.locationPoints = []
        return
      }
      const jsonObject = JSON.parse(action.payload);
      // console.log(jsonObject.length)
      var icon = new H.map.DomIcon(
        svg.replace('FILL_COLOR', 'rgba(219,33,21)')
      )
      console.log(jsonObject)
      //@ts-ignore
      const jsonPoints = jsonObject.map(point => ({
        lat: point.lat,
        lng: point.lon,
        opacity: point.opacity ? point.opacity - 0.02 : 1,
        icon: icon,
        route_hash_id: point.route_hash_id
      }))
      const uniqueJsonPoints = jsonPoints.reduce((acc :any, current :any) => {
          const existingPoint = acc.find((point: Point) => point.route_hash_id === current.route_hash_id);
          if (!existingPoint) {
              acc.push(current);
          }
          return acc;
      }, []);

      const prevPoints = state.locationPoints
      const existingCoords = state.locationPoints;
      const newUniquePoints = uniqueJsonPoints.filter((newPoint :Point) => {
        return !existingCoords.some(existingCoord =>
          existingCoord.lat === newPoint.lat || existingCoord.lng === newPoint.lng || existingCoord.route_hash_id === newPoint.route_hash_id
        );
      });
      for (let i = 0; i < prevPoints.length; i++) {
        prevPoints[i].opacity = prevPoints[i].opacity - 0.05;
        if (prevPoints[i].opacity < 0.1) {
          prevPoints.splice(i, 1);
        }
      //   var icon = new H.map.Icon( 
      //     svg.replace('FILL_COLOR', 'rgba(30, 200, 200, ' + prevPoints[i].opacity + ')'),
      //     {
      //       size,
      //       anchor: {x: size.w/2, y: size.h/2},
      //     }
      // )
      //   prevPoints[i].icon = icon
      }
      state.locationPoints = prevPoints.concat(newUniquePoints);
    }
  }

})

export const { 
  setOneHourTrip,
  setTwentyFourHourTrips,
  toggleStream,
  setPoints
} = realTimeReducer.actions

export const getOneHourTrip = () => (state: RootState) => 
  state.realTime.oneHourTrips
export const getTwentyFourHourTrip = () => (state: RootState) =>
  state.realTime.twentyFourHourTrips
export const isStreaming = () => (state: RootState) => 
  state.realTime.stream
export const getRealTimePoints = () => (state: RootState) => 
  state.realTime.locationPoints

export default realTimeReducer.reducer