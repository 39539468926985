import { getRoutePoints } from "util/awsfunctions";
import React from "react";
import { RoutePointType } from "support/models/Types";

export const useRouteData = (routeHash: string) => {
  const [routeData, setRouteData] = React.useState<RoutePointType[]>([]);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getRoutePoints(routeHash);
        setRouteData(data);
      } catch (error) {
        console.error("Failed to fetch route data:", error);
      }
    };
    if (routeHash) {
      fetchData();
    }
  }, [routeHash]);
  return routeData;
};
