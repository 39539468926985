import { Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";

const ConfirmationPopup = ({
  isOpen,
  onDelete,
  onCancel,
}: {
  isOpen: boolean;
  onDelete: () => void;
  onCancel: () => void;
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleDelete = () => {
    if (inputValue === "confirm") {
      onDelete();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div>
      <div>
        <Stack direction="column" spacing={2}>
          <p>Type "confirm" to delete the object</p>
          <TextField
            label="Confirm deletion"
            value={inputValue}
            onChange={handleInputChange}
          />
          <Button
            variant="outlined"
            disabled={inputValue !== "confirm"}
            onClick={handleDelete}
          >
            Confirm Deletion
          </Button>
          <Button variant="outlined" onClick={onCancel}>
            Cancel Deletion
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
