import { HazardType } from "./CreateRoadReport";

export type RoadReport = {
  id: string;
  lat: number;
  lon: number;
  rv_height: number | null;
  rv_length: number | null;
  rv_type: String | null;
  pm_user_id: number | null;
  hazard_type: HazardType | null;
  rv_weight: number | null;
  low_clearance_height: number | null;
  weight_limit: number | null;
  still_there: Still_There[];
};

export type Still_There = {
  recorded_at: string;
  recorded_by: number;
  id: number;
  road_report_id: string;
};

export const filterRoadReportsByHazardType = (
  roadReports: RoadReport[],
  selectedHazards: HazardType[]
): RoadReport[] => {
  if (selectedHazards.length === 0) return roadReports;
  return roadReports.filter((roadReport) =>
    selectedHazards.includes(roadReport.hazard_type as HazardType)
  );
};
