import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { isStreaming, toggleStream } from "stores/realTime";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import { startStreamListener } from "util/realTimestreamer";

const RealTimeButton: React.FC = () => {
  const stream = useAppSelector(isStreaming());
  const dispatch = useAppDispatch();
  startStreamListener();

  const handleButtonClick = () => {
    if (stream) {
      dispatch(toggleStream());
      return;
    }
    const password = prompt("Enter password:");
    if (password === "rv_life") {
      dispatch(toggleStream());
    } else {
      alert("Incorrect password!");
    }
  };

  return (
    <Button
      component={Link}
      to="/missionControl"
      variant="outlined"
      onClick={handleButtonClick}
      sx={{
        position: "absolute",
        bottom: 20,
        left: 40,
        mx: 2,
        bgcolor: "white",
      }}
    >
      Live Routing {stream ? "⏸️" : "▶️"}
    </Button>
  );
};

export default RealTimeButton;
