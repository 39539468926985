import { Button } from "@mui/material";

export const SearchButtons: React.FC<{
  onSearch: () => void;
  onExport: () => void;
  onGetCommentedEntries: () => void;
  open: boolean;
}> = ({ onSearch, onExport, onGetCommentedEntries, open }) => (
  <>
    <Button onClick={onSearch} variant="outlined" sx={{ mx: 1 }}>
      Search
    </Button>
    <Button onClick={onExport} variant="outlined" sx={{ mx: 1 }}>
      Export CSV
    </Button>
    {open && (
      <>
        <Button
          onClick={onGetCommentedEntries}
          variant="outlined"
          sx={{ mx: 1 }}
        >
          Get All Commented Entries
        </Button>
      </>
    )}
  </>
);
