import React from "react";
import { selectMapBoxRoute } from "stores/routing";
import { useAppSelector } from "stores/hooks";
import { selectVisibleRoutes } from "stores/ui";
import { RouteKey } from "stores/types";
import PolylineMapBox from "map/elements/PolylineMapBox";

const GenericRouteMapBox: React.FC<{ id: RouteKey }> = ({ id }) => {
  const route = useAppSelector(selectMapBoxRoute());
  const isRouteVisible = useAppSelector(selectVisibleRoutes("mapBox"));
  if (route && isRouteVisible) {
    const polyline = (
      <PolylineMapBox
        positions={route.coordinates}
        lineWidth={4}
        lineDash={[2, 6]}
        strokeColor={"rgba(43, 43, 43, 1)"}
        lineDashOffset={8}
      />
    );
    return <>{polyline}</>;
  }
  return null;
};

export default GenericRouteMapBox;
