import React from "react";
import { selectSegmentOverlay } from "stores/routing";
import { useAppSelector } from "stores/hooks";
import Polyline from "map/elements/Polyline";
import { decodeLatLngOffset } from "map/util/decodeLatLngOffset";
import InfoBubble from "map/elements/InfoBubble";

const SegmentOverlay: React.FC = () => {
  const segments = useAppSelector(selectSegmentOverlay());

  if (!segments) {
    return null;
  }

  const polylines = segments.map(segment => {

    const coords = segment.attributes.ROAD_GEOM_FCN[0];
    const positions = decodeLatLngOffset(coords.LAT, coords.LON)
    return {
      ...segment,
      geometry: H.geo.LineString.fromLatLngArray(positions).toFlexiblePolyline()
    }

  });

  return (
    <>
      {polylines.map((polyline, i) => (
        <Polyline
          key={polyline.segmentRef}
          positions={polyline.geometry}
          lineWidth={20}
          strokeColor={i % 2 === 0 ? "rgba(128, 128, 128, 0.6)" : "rgba(139, 69, 19, 0.6)"} // Alternating gray and brown
          hoverStrokeColor="rgba(255, 165, 0, 0.7)" // Orange with some transparency
          lineCap="round"
          lineJoin="round"
          popup={<InfoBubble>
            <div style={{ maxHeight: '800px', maxWidth: '800px', minWidth: '500px', overflow: 'auto' }}>
              <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                <h3>{polyline.attributes.ROAD_GEOM_FCN[0].NAME}</h3>
                {polyline.attributes.ROAD_ROUGHNESS_FCN && JSON.stringify(polyline.attributes.ROAD_ROUGHNESS_FCN[0], null, 2)}<br />
                {polyline.attributes.ADAS_ATTRIB_FCN &&
                  JSON.stringify({
                    SLOPES: polyline.attributes.ADAS_ATTRIB_FCN[0].SLOPES,
                    CURVATURES: polyline.attributes.ADAS_ATTRIB_FCN[0].CURVATURES,
                    HEADINGS: polyline.attributes.ADAS_ATTRIB_FCN[0].HEADINGS
                  }, null, 2)
                }
              </pre>
            </div>
          </InfoBubble>}
        />
      ))}
    </>
  );
};

export default SegmentOverlay;
