import React, { useEffect, useRef, useState } from "react";
import H from "@here/maps-api-for-javascript";
import GeoShape, { GeoShapeProps } from "./GeoShape";

type PolylineProps = Omit<GeoShapeProps, "shape"> & {
  positions?: number[][];
};

const Polyline: React.FC<PolylineProps> = ({ positions, ...shapeProps }) => {
  const [polyline, setPolyline] = useState<H.map.Polyline>();
  const positionsRef = useRef<number[][]>();

  useEffect(() => {
    return () => {
      setPolyline(undefined);
      positionsRef.current = undefined;
    };
  }, []);

  useEffect(() => {
    if (!positions) {
      return;
    }

    if (polyline && positionsRef.current === positions) {
      return;
    }

    try {
      const lineString = new H.geo.LineString();
      positions.forEach((coordinate: any) => {
        lineString.pushLatLngAlt(coordinate[1], coordinate[0], undefined);
      });

      let polyline = new H.map.Polyline(lineString);
      setPolyline(polyline);
    } catch (error) {
      console.warn("Error creating Polyline with:", positions, error);
    }

    positionsRef.current = positions;
  }, [polyline, positions]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <GeoShape shape={polyline} {...shapeProps} />;
};

export default Polyline;
