import React, { useEffect, useRef, useState } from "react";
import H from "@here/maps-api-for-javascript";
import { Layers, MapObjectEvent } from "../types";
import { useGroupContext } from "map/Group";

export type GeoShapeProps = {
  testID?: string;
  shape: H.map.GeoShape | undefined;
  data?: Record<string, unknown>;
  layer?: Layers;
  zIndex?: number;
  volatile?: boolean;
  fillColor?: string;
  lineDash?: number[];
  lineWidth?: number;
  lineDashOffset?: number;
  strokeColor?: string;
  lineCap?: string;
  lineJoin?: string;
  draggable?: boolean;
  hoverFillColor?: string;
  hoverStrokeColor?: string;
  popup?: React.ReactElement | false | null | undefined;
  onPopupClose?: () => void;
  onPointerEnter?: (event: MapObjectEvent) => void;
  onPointerLeave?: (event: MapObjectEvent) => void;
  onPointerDown?: (event: MapObjectEvent) => void;
  onPointerMove?: (event: MapObjectEvent) => void;
  onTap?: (event: MapObjectEvent) => void;
};

function handleEventHandler(
  shape: H.map.GeoShape | undefined,
  event: string,
  handler: ((event: MapObjectEvent) => void) | undefined
) {
  if (!shape || !handler) {
    return;
  }

  shape.addEventListener(event, handler);

  return () => {
    shape.removeEventListener(event, handler);
  };
}

const GeoShape: React.FC<GeoShapeProps> = ({
  shape,
  layer = undefined,
  data = undefined,
  volatile = undefined,
  zIndex = undefined,
  fillColor = undefined,
  lineDash = undefined,
  lineWidth = undefined,
  lineDashOffset = undefined,
  strokeColor = undefined,
  lineCap = undefined,
  lineJoin = undefined,
  draggable = undefined,
  hoverFillColor = undefined,
  hoverStrokeColor = undefined,
  popup = undefined,
  onPopupClose = undefined,
  onPointerEnter = undefined,
  onPointerLeave = undefined,
  onPointerDown = undefined,
  onPointerMove = undefined,
  onTap = undefined,
  testID = undefined,
}: GeoShapeProps) => {
  const { addObject, removeObject } = useGroupContext();
  const defaultStyle = useRef<H.map.SpatialStyle>();
  const [popupVisible, setPopupVisible] = useState(false);
  const [moveIntoView, setMoveIntoView] = useState(false);

  useEffect(() => {
    if (!addObject || !removeObject || !shape) {
      return;
    }

    addObject(shape, layer);

    return () => {
      removeObject(shape, layer);
      shape.dispose();
    };
  }, [addObject, layer, removeObject, shape]);

  useEffect(() => {
    if (!shape) {
      return;
    }
    shape.setData({
      ...shape.getData(),
      ...data,
    });
  }, [shape, data]);

  useEffect(() => {
    if (!shape) {
      return;
    }
    shape.setData({
      ...shape.getData(),
      testID,
    });
  }, [shape, testID]);

  useEffect(() => {
    if (!shape || zIndex === undefined) {
      return;
    }

    shape.setZIndex(zIndex);
  }, [shape, zIndex]);

  useEffect(() => {
    if (!shape) {
      return;
    }

    shape.setVolatility(volatile);
  }, [shape, volatile]);

  useEffect(() => {
    if (!shape || (!hoverFillColor && !hoverStrokeColor)) {
      return;
    }

    const handlePointerEnter = () => {
      defaultStyle.current = shape.getStyle();
      const hoverStyle = defaultStyle.current.getCopy({
        fillColor: hoverFillColor || defaultStyle.current.fillColor,
        strokeColor: hoverStrokeColor || defaultStyle.current.strokeColor,
      });
      shape.setStyle(hoverStyle);
      document.body.style.cursor = 'pointer';
    };

    const handlePointerLeave = () => {
      if (defaultStyle.current) {
        shape.setStyle(defaultStyle.current);
        document.body.style.cursor = '';
      }
    };

    shape.addEventListener('pointerenter', handlePointerEnter);
    shape.addEventListener('pointerleave', handlePointerLeave);

    return () => {
      shape.removeEventListener('pointerenter', handlePointerEnter);
      shape.removeEventListener('pointerleave', handlePointerLeave);
    };
  }, [shape, hoverFillColor, hoverStrokeColor]);

  useEffect(
    () => handleEventHandler(shape, "pointerleave", onPointerLeave),
    [shape, onPointerLeave]
  );

  useEffect(
    () => handleEventHandler(shape, "pointerenter", onPointerEnter),
    [shape, onPointerEnter]
  );

  useEffect(
    () => handleEventHandler(shape, "pointerdown", onPointerDown),
    [shape, onPointerDown]
  );

  useEffect(
    () => handleEventHandler(shape, "pointermove", onPointerMove),
    [shape, onPointerMove]
  );

  useEffect(() => {
    if (!shape || !popup) {
      return;
    }

    const handleTap = (evt: MapObjectEvent) => {
      setPopupVisible(true);
      setMoveIntoView(true);
      if (onTap) onTap(evt);
    };

    shape.addEventListener("tap", handleTap);

    return () => {
      shape.removeEventListener("tap", handleTap);
    };
  }, [shape, popup, onTap]);

  useEffect(() => {
    if (!shape || draggable === undefined) {
      return;
    }
    shape.draggable = draggable;
  }, [shape, draggable]);

  useEffect(() => {
    if (!shape) {
      return;
    }

    let style = shape.getStyle();

    if (fillColor !== undefined) {
      style = style.getCopy({ fillColor });
    }

    if (lineDash !== undefined) {
      style = style.getCopy({ lineDash });
    }

    if (lineWidth !== undefined) {
      style = style.getCopy({ lineWidth });
    }

    if (strokeColor !== undefined) {
      style = style.getCopy({ strokeColor });
    }

    if (lineDashOffset !== undefined) {
      style = style.getCopy({ lineDashOffset });
    }

    if (lineCap !== undefined) {
      style = style.getCopy({ lineCap });
    }

    if (lineJoin !== undefined) {
      style = style.getCopy({ lineJoin });
    }

    shape.setStyle(style);
    defaultStyle.current = style;
  }, [shape, fillColor, lineDash, lineWidth, lineDashOffset, strokeColor, lineCap, lineJoin]);

  return popup && shape ? React.cloneElement(popup, {
    position: shape.getBoundingBox()?.getCenter(),
    visible: popupVisible,
    moveIntoView,
    onClose: () => {
      setPopupVisible(false);
      if (onPopupClose) onPopupClose();
    }
  }) : null;
};

export default GeoShape;
