import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button,
  Paper,
} from "@mui/material";
import { Link } from "@mui/material";
import { useHereMap } from "map/Context";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { RoadReport } from "sonar/types/RoadReport";

interface RoadReportsTableProps {
  roadReports: RoadReport[];
}

// header style
let headerStyle = {
  backgroundColor: "#119758",
  color: "white",
};

const RoadReportsTable: React.FC<RoadReportsTableProps> = ({ roadReports }) => {
  const { map } = useHereMap();
  const [, setSelectedRoadReport] = useState<RoadReport | null>(null);
  const [, setStillThereRoadReport] = useState<RoadReport | undefined>(
    undefined
  );
  const [selectedId, setSelectedId] = useState<string | null>(null); // Added state for selectedId

  if (roadReports.length === 0) {
    return <div></div>;
  }
  const jumpToRoadReport = (roadReport: RoadReport) => {
    // Create a new URLSearchParams object
    const params = new URLSearchParams(window.location.search);
    params.set('id', roadReport.id);

    // Update the URL without reloading the page
    window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);

    // loop through the road reports and find the one that matches the id
    // lat and lon to create a bounds
    let lat = roadReport.lat;
    let lon = roadReport.lon;
    let position = new H.geo.Point(lat, lon);

    roadReports.forEach((item) => {
      if (item.id === roadReport.id) {
        map?.getViewModel().setLookAtData({
          position: position,
          zoom: 15, // Adjust zoom level as needed
        });
      }
    });
  }

  const handleOpenModal = (roadReport: RoadReport) => {
    setSelectedRoadReport(roadReport);
  };


  const handleRowClick = (id: string) => {
    setSelectedId(id);
  };
  const handleOpenStillThereModal = (roadReport: RoadReport) => {
    setStillThereRoadReport(roadReport);
  };

  const handleEditClick = (roadReportId: string) => {
    // Navigate to the edit page with the road report ID
    window.location.href = `/sonar?id=${roadReportId}`;
  };

  return (
    <Paper style={{ height: 400, width: '100%', overflow: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ ...headerStyle, width: '10%' }}>
              <Tooltip title="Hover to View Latitude and Longitude, Click to jump to Road Report">
                <span>
                  <InfoOutlinedIcon
                    fontSize="small"
                    style={{ marginLeft: "4px" }}
                  />
                  ID
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={{ ...headerStyle, width: '20%' }}>Hazard Type</TableCell>
            <TableCell style={{ ...headerStyle, width: '20%' }}>RV INFO</TableCell>
            <TableCell style={{ ...headerStyle, width: '20%' }}>STILL THERE ENTRIES</TableCell>
            <TableCell style={{ ...headerStyle, width: '10%' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roadReports.map((roadReport) => (
            <TableRow
              key={roadReport.id}
              style={selectedId === roadReport.id ? { backgroundColor: "yellow" } : {}}
              onClick={() => handleRowClick(roadReport.id)}
            >
              <TableCell style={{ width: '10%' }}>
                <Tooltip title={`Latitude: ${roadReport.lat}, Longitude: ${roadReport.lon}`}>
                  <Link
                    onClick={() => {
                      jumpToRoadReport(roadReport);
                    }}
                  >
                    id
                  </Link>
                </Tooltip>
              </TableCell>
              <TableCell style={{ width: '20%' }}>{roadReport.hazard_type}</TableCell>
              <TableCell style={{ width: '20%' }}>
                <Button onClick={() => handleOpenModal(roadReport)}>RV Info</Button>
              </TableCell>
              <TableCell style={{ width: '20%' }}>
                {roadReport.still_there.length > 0 ? (
                  <Button onClick={() => handleOpenStillThereModal(roadReport)}>
                    Entries
                  </Button>
                ) : (
                  <Button onClick={() => handleOpenStillThereModal(roadReport)}>
                    NO Entries
                  </Button>
                )}
              </TableCell>
              <TableCell style={{ width: '10%' }}>
                <Button onClick={() => handleEditClick(roadReport.id)}>Edit</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default RoadReportsTable;
