import React from "react";
import { GeoShapeProps } from "./GeoShape";
import Polyline from "./Polyline";

type PolylineProps = Omit<GeoShapeProps, "shape"> & {
  polylines: string[];
};

const MultiPolyline: React.FC<PolylineProps> = ({
  polylines,
  ...shapeProps
}) => {
  return (
    <>
      {polylines.map((polyline) => {
        return <Polyline positions={polyline} {...shapeProps} />;
      })}
    </>
  );
};

export default MultiPolyline;
