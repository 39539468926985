import DepartureWaypoints from "./departure/DepartureWaypoints"
import EditableWaypointApproach from "./approach/EditableWaypointApproach"
import WaypointApproach from "./approach/WaypointApproach"
import EditableDepartureWaypoints from "./departure/EditableDepartureWaypoints"

const Waypoints: React.FC = () => {
    return <>
        <WaypointApproach />
        <EditableWaypointApproach />
        <DepartureWaypoints />
        <EditableDepartureWaypoints />
    </>
}

export default Waypoints;