import React from "react";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import { AuthContext } from "stores/AuthContext";
import {
  fetchGrid,
  getDeletedVisible,
  getEditedAvoidArea,
  getEditedAvoidPolygon,
  isMapGridVisible,
  returnGrid,
} from "stores/mapGrid";
import AvoidAreaRect from "./AvoidAreaRect";
import AvoidAreaPolygon from "./AvoidAreaPolygon";
import { isPolylineBoundingBox } from "../utils.avoidarea";

const AvoidAreas: React.FC = () => {
  const dispatch = useAppDispatch();
  const mapGrid = useAppSelector(returnGrid);
  const isGridVisible = useAppSelector(isMapGridVisible);
  const deletedVisible = useAppSelector(getDeletedVisible);
  const editedAvoidArea = useAppSelector(getEditedAvoidArea);
  const editedAvoidPolygon = useAppSelector(getEditedAvoidPolygon);
  const { keycloak } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (isGridVisible && mapGrid.length === 0) {
      dispatch(fetchGrid(keycloak?.token ?? ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGridVisible, mapGrid]);

  const avoidAreaElements: JSX.Element[] = [];

  if (editedAvoidArea) {
    avoidAreaElements.push(
      <AvoidAreaRect
        key={editedAvoidArea.id ?? "edited"}
        avoidArea={editedAvoidArea}
        editable
      />
    );
  }

  if (editedAvoidPolygon) {
    avoidAreaElements.push(
      <AvoidAreaPolygon key="polygon" avoidArea={editedAvoidPolygon} editable />
    );
  }

  if (isGridVisible) {
    const avoidAreas = mapGrid
      .map((grid) => {
        return grid.avoidAreas.map((avoidArea) => {
          if (avoidArea.id === editedAvoidArea?.id) {
            return null;
          }
          if (avoidArea.id === editedAvoidPolygon?.id) {
            return null;
          }
          if (avoidArea.dateDeleted && !deletedVisible) {
            return null;
          }
          if (isPolylineBoundingBox(avoidArea.coordinates)) {
            return <AvoidAreaRect key={avoidArea.id} avoidArea={avoidArea} />;
          }
          return <AvoidAreaPolygon key={avoidArea.id} avoidArea={avoidArea} />;
        });
      })
      .flat()
      .filter((avoidArea) => avoidArea !== null) as JSX.Element[];
    avoidAreaElements.push(...avoidAreas);
  }

  return <>{avoidAreaElements}</>;
};

export default AvoidAreas;
