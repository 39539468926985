import React, { useEffect } from 'react';
import { useAppSelector } from 'stores/hooks';
import { selectCommentPolylines } from 'stores/commentPolylines';
import { useHereMap } from 'map/Context';
import { createRoot } from 'react-dom/client';

export const CommentPolylines: React.FC = () => {
  const { intersection, buffered, comment } = useAppSelector(selectCommentPolylines);
  const { map, ui } = useHereMap();
  
  useEffect(() => {
    if (!map || (!intersection && !buffered)) return;

    const lineGroup = new H.map.Group();

    let intersectionInfoBubble: H.ui.InfoBubble;
    let bufferedInfoBubble: H.ui.InfoBubble;
    const renderIntersectionInfoBubbleContent = () => {
        const container = document.createElement("div");
        const root = createRoot(container);
        root.render(
          <div>
            <h1>Intersection</h1>
            <p>Comment: {comment}</p>
          </div>
        );
        return container;
      };

      const contentNode = renderIntersectionInfoBubbleContent();

    if (intersection) {
        try {
            const linestring = H.geo.LineString.fromFlexiblePolyline(intersection);

        const intersectionPolyline = new H.map.Polyline(linestring, {
            zIndex: 1000,
            style: {
                strokeColor: 'rgba(255, 0, 0, 1)', // red
                lineWidth: 5 // Adjust the width as needed
            },
            data: { comment: 'Intersection Comment' } // Add comment data
        });

        // Add hover event listener
        intersectionPolyline.addEventListener('pointerenter', (evt:PointerEvent) => {
            let center = intersectionPolyline.getBoundingBox()?.getCenter();
            if (center) {
                if (!intersectionInfoBubble) {
                    intersectionInfoBubble = new H.ui.InfoBubble(center);
                    ui?.addBubble(intersectionInfoBubble);
                }
                intersectionInfoBubble.setContent(contentNode);
                intersectionInfoBubble.open();
            }
        });

        intersectionPolyline.addEventListener('pointerleave', () => {
            // Hide the comment tooltip
            console.log('Hide tooltip'); // Replace with actual tooltip hide logic
        });

        lineGroup.addObject(intersectionPolyline);
        } catch (error) {
            console.error('Error creating intersection polyline:', error);
        }
    }

    if (buffered) {
        try {
            const linestring = H.geo.LineString.fromFlexiblePolyline(buffered);

        const bufferedPolyline = new H.map.Polyline(linestring, {
            style: {
                strokeColor: 'rgba(255, 0, 0, .5)', //red 
                lineWidth: 7 // Adjust the width as needed
            },
            data: { comment: 'Buffered Comment' } // Add comment data
        });

        // Add hover event listener
        bufferedPolyline.addEventListener('pointerenter', (evt: any) => {

            console.log(evt);
            const comment = evt.target.getData().comment;
            // Display the comment (e.g., using a tooltip)
            console.log(comment); // Replace with actual tooltip display logic
        });

        bufferedPolyline.addEventListener('pointerleave', () => {
            // Hide the comment tooltip
            console.log('Hide tooltip'); // Replace with actual tooltip hide logic
        });

        lineGroup.addObject(bufferedPolyline);
        } catch (error) {
            console.error('Error creating buffered polyline:', error);
        }
    }

    map.addObject(lineGroup);

    return () => {
      map.removeObject(lineGroup);
      if (intersectionInfoBubble) {
        intersectionInfoBubble.close();
      }
      if (bufferedInfoBubble) {
        bufferedInfoBubble.close();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, intersection, buffered]);

  return null;
};

export default CommentPolylines; 