import { Route } from "support/models/Route";
import {
  convertFeetToCentimeters,
  convertPoundsToKilograms,
} from "./conversion";

export function calculateRoutingParams(
  startLat: number,
  startLng: number,
  route: Route
) {
  const {
    destinationLat,
    destinationLng,
    routingMode,
    vehicleLength,
    vehicleHeight,
    vehicleWeight,
    avoidTraffic,
    avoidFerries,
    avoidTunnels,
    avoidHighways,
    avoidTolls,
    avoidUnpaved,
  } = route;
  const rvParams = [];

  const transportMode = routingMode === "rv" ? "truck" : "car";
  const traffic = avoidTraffic ? "fast" : "short";
  if (transportMode === "truck") {
    const length = vehicleLength ? convertFeetToCentimeters(vehicleLength) : 0;
    const weight = vehicleWeight ? convertPoundsToKilograms(vehicleWeight) : 0;
    const height = vehicleHeight ? convertFeetToCentimeters(vehicleHeight) : 0;
    rvParams.push({
      "vehicle[length]": length,
      "vehicle[grossWeight]": weight,
      "vehicle[height]": height,
    });

    const avoid = [];
    if (avoidFerries) {
      avoid.push("ferry");
    }
    if (avoidTunnels) {
      avoid.push("tunnel");
    }
    if (avoidHighways) {
      avoid.push("controlledAccessHighway");
    }
    if (avoidTolls) {
      avoid.push("tollRoad");
    }
    if (avoidUnpaved) {
      avoid.push("dirtRoad");
    }
    if (avoid.length > 0) {
      rvParams.push({ "avoid[features]": avoid });
    }
  }
  const routingParams = Object.assign(
    {},
    {
      routingMode: traffic,
      transportMode,
      origin: `${startLat},${startLng}`,
      destination: `${destinationLat},${destinationLng}`,
      return: "polyline",
    },
    ...rvParams
  );
  return routingParams;
}
