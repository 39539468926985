import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHereMap } from "../Context";
import { MapObjectEvent, LatLngExpression } from "../types";
import { createPoint } from "../geo/util";

export type MarkerProps = {
  position: LatLngExpression;
  markerId?: number | string;
  testID?: string;
  icon?: H.map.Icon | H.map.DomIcon;
  data?: Record<string, unknown>;
  zIndex?: number;
  onTap?: (event: MapObjectEvent) => void;
  onPointerDown?: (event: MapObjectEvent) => void;
  onPointerUp?: (event: MapObjectEvent) => void;
  onPointerEnter?: (event: MapObjectEvent) => void;
  onPointerLeave?: (event: MapObjectEvent) => void;
  useDom?: boolean;
  volatile?: boolean;
};

function eventEffect(
  event: string | undefined,
  handler: ((event: MapObjectEvent) => void) | undefined,
  marker: H.map.AbstractMarker | undefined
): (() => void) | undefined {
  if (marker && handler && event) {
    marker.addEventListener(event, handler);

    return () => {
      marker.removeEventListener(event, handler);
    };
  }
}

function isValidIcon(
  marker: H.map.AbstractMarker,
  icon: H.map.Icon | H.map.DomIcon
) {
  return (
    (marker instanceof H.map.DomMarker && icon instanceof H.map.DomIcon) ||
    (marker instanceof H.map.Marker && icon instanceof H.map.Icon)
  );
}

const Marker: React.VFC<MarkerProps> = ({
  markerId = undefined,
  testID = undefined,
  data = undefined,
  icon = undefined,
  position,
  onTap = undefined,
  onPointerDown = undefined,
  onPointerUp = undefined,
  onPointerEnter = undefined,
  onPointerLeave = undefined,
  zIndex = undefined,
  volatile = undefined,
  useDom = undefined,
}: MarkerProps) => {
  const { addObject, removeObject } = useHereMap();
  const [marker, setMarker] = useState<H.map.AbstractMarker>();
  const positionRef = useRef(position);
  const iconRef = useRef(icon);
  const shouldUseDom = useDom || icon instanceof H.map.DomIcon;

  const allData = useMemo(() => {
    const d = { ...data };
    if (markerId) {
      d.markerId = markerId;
    }
    if (testID) {
      d.testID = testID;
    }
    return d;
  }, [data, markerId, testID]);

  // Create a marker
  useEffect(() => {
    if (addObject && removeObject) {
      const pos = createPoint(positionRef.current ?? { lat: 0, lng: 0 });
      const mkr = shouldUseDom
        ? new H.map.DomMarker(pos, {
            icon: iconRef.current,
            data: allData,
          })
        : new H.map.Marker(pos, {
            icon: iconRef.current as H.map.Icon,
            data: allData,
          });
      addObject(mkr, "markers");
      setMarker(mkr);

      return () => {
        setMarker(undefined);
        removeObject(mkr, "markers");
        mkr.dispose();
      };
    }
    // TODO: There is no dependency on allData because this effect only cares
    // about the initial value of allData. Any later changes will be
    // handled by another effect below.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addObject, removeObject, shouldUseDom]);

  useEffect(() => {
    // Due to how effects run during an update, the Marker could
    // temporarily be in a state where the marker object is a canvas marker
    // but the new icon is a DOM icon, or vice versa, so ensure the icon is
    // valid for the marker.
    if (!marker || !icon || !isValidIcon(marker, icon)) {
      return;
    }
    if (icon !== iconRef.current) {
      marker.setIcon(icon);
      iconRef.current = icon;
    }
  }, [marker, icon]);

  useEffect(() => {
    if (!marker) {
      return;
    }
    marker.setData({
      ...marker.getData(),
      ...allData,
    });
  }, [marker, allData]);

  useEffect(() => {
    if (!marker) {
      return;
    }
    marker.setVolatility(volatile);
  }, [marker, volatile]);

  useEffect(() => {
    if (!position || !marker) {
      return;
    }
    if (positionRef.current !== position) {
      const pos = createPoint(position);
      marker.setGeometry(pos);
      positionRef.current = position;
    }
  }, [marker, position]);

  useEffect(() => {
    if (!marker || zIndex === undefined) {
      return;
    }
    marker.setZIndex(zIndex);
  }, [marker, zIndex]);

  useEffect(() => eventEffect("tap", onTap, marker), [marker, onTap]);

  useEffect(
    () => eventEffect("pointerdown", onPointerDown, marker),
    [marker, onPointerDown]
  );

  useEffect(
    () => eventEffect("pointerup", onPointerUp, marker),
    [marker, onPointerUp]
  );

  useEffect(
    () => eventEffect("pointerenter", onPointerEnter, marker),
    [marker, onPointerEnter]
  );

  useEffect(
    () => eventEffect("pointerleave", onPointerLeave, marker),
    [marker, onPointerLeave]
  );

  return null;
};

export default Marker;
