import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid";
import React from "react";
import { AuthContext } from "stores/AuthContext";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import {
  fetchBusZones,
  isBusZonesVisible,
  returnZones,
  setEditedBusZone,
  toggleVisibility,
} from "stores/busZones";
import { IBusZone } from "stores/types";
import { formatPoint } from "../AvoidArea/utils.avoidarea";
import { useHereMap } from "map/Context";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import _ from "lodash";
import { decode } from "util/flexible-polyline";

const BusZoneList: React.FC<{
  open: boolean;
  setOpen(value: boolean): void;
}> = ({ open, setOpen }) => {
  const { keycloak } = React.useContext(AuthContext);
  const { map } = useHereMap();
  const busZones = useAppSelector(returnZones);
  const dispatch = useAppDispatch();
  const busZonesVisible = useAppSelector(isBusZonesVisible);

  React.useEffect(() => {
    if (busZones.length === 0) {
      dispatch(fetchBusZones(keycloak?.token ?? ""));
    }
    if (!open) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataGridRef = useGridApiRef();

  const columns: GridColDef<IBusZone>[] = [
    {
      field: "id",
      headerName: "Actions",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <Button
              component={Link}
              to="/bus-zones"
              startIcon={<EditIcon />}
              onClick={() => {
                dispatch(setEditedBusZone(_.cloneDeep(params.row)));
                const center = H.geo.LineString.fromFlexiblePolyline(
                  params.row.polyline
                )
                  .getBoundingBox()
                  ?.getCenter();
                if (center) map?.setCenter(center);
                map?.setZoom(15);
                setOpen(false);
              }}
              variant="outlined"
              sx={{ mr: 1 }}
            >
              Edit
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                const center = H.geo.LineString.fromFlexiblePolyline(
                  params.row.polyline
                )
                  .getBoundingBox()
                  ?.getCenter();
                if (center) map?.setCenter(center);
                map?.setZoom(15);
                setOpen(false);
                if (!busZonesVisible) dispatch(toggleVisibility());
              }}
              variant="outlined"
            >
              View
            </Button>
          </>
        );
      },
    },
    {
      field: "zoneName",
      headerName: "Name",
      width: 375,
      renderCell: (params) => (
        <div
          style={{
            display: "flex", // Enable Flexbox
            alignItems: "center", // Vertically center the content
            justifyContent: "left", // Align content to the left horizontally
            whiteSpace: "normal",
            wordWrap: "break-word",
            lineHeight: "16px",
            minHeight: 52,
            paddingBlock: 4,
          }}
        >
          {params.value}
        </div>
      ),
    },

    {
      field: "polyline",
      headerName: "Coordinates",
      width: 400,
      renderCell: (params) => {
        const coordinates = decode(params.value).polyline.map((coord) =>
          formatPoint({ lat: coord[0], lng: coord[1] })
        );
        return (
          <div
            style={{
              display: "flex", // Enable Flexbox
              alignItems: "center", // Vertically center the content
              justifyContent: "left", // Align content to the left horizontally
              whiteSpace: "normal",
              wordWrap: "break-word",
              lineHeight: "16px",
              minHeight: 52,
              paddingBlock: 4,
            }}
          >
            {coordinates.join(", ")}
          </div>
        );
      },
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 100,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (!params.value) return "N/A";
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
    },
    {
      field: "dateEdited",
      headerName: "Date Edited",
      width: 100,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (!params.value) return "N/A";
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "editedBy",
      headerName: "Edited By",
      width: 150,
    },
    {
      field: "dateDeleted",
      headerName: "Date Deleted",
      width: 100,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (!params.value) return "N/A";
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "deletedBy",
      headerName: "Deleted By",
      width: 150,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start", // Aligns items to the start of the cross axis (top), assuming LTR content flow
        p: 0, // No padding
      }}
    >
      <Stack
        direction="row"
        sx={{ my: 1, justifyContent: "flex-end", width: "100%" }}
      >
        <Button
          component={Link}
          to="/"
          onClick={() => {
            if (open) {
              setOpen(false);
            }
          }}
          sx={{ mr: 3 }}
          variant="outlined"
        >
          Return
        </Button>
      </Stack>
      <div
        style={{
          height: "calc(100vh - 121px)",
          width: "100%",
        }}
      >
        <DataGrid
          apiRef={dataGridRef}
          rows={busZones}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
            sorting: {
              sortModel: [
                {
                  field: "dateCreated",
                  sort: "desc",
                },
              ],
            },
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          disableRowSelectionOnClick
          getRowHeight={() => {
            return "auto";
          }}
        />
      </div>
    </Box>
  );
};

export default BusZoneList;
