import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { V8RouteResponse } from "map/types";
import _ from "lodash";
import { RouteKey } from "stores/types";
import { selectVisibleRoutes, toggleRoute } from "stores/ui";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import { addSegmentOverlay } from "stores/routing";

const RouteBox: React.FC<{
  route?: V8RouteResponse;
  label?: string;
  id: RouteKey;
}> = ({ route, label, id }) => {
  const dispatch = useAppDispatch();
  const isRouteVisible = useAppSelector(selectVisibleRoutes(id));
  if (!route) {
    return null;
  }
  return (
    <Stack direction="row">
      <div>
        <Checkbox
          checked={isRouteVisible}
          onChange={() => dispatch(toggleRoute(id))}
        />
      </div>
      <Accordion sx={{ width: "90%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{label || `${_.capitalize(id)}`} Route</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            variant="contained"
            size="small"
            sx={{ mb: 2 }}
            onClick={async () => {
              const segments = route.routes[0].sections.flatMap(section => section.spans?.map(span => span.segmentRef)).join(';');
              try {
                const boundary = '-----------------------------25099121';
                const formBody = `${boundary}\r\nContent-Disposition: form-data; name="segments"\r\n\r\n${segments}\r\n${boundary}--`;
                const attributes = ['ROAD_GEOM_FCn(*)', 'ROAD_ROUGHNESS_FCn(*)', 'ADAS_ATTRIB_FCn(*)'];

                const response = await fetch(`https://smap.hereapi.com/v8/maps/attributes/path?apikey=WjvhoAY5dh52iXQP60PCqNWJAplHBVoeCs0rzUGRz98&transportMode=car&attributes=${attributes.join(',')}`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': `multipart/form-data; boundary=${boundary.slice(2)}`
                  },
                  body: formBody
                });

                const attributesData = await response.json();

                dispatch(addSegmentOverlay(attributesData.segments));

                console.log(attributesData.segments);

              } catch (error) {
                console.error('Error posting segments:', error);
              }
            }}
            disabled={!route.routes[0].sections.some(section => section.spans?.some(span => span.segmentRef))}
          >
            {route.routes[0].sections.some(section => section.spans?.some(span => span.segmentRef)) ? 'Get Route Details' : 'Select the Get Segment ID param'}
          </Button>
          <div id="car-panel">
            {route.routes[0].sections.map((section) => {
              return section.actions?.map((action) => {
                return <div key={action.instruction}>{action.instruction}</div>;
              });
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default RouteBox;
