import React from "react";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import {
  removeDestination,
  removeOrigin,
  selectDestination,
  selectOrigin,
} from "stores/routing";
import { formatPoint } from "../AvoidArea/utils.avoidarea";

export const OriginDestinationSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const origin = useAppSelector(selectOrigin);
  const destination = useAppSelector(selectDestination);

  return (
    <List>
      <ListItem disablePadding>
        {origin ? (
          <ListItemText primary={`Origin: ${formatPoint(origin)}`} />
        ) : (
          <ListItemText primary={`Origin:`} />
        )}
        <ListItemButton onClick={() => dispatch(removeOrigin())}>
          <ListItemText primary={`Delete`} />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        {destination ? (
          <ListItemText primary={`Destination: ${formatPoint(destination)}`} />
        ) : (
          <ListItemText primary={`Destination:`} />
        )}
        <ListItemButton onClick={() => dispatch(removeDestination())}>
          <ListItemText primary={`Delete`} />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
