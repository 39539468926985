// AdjustValueComponent.js
import { SidebarContainer } from "map/Sidebar/SidebarContainer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "stores/hooks";
import { isMapGridVisible, toggleVisibility } from "stores/mapGrid";

export const OpenAvoidAreas: React.FC = () => {
  const isGridVisible = useAppSelector(isMapGridVisible);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isGridVisible) {
      dispatch(toggleVisibility());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SidebarContainer />;
};
