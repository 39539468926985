import axios from "axios";
import { LatLngLiteral } from "map/types";
import { getURL } from "util/url";
import martinez from "martinez-polygon-clipping";
import {
  ABSENT,
  DEFAULT_PRECISION,
  Position,
  encode as encodePolyline,
} from "util/flexible-polyline";

export const decodeBboxFromPolyline = (
  polyline: string
): { topLeft: LatLngLiteral; bottomRight: LatLngLiteral } => {
  try {
    const points =
      H.geo.LineString.fromFlexiblePolyline(polyline).getLatLngAltArray();
    const topLeft = {
      lat: points[0],
      lng: points[4],
    };
    const bottomRight = {
      lat: points[3],
      lng: points[1],
    };
    return {
      topLeft,
      bottomRight,
    };
  } catch (e) {
    return {
      topLeft: { lat: 0, lng: 0 },
      bottomRight: { lat: 0, lng: 0 },
    };
  }
};

export const formatPoint = (point: LatLngLiteral): string =>
  `${Math.abs(point.lat).toFixed(5)}° ${point.lat > 0 ? "N" : "S"}, ${Math.abs(
    point.lng
  ).toFixed(5)}° ${point.lng > 0 ? "E" : "W"}`;

export const reverseGeocodeState = async (
  point: LatLngLiteral
): Promise<string> => {
  const response = await axios.get(
    "https://revgeocode.search.hereapi.com/v1/revgeocode?apiKey=WjvhoAY5dh52iXQP60PCqNWJAplHBVoeCs0rzUGRz98&at=" +
      point.lat +
      "," +
      point.lng
  );
  return response.data.items[0].address.stateCode;
};

export const getGridLocation = async (
  point: LatLngLiteral,
  bearerToken: string = ""
): Promise<string> => {
  const response = await axios.get(
    `${getURL()}/map/gridlocation?location=` + point.lat + "," + point.lng,
    {
      headers: {
        Authorization: "Bearer " + bearerToken,
      },
    }
  );
  return response.data.zone;
};

export const isPolylineBoundingBox = (polyline: string): boolean => {
  const points =
    H.geo.LineString.fromFlexiblePolyline(polyline).getLatLngAltArray();
  return points.length === 6;
};

/**
 * Check if a polygon formed by an array of positions is self-intersecting.
 * @param {Position[]} positions Array of positions [lat, lng, alt?].
 * @returns {boolean} `true` if the polygon is self-intersecting, otherwise `false`.
 */
export function isSelfIntersecting(positions: Position[]): boolean {
  try {
    if (positions.length < 3) {
      throw new Error(
        "Invalid polygon: A polygon must have at least 3 points."
      );
    }

    // Convert positions [lat, lng, alt?] to GeoJSON coordinates [lng, lat]
    const coordinates = positions.map((pos) => [pos[1], pos[0]]);

    // Ensure the polygon is enclosed by duplicating the first point at the end
    if (
      !(
        coordinates[0][0] === coordinates[coordinates.length - 1][0] &&
        coordinates[0][1] === coordinates[coordinates.length - 1][1]
      )
    ) {
      coordinates.push([coordinates[0][0], coordinates[0][1]]);
    }

    const polygon = [coordinates];

    // Perform an intersection operation on the polygon with itself
    const result = martinez.intersection(polygon, polygon);

    // If result is not empty and equal to the input polygon itself, it is self-intersecting
    return result.length !== 0;
  } catch (error) {
    console.error("Error while checking self-intersection:", error);
    return false;
  }
}

function roundToFiveDecimals(number: number) {
  return Math.round(number * 1e5) / 1e5;
}

export const convertRectangularPolygonToBbox = (
  positions: Position[]
): [Position, Position] => {
  const lats = positions.map((point) => point[0]);
  const lngs = positions.map((point) => point[1]);

  const minLat = Math.min(...lats);
  const maxLat = Math.max(...lats);
  const minLng = Math.min(...lngs);
  const maxLng = Math.max(...lngs);

  return [
    [roundToFiveDecimals(maxLat), roundToFiveDecimals(minLng)], // Top-left
    [roundToFiveDecimals(minLat), roundToFiveDecimals(maxLng)], // Bottom-right
  ];
};

export const encodeDefaultPolyline = (positions: Position[]): string => {
  const encodedPayload = encodePolyline({
    precision: DEFAULT_PRECISION,
    thirdDim: ABSENT,
    thirdDimPrecision: 0,
    polyline: positions,
  });
  return encodedPayload;
};
