import React, { useContext, useEffect } from "react";
import { Card, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import { useForm, FormProvider } from "react-hook-form";
import { V8RouteResponse } from "map/types";
import {
  addMapBoxRoute,
  addRoute,
  selectEditedDepartureWaypoints,
  selectEditedWaypointApproach,
  selectWaypointApproach,
  selectDepartureWaypoints,
  selectOrigin,
  selectDestination,
  addOrigin,
  addDestination,
} from "stores/routing";
import { AuthContext } from "stores/AuthContext";
import { getEditedAvoidArea, getEditedAvoidPolygon } from "stores/mapGrid";
import { useHereMap } from "map/Context";
import { route, RouteParams } from "./route";
import WaypointApproachTools from "./WaypointApproachTools";
import AvoidAreaForm from "./AvoidArea/AvoidAreaForm";
import AvoidPolygonForm from "./AvoidArea/AvoidPolygonForm";
import { OriginDestinationSection } from "./SidebarTools/OriginDestinationSection";
import { RouteParametersSection } from "./SidebarTools/RouteParametersSection";
import RouteResultsSection from "./SidebarTools/RouteResultsSection";
import { useLocation } from "react-router-dom";
import BusZoneForm from "./BusZones/BusZoneForm";
import BusPolygonForm from "./BusZones/BusPolygonForm";
import { setFormValues } from "stores/routeForm";

const Tools: React.FC = () => {
  const { map } = useHereMap();

  const editedAvoidArea = useAppSelector(getEditedAvoidArea);
  const editedAvoidPolygon = useAppSelector(getEditedAvoidPolygon);
  const waypointApproach = useAppSelector(selectWaypointApproach());
  const editedWaypointApproach = useAppSelector(selectEditedWaypointApproach());
  const departureWaypoints = useAppSelector(selectDepartureWaypoints());
  const editedDepartureWaypoints = useAppSelector(
    selectEditedDepartureWaypoints()
  );
  const origin = useAppSelector(selectOrigin);
  const destination = useAppSelector(selectDestination);

  const dispatch = useAppDispatch();
  const savedFormValues = useAppSelector((state) => state.routeForm);

  const authContext = useContext(AuthContext);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const originlat = queryParams.get("originlat");
    const originlng = queryParams.get("originlng");
    const destinationlat = queryParams.get("destinationlat");
    const destinationlng = queryParams.get("destinationlng");

    if (originlat && originlng) {
      dispatch(
        addOrigin({
          lat: parseFloat(originlat),
          lng: parseFloat(originlng),
        })
      );
    }
    if (destinationlat && destinationlng) {
      dispatch(
        addDestination({
          lat: parseFloat(destinationlat),
          lng: parseFloat(destinationlng),
        })
      );
    }
  }, [dispatch, location.search]);

  if (!authContext) {
    throw new Error("AuthContext is not available");
  }
  const { keycloak } = authContext;
  if (!keycloak) {
    throw new Error("Keycloak is not available");
  }

  const onSuccess = (result: V8RouteResponse, isMapBox?: boolean) => {
    if (isMapBox) {
      dispatch(addMapBoxRoute({ type: "mapBox", route: result as any }));
    } else {
      result.routes.forEach((route, index) => {
        const { mode } = route.sections[0].transport;
        if (mode === "car" || mode === "truck" || mode === "bus") {
          const singleRouteResult: V8RouteResponse = {
            ...result,
            routes: [route],
          };
          dispatch(
            addRoute({
              type: `${mode}${index}`, // Attach index to the mode
              route: singleRouteResult,
            })
          );
        }
      });
    }
  };

  const onSuccessNP = (result: V8RouteResponse) => {
    result.routes.forEach((route, index) => {
      const { mode } = route.sections[0].transport;
      if (mode === "car" || mode === "truck" || mode === "bus") {
        const singleRouteResult: V8RouteResponse = {
          ...result,
          routes: [route],
        };
        dispatch(
          addRoute({
            type: `${mode}NP${index}`, // Attach index to the mode
            route: singleRouteResult,
          })
        );
      }
    });
  };

  const onError = (e: Error) => {
    console.error(e);
  };

  const initialValues: RouteParams = {
    dirtRoad: false,
    tollRoad: false,
    highway: false,
    tunnel: false,
    ferry: false,
    difficultTurns: false,
    uTurns: false,
    propane: false,
    height: "",
    length: "",
    width: "",
    weight: "",
    car: true,
    bus: true,
    truck: true,
    compareParams: false,
    approachWaypoints: false,
    departureWaypoints: false,
    segmentRefs: false,
    traffic: "longterm",
    alternatives: 0,
  };

  const methods = useForm({
    defaultValues: { ...initialValues, ...savedFormValues },
  });

  const { handleSubmit, watch } = methods;

  const { logout } = useContext(AuthContext);

  useEffect(() => {
    const subscription = watch((values) => {
      dispatch(setFormValues(values));
    });
    return () => subscription.unsubscribe();
  }, [dispatch, watch]);

  const editedAvoid = editedAvoidArea || editedAvoidPolygon;

  const onSubmit = (data: RouteParams) => {
    if (editedAvoidArea && editedAvoidPolygon) {
      alert("This shouldn't happen.");
      return;
    }
    route(
      onSuccess,
      onSuccessNP,
      onError,
      keycloak.token!,
      data,
      logout,
      map,
      origin,
      destination,
      editedAvoid ? editedAvoid.coordinates : undefined,
      editedWaypointApproach ?? waypointApproach,
      editedDepartureWaypoints ?? departureWaypoints
    );
  };

  return (
    <FormProvider {...methods}>
      <Stack spacing={2}>
        <Card sx={{ overflowY: "auto", height: "100%", width: "100%" }}>
          <WaypointApproachTools />
        </Card>
        <Card sx={{ overflowY: "auto", height: "100%" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} p={2}>
              <OriginDestinationSection />
              <RouteParametersSection />
              <RouteResultsSection />
            </Stack>
          </form>
          <AvoidAreaForm />
          <AvoidPolygonForm />
          <BusZoneForm />
          <BusPolygonForm />
        </Card>
      </Stack>
    </FormProvider>
  );
};

export default Tools;
