import React from "react";
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { RoadReport } from "sonar/types/RoadReport";

interface UpdateLocationProps {
  oldRoadReport?: RoadReport;
  newRoadReport: RoadReport;
  showUpdate: boolean;
  setShowUpdate: (show: boolean) => void;
  onUpdate: (roadReport: RoadReport) => void;
}

const UpdateLocation: React.FC<UpdateLocationProps> = ({
  oldRoadReport,
  newRoadReport,
  showUpdate,
  setShowUpdate,
  onUpdate,
}) => {
  const handleClose = () => {
    setShowUpdate(false);
  };
  return (
    <Dialog
      open={showUpdate}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle>Update Road Report Location</DialogTitle>
      <DialogContent>
        <ReactDiffViewer
          oldValue={oldRoadReport}
          newValue={newRoadReport}
          splitView={true}
          compareMethod={DiffMethod.JSON}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onUpdate(newRoadReport);
            handleClose();
          }}
        >
          Submit Update
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLocation;
