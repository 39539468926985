import {
  S3Client,
  GetObjectCommand,
  GetObjectCommandOutput,
} from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import {
  TimestreamQueryClient,
  QueryCommand,
  QueryCommandOutput,
} from "@aws-sdk/client-timestream-query";

// Set your AWS region
const REGION = "us-east-1";

// Set your AWS credentials
const credentials = fromCognitoIdentityPool({
  clientConfig: { region: REGION },
  identityPoolId: "us-east-1:b11435b7-f4a2-4ec7-b577-6b4c5eb27c3e",
});

// Create a new S3 client with the configured credentials
const s3Client = new S3Client({
  region: REGION,
  credentials,
});

const timeStreamCredentials = {
  accessKeyId: "AKIA4ICIEBAD3QUXXKE5",
  secretAccessKey: "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",
};

const timestreamQueryClient = new TimestreamQueryClient({
  region: REGION,
  credentials: timeStreamCredentials,
});

const timeStreamOneHourParams = {
  QueryString:
    'SELECT COUNT(DISTINCT route_hash_id) AS unique_route_count FROM "RoutingAnalytics"."locationPoints" WHERE time >= ago(1h)',
};

const timeStreamTwentyFourHourParams = {
  QueryString:
    'SELECT COUNT(DISTINCT route_hash_id) AS unique_route_count FROM "RoutingAnalytics"."locationPoints" WHERE time >= ago(24h)',
};

const bucketName = "location-points";
const key = "latest-location.json";

const params = {
  Bucket: bucketName,
  Key: key,
};

export const getS3Points = async (): Promise<GetObjectCommandOutput> => {
  const command = new GetObjectCommand(params);
  const data = await s3Client.send(command);
  return data;
};

export const oneHourStream = async (): Promise<QueryCommandOutput> => {
  const command = new QueryCommand(timeStreamOneHourParams);
  const data = await timestreamQueryClient.send(command);
  return data;
};

export const twentyFourHourStream = async (): Promise<QueryCommandOutput> => {
  const command = new QueryCommand(timeStreamTwentyFourHourParams);
  const data = await timestreamQueryClient.send(command);
  return data;
};

export const fetchHourlyRoutes = async () => {
  const queryString = `
    SELECT BIN(time, 1h) as hour, COUNT(DISTINCT route_hash_id) AS unique_route_hash_ids
    FROM "RoutingAnalytics"."locationPoints"
    WHERE time >= ago(24h)
    GROUP BY BIN(time, 1h)
    ORDER BY hour DESC
  `;
  const command = new QueryCommand({ QueryString: queryString });
  const data = await timestreamQueryClient.send(command);
  return data;
};

const TIME_RANGES = {
  "1h": "1h",
  "8h": "8h",
  "1d": "24h",
  "3d": "3d",
  "7d": "7d",
};

export enum TimeWindow {
  ONE_HOUR = "1h",
  EIGHT_HOURS = "8h",
  ONE_DAY = "1d",
  THREE_DAYS = "3d",
  SEVEN_DAYS = "7d",
}

export const fetchRoutes = async (timeWindow: TimeWindow) => {
  const queryString = `SELECT COUNT(DISTINCT route_hash_id) AS unique_route_count FROM "RoutingAnalytics"."locationPoints" WHERE time >= ago(${TIME_RANGES[timeWindow]})`;

  const command = new QueryCommand({ QueryString: queryString });
  const data = await timestreamQueryClient.send(command);
  return data;
};

export const fetchCompletedRoutes = async (timeWindow: TimeWindow) => {
  const queryString = `
SELECT COUNT(DISTINCT route_hash_id) AS unique_route_count 
FROM "RoutingAnalytics"."locationPoints" 
WHERE time >= ago(${TIME_RANGES[timeWindow]})
  AND route_stop_reason = 'End Reached - Trip Finished'
`;

  const command = new QueryCommand({ QueryString: queryString });
  const data = await timestreamQueryClient.send(command);
  return data;
};
