import React from "react";
import Keycloak from "keycloak-js";

type AuthContextType = {
  keycloak: Keycloak | null;
  authenticated: boolean;
  logout: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  keycloak: null,
  authenticated: false,
  logout: () => undefined,
});
