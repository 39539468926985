import React, { useState, useEffect, ReactNode } from "react";
import Keycloak from "keycloak-js";
import { AuthContext } from "./AuthContext"; // Make sure this also contains the precise types for your context.

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // in ms

const initKeycloakInstance = async (
  retries: number,
  keycloakInstance: Keycloak,
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  try {
    const authenticated = await keycloakInstance.init({
      onLoad: "login-required",
      pkceMethod: "S256",
      checkLoginIframe: false,
    });
    setAuthenticated(authenticated);
  } catch (err) {
    if (retries < MAX_RETRIES) {
      console.warn(
        `Keycloak initialization error, attempt ${retries + 1}. Retrying in ${
          RETRY_DELAY / 1000
        } seconds.`
      );
      console.error(err);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return initKeycloakInstance(
        retries + 1,
        keycloakInstance,
        setAuthenticated,
        setError,
        setLoading
      );
    } else {
      console.error("Max retries reached. Failed to initialize Keycloak:", err);
      setError("Failed to initialize Keycloak after multiple attempts.");
    }
  } finally {
    setLoading(false);
  }
};

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [keycloak, setKeycloak] = useState<Keycloak | null>(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Create Keycloak instance only once
    const keycloakInstance = new Keycloak({
      url:
        process.env.NODE_ENV === "development"
          ? "https://keycloak-dev.auth.aws-dev-rvlife.com/"
          : "https://keycloak.auth.aws-prod-rvlife.com/",
      realm: "rvlife",
      clientId: process.env.NODE_ENV === "development" ? "atlas-dev" : "atlas",
    });

    setKeycloak(keycloakInstance); // Set the Keycloak instance once

    // Initialize Keycloak with retry logic if an error occurs
    initKeycloakInstance(
      0,
      keycloakInstance,
      setAuthenticated,
      setError,
      setLoading
    );
  }, []);

  const logout = () => {
    if (keycloak) {
      keycloak.logout();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <AuthContext.Provider value={{ keycloak, authenticated, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
