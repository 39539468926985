import React from "react";
import Marker from "../Marker";
import { LatLngLiteral } from "map/types";
import InfoBubble from "../InfoBubble";
import { Button } from "@mui/material";
import { ABSENT, DEFAULT_PRECISION, encode } from "util/flexible-polyline";
import MultiPolyline from "../MultiPolyline";
import { createIcon } from "map/util/createIcon";
import Construction from "assets/svg/construction.svg";
import HighWinds from "assets/svg/high-winds.svg";
import RoadHazard from "assets/svg/road-hazard.svg";
import RoadClosed from "assets/svg/road-closed.svg";
import RoadTraffic from "assets/svg/road-traffic.svg";
import LocationMarker from "assets/svg/location-marker.svg";
import RoadNarrow from "assets/svg/road-narrow.svg"

interface Incident {
  location: { shape: { links: { points: LatLngLiteral[] }[] } };
  incidentDetails: any;
}

const IncidentIcons: { [key: string]: string } = {
  accident: RoadHazard,
  roadClosure: RoadClosed,
  laneRestriction: RoadNarrow,
  congestion: RoadTraffic,
  disabledVehicle: RoadHazard,
  massTransit: RoadHazard,
  plannedEvent: RoadHazard,
  roadHazard: RoadHazard,
  weather: HighWinds,
  construction: Construction,
  other: LocationMarker,
};

const IncidentMarker: React.FC<{ incident: Incident }> = ({ incident }) => {
  const [polylines, setPolylines] = React.useState<string[]>([]);


  const incidentLocation = incident.location;
  if (!incidentLocation || !incidentLocation.shape || !incidentLocation.shape.links) {
    // Exit the function early if the necessary properties do not exist
    console.log(incidentLocation)
    console.log('this incident has no links it seems')
    return;
  }

  const convertPolylines = () => {
    
    
    const links = incidentLocation.shape.links;
  
    const polylines = links.map((link) => {
      return encode({
        precision: DEFAULT_PRECISION,
        thirdDim: ABSENT,
        thirdDimPrecision: 0,
        polyline: link.points.map((point) => [point.lat, point.lng]),
      });
    });
  
    setPolylines(polylines);
  };

  const markerNode = (
    <InfoBubble maxHeight={1200} maxWidth={1200}>
      <strong>Incident Type:</strong> {incident.incidentDetails.type}
      <br />
      <strong>Description:</strong> {incident.incidentDetails.description.value}
      <br />
      <strong>End Time:</strong> {incident.incidentDetails.endTime}
      <Button onClick={convertPolylines} variant="outlined">
        View Polylines
      </Button>
    </InfoBubble>
  );

  const position = {
    lat: incident.location.shape.links[0].points[0].lat,
    lng: incident.location.shape.links[0].points[0].lng,
  };

  const onPointerEnter = () => {
    document.body.style.cursor = "pointer";
  };

  const onPointerLeave = () => {
    document.body.style.cursor = "";
  };

  const icon = createIcon({
    iconUrl:
      (incident.incidentDetails.type
        ? IncidentIcons[incident.incidentDetails.type]
        : null) || LocationMarker,
    iconSize: [40, 40],
  });

  return (
    <>
      <Marker
        position={position}
        popup={markerNode}
        key={incident.incidentDetails.id}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        icon={icon}
      />
      <MultiPolyline
        polylines={polylines}
        lineWidth={8}
        strokeColor="rgba(256, 0, 0, 1)"
      />
    </>
  );
};

export default IncidentMarker;
