import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { makeStyles } from "@mui/styles";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { DateTime } from "luxon";

Chart.register(CategoryScale);

const useStyles = makeStyles((theme) => ({
  graphContainer: {
    width: "80%",
    margin: "auto",
    marginTop: 4,
    marginBottom: 4,
  },
}));

export interface AverageDataPoint {
  date: string;
  average: number;
}

const AverageGraph: React.FC<{ data: AverageDataPoint[] }> = ({ data }) => {
  const classes = useStyles();
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: any[];
  }>({ labels: [], datasets: [] });

  useEffect(() => {
    const sixMonthsAgo = DateTime.now().minus({ months: 6 });

    const filteredData = data
      .map((item) => ({
        ...item,
        dateTime: DateTime.fromFormat(item.date, "yyyyMMdd", {
          zone: "utc",
        }).setZone("America/Chicago"),
      }))
      .filter((item) => item.dateTime >= sixMonthsAgo);

    const labels = filteredData.map((item) =>
      item.dateTime.toFormat("MM/dd/yyyy")
    );
    const values = filteredData.map((item) => item.average);

    setChartData({
      labels: labels,
      datasets: [
        {
          label: "Average Ratings Over Time",
          data: values,
          fill: false,
          borderColor: "#3f51b5",
          tension: 0.4,
        },
      ],
    });
  }, [data]);

  return (
    <div className={classes.graphContainer}>
      <Line data={chartData} />
    </div>
  );
};

export default AverageGraph;
