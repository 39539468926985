import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "stores/AuthContext";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import {
  discardChanges,
  saveChanges,
  deleteAvoidArea,
  getEditedAvoidPolygon,
  setEditedAvoidArea,
  deleteAvoidPolygonVertex,
} from "stores/mapGrid";
import ConfirmationPopup from "support/components/Confirmation";
import {
  formatPoint,
  getGridLocation,
  isSelfIntersecting,
  reverseGeocodeState,
} from "./utils.avoidarea";
import { IAvoidArea } from "stores/types";
import { selectOrigin, selectDestination } from "stores/routing";
import {
  ABSENT,
  DEFAULT_PRECISION,
  Position,
  decode,
  encode as encodePolyline,
} from "util/flexible-polyline";
import { parseISO } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";

interface IAvoidAreaFormValues {
  name: string;
  desc: string;
  height: number;
  heightFeet: number;
  heightInches: number;
  length: number;
  lengthFeet: number;
  lengthInches: number;
  weight: number;
  width: number;
  widthFeet: number;
  widthInches: number;
  testCoords: boolean;
  reminderDate: Date | null;
  startDate: Date | null;
  endDate: Date | null;
}

const AvoidPolygonForm: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = React.useState(false);

  const editedAvoidPolygon = useAppSelector(getEditedAvoidPolygon);
  const origin = useAppSelector(selectOrigin);
  const destination = useAppSelector(selectDestination);

  const { keycloak } = React.useContext(AuthContext);

  const defaultValues: IAvoidAreaFormValues = {
    desc: "",
    height: 0,
    heightFeet: 0,
    heightInches: 0,
    length: 0,
    lengthFeet: 0,
    lengthInches: 0,
    name: "New Avoid Polygon",
    weight: 0,
    width: 0,
    widthFeet: 0,
    widthInches: 0,
    testCoords: false,
    reminderDate: null,
    startDate: null,
    endDate: null,
  };

  if (editedAvoidPolygon) {
    (Object.keys(defaultValues) as Array<keyof IAvoidAreaFormValues>).forEach(
      (key) => {
        if (key in editedAvoidPolygon) {
          const value = editedAvoidPolygon[key as keyof IAvoidArea];
          if (
            (key === "reminderDate" ||
              key === "startDate" ||
              key === "endDate") &&
            typeof value === "string"
          ) {
            (defaultValues[key] as Date) = parseISO(value);
          } else if (value !== undefined) {
            if (key === "height" || key === "length" || key === "width") {
              const inches = Math.round(Number(value) / 2.54);
              (defaultValues[`${key}Feet` as keyof IAvoidAreaFormValues] as number) = Math.floor(inches / 12);
              (defaultValues[`${key}Inches` as keyof IAvoidAreaFormValues] as number) = inches % 12;
            } else if (key === "weight") {
              (defaultValues.weight as number) = Math.round(Number(value) * 2.20462);
            } else if (key in defaultValues) {
              (defaultValues[key as keyof IAvoidAreaFormValues] as typeof value) = value;
            }
          }
        }
      }
    );
  }

  const { handleSubmit, control, reset } = useForm({ defaultValues });

  React.useEffect(() => {
    if (editedAvoidPolygon) {
      const updatedValues = { ...defaultValues };
      (Object.keys(defaultValues) as Array<keyof IAvoidAreaFormValues>).forEach(
        (key) => {
          if (key in editedAvoidPolygon) {
            const value = editedAvoidPolygon[key as keyof IAvoidArea];
            if (
              (key === "reminderDate" ||
                key === "startDate" ||
                key === "endDate") &&
              typeof value === "string"
            ) {
              (updatedValues[key] as Date) = parseISO(value);
            } else if (value !== undefined) {
              if (key === "height" || key === "length" || key === "width") {
                const inches = Math.round(Number(value) / 2.54);
                (updatedValues[`${key}Feet` as keyof IAvoidAreaFormValues] as number) = Math.floor(inches / 12);
                (updatedValues[`${key}Inches` as keyof IAvoidAreaFormValues] as number) = inches % 12;
              } else if (key === "weight") {
                (updatedValues.weight as number) = Math.round(Number(value) * 2.20462);
              } else if (key in updatedValues) {
                (updatedValues[key as keyof IAvoidAreaFormValues] as typeof value) = value;
              }
            }
          }
        }
      );
      reset(updatedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedAvoidPolygon]);

  if (!editedAvoidPolygon) {
    return null;
  }

  const coordinates = decode(editedAvoidPolygon.coordinates);

  const onSubmit = async (formData: IAvoidAreaFormValues) => {
    if (coordinates) {
      if (isSelfIntersecting(coordinates.polyline)) {
        alert("Avoid Area cannot intersect itself.");
        return;
      }

      const gridPromises = coordinates.polyline.map(([lat, lng]) => {
        const grid = getGridLocation({ lat, lng }, keycloak?.token);
        return grid;
      });

      const statePromises = coordinates.polyline.map(([lat, lng]) => {
        const state = reverseGeocodeState({ lat, lng });
        return state;
      });

      Promise.all(gridPromises)
        .then((resolvedGridLocations) => {
          return Promise.all(statePromises)
            .then((resolvedStates) => {
              if (new Set(resolvedStates).size > 1) {
                alert(
                  "Avoid Areas must be in the same state. States: " +
                  Array.from(resolvedStates).join(", ")
                );
                return;
              }
              if (new Set(resolvedGridLocations).size > 1) {
                alert(
                  "Avoid Areas must be in the same zone. Grids: " +
                  Array.from(resolvedGridLocations).join(", ")
                );
                return;
              }

              const newAvoidArea = { ...editedAvoidPolygon };
              const zone = resolvedGridLocations[0];
              const state = resolvedStates[0];

              newAvoidArea.zone = zone;
              newAvoidArea.state = state;
              newAvoidArea.name = formData.name;
              newAvoidArea.desc = formData.desc ?? "";
              newAvoidArea.height = Math.round(((formData.heightFeet * 12 + formData.heightInches) * 2.54));
              newAvoidArea.length = Math.round(((formData.lengthFeet * 12 + formData.lengthInches) * 2.54));
              newAvoidArea.weight = Math.round(Number(formData.weight) / 2.20462);
              newAvoidArea.width = Math.round(((formData.widthFeet * 12 + formData.widthInches) * 2.54));

              if (formData.reminderDate)
                newAvoidArea.reminderDate = formData.reminderDate.toISOString();
              if (formData.startDate)
                newAvoidArea.startDate = formData.startDate.toISOString();
              if (formData.endDate)
                newAvoidArea.endDate = formData.endDate.toISOString();

              if (formData.testCoords) {
                if (origin && destination) {
                  const payload = [
                    [origin.lat, origin.lng],
                    [destination.lat, destination.lng],
                  ] as Position[];
                  const encodedPayload = encodePolyline({
                    precision: DEFAULT_PRECISION,
                    thirdDim: ABSENT,
                    thirdDimPrecision: 0,
                    polyline: payload,
                  });

                  newAvoidArea.testCoordinates = encodedPayload;
                } else {
                  alert("Please set an origin and destination first.");
                  return;
                }
              }

              dispatch(setEditedAvoidArea(newAvoidArea));

              if (keycloak?.token) dispatch(saveChanges(keycloak.token));
            })

            .catch((error) => {
              console.error("Error resolving states:", error);
            });
        })
        .catch((error) => {
          console.error("Error resolving grid locations:", error);
        });
    }
  };

  const discardGridChanges = () => {
    dispatch(discardChanges());
  };

  const formattedCoordinates = coordinates?.polyline.map((coord) =>
    formatPoint({ lat: coord[0], lng: coord[1] })
  );

  const coordinateFields = formattedCoordinates?.map((coord, index) => (
    <Stack direction="row" key={coord}>
      <TextField
        key={index}
        label={`Coordinate ${index + 1}`}
        value={coord}
        disabled
        fullWidth
      />
      <Button
        onClick={() => {
          dispatch(deleteAvoidPolygonVertex(index));
        }}
        variant="outlined"
        sx={{ m: 1 }}
      >
        <DeleteIcon />
      </Button>
    </Stack>
  ));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2}>
        {coordinateFields}
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField label="Name" error={!!error} {...field} />
          )}
          rules={{ required: true }}
        />
        <Controller
          name="desc"
          control={control}
          render={({ field }) => (
            <TextField
              label="Description"
              multiline
              minRows={3}
              maxRows={3}
              {...field}
            />
          )}
        />
        <Grid container>
          <Grid item xs={6}>
            <Controller
              name="heightFeet"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  type="number"
                  label="Height (ft)"
                  error={!!error}
                  {...field}
                />
              )}
              rules={{ required: true, min: 0 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="heightInches"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  type="number"
                  label="Height (in)"
                  error={!!error}
                  {...field}
                />
              )}
              rules={{ required: true, min: 0, max: 11 }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Controller
              name="lengthFeet"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  type="number"
                  label="Length (ft)"
                  error={!!error}
                  {...field}
                />
              )}
              rules={{ required: true, min: 0 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="lengthInches"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  type="number"
                  label="Length (in)"
                  error={!!error}
                  {...field}
                />
              )}
              rules={{ required: true, min: 0, max: 11 }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Controller
              name="widthFeet"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  type="number"
                  label="Width (ft)"
                  error={!!error}
                  {...field}
                />
              )}
              rules={{ required: true, min: 0 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="widthInches"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  type="number"
                  label="Width (in)"
                  error={!!error}
                  {...field}
                />
              )}
              rules={{ required: true, min: 0, max: 11 }}
            />
          </Grid>
        </Grid>
        <Controller
          name="weight"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              type="number"
              label="Weight (lbs)"
              error={!!error}
              {...field}
            />
          )}
          rules={{ required: true, min: 0 }}
        />

        <Controller
          name="testCoords"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox disabled={!origin || !destination} {...field} />
              }
              label="Save origin/dest as test coords"
            />
          )}
        />

        <Controller
          name="reminderDate"
          control={control}
          render={({ field }) => (
            <DatePicker label="Remind Me On This Date" {...field} />
          )}
        />

        <Controller
          name="startDate"
          control={control}
          render={({ field }) => <DatePicker label="Start Date" {...field} />}
        />

        <Controller
          name="endDate"
          control={control}
          render={({ field }) => <DatePicker label="End Date" {...field} />}
        />

        <React.Fragment>
          <Button variant="outlined" onClick={discardGridChanges}>
            Discard Changes
          </Button>
        </React.Fragment>

        <Button variant="outlined" type="submit">
          Save Changes
        </Button>
        <Button variant="outlined" onClick={() => setIsDeleting(true)}>
          Delete
        </Button>
        <ConfirmationPopup
          isOpen={isDeleting}
          onDelete={() => {
            if (keycloak?.token)
              dispatch(deleteAvoidArea(editedAvoidPolygon.id, keycloak?.token));
          }}
          onCancel={() => {
            setIsDeleting(false);
          }}
        />
      </Stack>
    </form>
  );
};

export default AvoidPolygonForm;
