import React from "react";
import {
  addActiveApproachWaypoint,
  selectActiveApproachWaypoint,
  selectDestination,
  selectEditedWaypointApproach,
  selectWaypointApproach,
} from "stores/routing";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import WaypointPolyline from "../WaypointPolyline";

const WaypointApproach: React.FC = () => {
  const dispatch = useAppDispatch();
  const route = useAppSelector(selectWaypointApproach());
  const editedRoute = useAppSelector(selectEditedWaypointApproach());
  const activeApproachWaypoint = useAppSelector(selectActiveApproachWaypoint());
  const onClick = (index: number) => {
    dispatch(addActiveApproachWaypoint(index));
  };
  const destination = useAppSelector(selectDestination);
  return (
    <WaypointPolyline
      route={route}
      editedRoute={editedRoute}
      activeWaypoint={activeApproachWaypoint}
      onClick={onClick}
      destination={destination}
    />
  );
};

export default WaypointApproach;
