import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Stack, Typography, Paper, Grid, Slider, Button, CircularProgress } from '@mui/material';
import { useAppSelector, useAppDispatch } from 'stores/hooks';
import { selectRoute } from 'stores/routing';
import {CommentPolylines as CommentPolylinesComponent} from 'map/elements/CommentPolylines';
import SonarAPI from 'sonar/SonarAPI';
import { AuthContext } from 'stores/AuthContext';
import { clearCommentPolylines, selectCommentPolylines, setCommentPolylines } from 'stores/commentPolylines';
import ShareIcon from '@mui/icons-material/Share';
import { decode, encode, simplifyPolylineByLength } from 'util/flexible-polyline';
import Polyline from 'map/elements/Polyline';

// Define the TypeScript interface for the response
interface CommentResponse {
  id: string;
  comment: string;
  area_match_ratio: number;
  intersection_polyline: string;
  buffered_polyline: string;
  rating: number;
}

// Update the interface for the search parameters
interface SearchParams {
  polyline: string;
  bufferDistance?: number;
  searchBufferDistance?: number;
  ratings?: number[];
  matchPercentage?: number;
}

export const CommentsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  const [searchParams, setSearchParams] = useState<SearchParams>({
    polyline: urlSearchParams.get('polyline') ?? '',
    bufferDistance: parseFloat(urlSearchParams.get('bufferDistance') ?? '0.01'),
    searchBufferDistance: parseFloat(urlSearchParams.get('searchBufferDistance') ?? '0.01'),
    ratings: urlSearchParams.get('ratings')?.split(',').map(Number) ?? [],
    matchPercentage: parseInt(urlSearchParams.get('matchPercentage') ?? '50')
  });

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);  const [selectedRatings, setSelectedRatings] = useState<number[]>(
    urlSearchParams.get('ratings')?.split(',').map(Number) ?? []
  );
  const [routeLineType] = useState(urlParams.get("routeLine") ?? "");

  const [similarRouteReports, setSimilarRouteReports] = useState<CommentResponse[]>([]);
  const existingReport = useAppSelector(selectCommentPolylines);

  const routeLine = searchParams.polyline;
  const authContext = React.useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is not available");
  }
  const { keycloak } = authContext;
  const token = keycloak?.token;

  // grab the route type from the search params
  const selectedRouteType = routeLineType as "car" | "truck" | "bus";
  const route = useAppSelector(selectRoute(selectedRouteType));

  const polylines = searchParams.polyline ? [searchParams.polyline] : route?.routes?.[0]?.sections?.map(
    (section: any) => section.polyline
  ) ?? [];

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const shouldFetchRef = React.useRef(true);

  const fetchSimilarRoutes = async () => {
    if (polylines.length > 0 && token) {
      setIsLoading(true);
      const sonarApi = new SonarAPI(token);
      try {
        const searchConfig: SearchParams = {
          polyline: polylines[0],
          bufferDistance: searchParams.bufferDistance,
          searchBufferDistance: searchParams.searchBufferDistance,
          ratings: selectedRatings.length > 0 ? selectedRatings : undefined,
          matchPercentage: searchParams.matchPercentage
        };
        const response = await sonarApi.getLikeRouteReports(
          searchConfig.polyline, 
          searchConfig.bufferDistance, 
          searchConfig.searchBufferDistance, 
          searchConfig.ratings,
          searchConfig.matchPercentage
        );
        const sortedResponse = response.sort((a: CommentResponse, b: CommentResponse) => 
          b.area_match_ratio - a.area_match_ratio
        );
        setSimilarRouteReports(sortedResponse || []);
      } catch (error) {
        console.error('Error fetching similar routes:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Add handler for rating selection
  const handleRatingToggle = (rating: number) => {
    setSelectedRatings(prev => 
      prev.includes(rating) 
        ? prev.filter(r => r !== rating)
        : [...prev, rating]
    );
  };

  // Update the search parameters section in the UI
  const renderSearchControls = () => (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="subtitle2" gutterBottom>
        Search Parameters
      </Typography>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="body2">
            Minimum Match Percentage: {searchParams.matchPercentage}%
          </Typography>
          <Slider
            value={searchParams.matchPercentage}
            onChange={(_, value) => setSearchParams(prev => ({
              ...prev,
              matchPercentage: value as number
            }))}
            min={0}
            max={100}
            step={1}
            marks={[
              { value: 0, label: '0%' },
              { value: 50, label: '50%' },
              { value: 100, label: '100%' }
            ]}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${value}%`}
          />
        </Stack>

        <Stack spacing={1}>
          <Typography variant="body2">
            Buffer Distance: {searchParams.bufferDistance}
          </Typography>
          <Slider
            value={searchParams.bufferDistance}
            onChange={(_, value) => setSearchParams(prev => ({
              ...prev,
              bufferDistance: value as number
            }))}
            min={0.001}
            max={0.2}
            step={0.001}
            marks={[
              { value: 0.001, label: '0.001' },
              { value: 0.025, label: '0.025' },
              { value: 0.2, label: '0.2' }
            ]}
            valueLabelDisplay="auto"
          />
        </Stack>

        <Stack spacing={1}>
          <Typography variant="body2">
            Search Buffer Distance: {searchParams.searchBufferDistance}
          </Typography>
          <Slider
            value={searchParams.searchBufferDistance}
            onChange={(_, value) => setSearchParams(prev => ({
              ...prev,
              searchBufferDistance: value as number
            }))}
            min={0.001}
            max={0.2}
            step={0.001}
            marks={[
              { value: 0.001, label: '0.001' },
              { value: 0.025, label: '0.025' },
              { value: 0.2, label: '0.2' }
            ]}
            valueLabelDisplay="auto"
          />
        </Stack>

        <Typography variant="subtitle2">Filter by Rating:</Typography>
        <Stack direction="row" spacing={1}>
          {[1, 2, 3, 4, 5].map((rating) => (
            <Paper
              key={rating}
              sx={{
                p: 1,
                cursor: 'pointer',
                bgcolor: selectedRatings.includes(rating) ? 'primary.main' : 'grey.100',
                color: selectedRatings.includes(rating) ? 'white' : 'text.primary',
              }}
              onClick={() => handleRatingToggle(rating)}
            >
              {rating}
            </Paper>
          ))}
        </Stack>

        <Button
          variant="outlined"
          onClick={handleShareSearch}
          sx={{ alignSelf: 'flex-end' }}
          startIcon={<ShareIcon />}
        >
          Share Search
        </Button>
      </Stack>
    </Paper>
  );

  const handleReportClick = (report: CommentResponse) => {
    // check if the report is already in the state
    if (existingReport.intersection === report.intersection_polyline && existingReport.buffered === report.buffered_polyline) {
      dispatch(clearCommentPolylines());
      return;
    }   
    dispatch(setCommentPolylines({
      intersection: report.intersection_polyline,
      buffered: report.buffered_polyline,
      comment: report.comment
    }));
  };


  const renderRouteDetails = () => {
    if (polylines.length === 0) {
      return (
        <Paper sx={{ p: 2, height: '100%' }}>
          <Stack spacing={2} alignItems="center">
            <Typography variant="subtitle1" color="text.secondary" align="center">
              No route polyline available to search on.
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              Please create a route by adding an origin and destination first.
            </Typography>
            <Button 
              variant="contained" 
              onClick={() => navigate('/')}
              sx={{ mt: 1 }}
            >
              Create Route
            </Button>
          </Stack>
        </Paper>
      );
    }

    return (
      <Paper sx={{ p: 2, height: '100%' }}>
        <Typography variant="subtitle1" gutterBottom>
          Route Details
        </Typography>
        <Stack spacing={1}>
          <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
            <strong>Route Sections:</strong>
          </Typography>
          {polylines.map((polyline, index) => (
            <Paper key={index} sx={{ p: 1, bgcolor: 'grey.100' }}>
              <Typography variant="caption">
                Section {index + 1}:
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  wordBreak: 'break-all', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis', 
                  whiteSpace: 'nowrap' 
                }}
              >
                {polyline}
              </Typography>
              <button 
                onClick={() => navigator.clipboard.writeText(polyline)}
                style={{ marginLeft: '10px' }}
              >
                Copy to Clipboard
              </button>
            </Paper>
          ))}
        </Stack>
      </Paper>
    );
  };

  const handleShareSearch = () => {
    const params = new URLSearchParams();
    
    // Add all search parameters to URL
    if (searchParams.bufferDistance !== undefined) {
      params.set('bufferDistance', searchParams.bufferDistance.toString());
    }
    if (searchParams.searchBufferDistance !== undefined) {
      params.set('searchBufferDistance', searchParams.searchBufferDistance.toString());
    }
    params.set('matchPercentage', searchParams.matchPercentage?.toString() ?? '50');
    
    // Add the polyline if available, but compress it much more aggressively
    if (polylines.length > 0) {
      // Parse the polyline into coordinates
      const coords = decode(polylines[0]);
      console.log("coords", coords);
      const compressedPolyline = simplifyPolylineByLength(coords, 300);
      params.set('polyline', encode({
        polyline: compressedPolyline,
        precision: coords.precision,
        thirdDim: coords.thirdDim,
        thirdDimPrecision: coords.thirdDimPrecision
      }));
      console.log("compressedPolyline", compressedPolyline);

    }
    
    if (selectedRatings.length > 0) {
      params.set('ratings', selectedRatings.join(','));
    }
    
    // Keep existing routeLine parameter if it exists
    const routeLine = urlSearchParams.get('routeLine');
    if (routeLine) {
      params.set('routeLine', routeLine);
    }

    // Update URL without reloading the page
    setUrlSearchParams(params);

    // Copy URL to clipboard
    const url = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
    navigator.clipboard.writeText(url).then(() => {
      console.log('Search URL copied to clipboard!');
    });
  };

  // Add effect to update search params when URL changes
  useEffect(() => {
    const bufferDistance = parseFloat(urlSearchParams.get('bufferDistance') ?? '0.01');
    const searchBufferDistance = parseFloat(urlSearchParams.get('searchBufferDistance') ?? '0.01');
    const matchPercentage = parseInt(urlSearchParams.get('matchPercentage') ?? '50');
    const ratings = urlSearchParams.get('ratings')?.split(',').map(Number) ?? [];

    setSearchParams(prev => ({
      ...prev,
      bufferDistance,
      searchBufferDistance,
      matchPercentage
    }));

    setSelectedRatings(ratings);
  }, [urlSearchParams]);

  // Add this effect near the other useEffect
  useEffect(() => {
    // Check if we have a polyline in the URL params
    const polylineFromUrl = urlSearchParams.get('polyline');
    
    // Only execute if we have both a polyline and a token
    if (polylineFromUrl && token && !isLoading && shouldFetchRef.current) {
      shouldFetchRef.current = false; // Prevent future executions
      fetchSimilarRoutes();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]); // Only run when token changes, which happens once on load

  
  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Comments for {routeLine} route
      </Typography>
      
      <Grid container spacing={2}>
        {/* Left column - Route Information */}
        <Grid item xs={12} md={12}>
          {renderRouteDetails()}
        </Grid>

        {/* Right column - Only show if there are polylines */}
        {polylines.length > 0 && (
          <Grid item xs={12} md={12}>
            <Paper sx={{ p: 2, height: '100%' }}>
              {renderSearchControls()}
              <Typography variant="subtitle1" gutterBottom>
                {similarRouteReports.length > 0 ? similarRouteReports.length + " Similar Route Reports" : "No Similar Routes Found"}
              </Typography>
              <Button
                variant="contained"
                onClick={() => fetchSimilarRoutes()}
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                sx={{ mb: 2 }}
              >
                {isLoading ? 'Fetching...' : 'Fetch Similar Routes'}
              </Button>
              <Stack spacing={2} sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
                {similarRouteReports.length > 0 ? (
                  similarRouteReports.map((report, index) => (
                    <Paper 
                      key={index} 
                      onClick={() => handleReportClick(report)}
                      sx={{ 
                        p: 2, 
                        bgcolor: existingReport.intersection === report.intersection_polyline ? '#fff59d' : 'grey.50',
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: existingReport.intersection === report.intersection_polyline ? '#fff176' : 'grey.100',
                        }
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">
                            <a href={`https://atlas.rvlife.com/support/routeHash/${report.id}`} target="_blank" rel="noopener noreferrer">
                              Report ID: {report.id}
                            </a>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            <strong>Comment:</strong> {report.comment}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            <strong>Match Ratio:</strong> {(report.area_match_ratio * 100).toFixed(1)}%
                          </Typography>
                      
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            <strong>Rating:</strong> {report.rating}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No similar routes found
                  </Typography>
                )}
              </Stack>
            </Paper>
          </Grid>
        )}
      </Grid>
      <CommentPolylinesComponent />
      {searchParams.polyline && (
        <Polyline
          positions={searchParams.polyline}
          lineWidth={4}
          lineDash={[2, 6]}
          strokeColor={"rgba(0, 128, 255, 0.7)"}
          lineDashOffset={4}
        />
      )}
    </Stack>
  );
};

export default CommentsPage;