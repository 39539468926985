import React from "react";
import Route from "./GenericRoute";
import MapBoxRoute from "./GenericRouteMapBox";
import { RouteKey, KindsOfRouting, RoutingModifiers } from "stores/types";

// Helper function to generate keys
const generateKeys = (): RouteKey[] => {
  const routingTypes: KindsOfRouting[] = ["car", "truck", "bus", "mapBox"];
  const modifiers: RoutingModifiers[] = ["", "NP"];
  const keys: RouteKey[] = [];

  routingTypes.forEach((type) => {
    if (type === "mapBox") {
      // Only add "mapBox" without modifiers or numbers
      keys.push("mapBox" as RouteKey);
    } else {
      modifiers.forEach((modifier) => {
        for (let number = 0; number <= 6; number++) {
          keys.push(`${type}${modifier}${number}` as RouteKey);
        }
        keys.push(`${type}${modifier}` as RouteKey); // Adding the key without number
      });
    }
  });

  return keys;
};

const Routes: React.FC = () => {
  const routeKeys = generateKeys();

  return (
    <>
      {routeKeys.map((key) => {
        // Render MapBoxRoute for keys related to mapBox
        if (key === "mapBox") {
          return <MapBoxRoute id={key} key={key} />;
        } else {
          return <Route id={key} key={key} />;
        }
      })}
    </>
  );
};

export default Routes;
