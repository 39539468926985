import React from 'react';
import { useAppSelector } from 'stores/hooks';
import { selectSegmentOverlay } from 'stores/routing';
import { Box, Typography, Paper } from '@mui/material';

const SegmentDetails: React.FC = () => {
    const segments = useAppSelector(selectSegmentOverlay());

    if (!segments) {
        return null;
    }

    return (
        <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
                Segment Details
            </Typography>
            {(() => {
                // Find segment with highest IRI
                let maxIRISegment = segments[0];
                let maxIRI = -Infinity;

                segments.forEach(segment => {
                    const fromIRI = Number(
                        segment.attributes.ROAD_ROUGHNESS_FCN?.[0]?.FROM_AVG_IRI || 0
                    );
                    const toIRI = Number(
                        segment.attributes.ROAD_ROUGHNESS_FCN?.[0]?.TO_AVG_IRI || 0
                    );
                    const iri = Math.max(fromIRI, toIRI);
                    if (iri > maxIRI) {
                        maxIRI = iri;
                        maxIRISegment = segment;
                    }
                });

                const roadData = maxIRISegment.attributes.ROAD_GEOM_FCN[0];
                const roughnessData = 
                    maxIRISegment.attributes.ROAD_ROUGHNESS_FCN?.[0];

                return (
                    <Paper 
                        key={maxIRISegment.segmentRef} 
                        sx={{ p: 2, mb: 2 }}
                    >
                        <Typography variant="subtitle1" gutterBottom>
                            Segment with Highest Roughness (IRI)
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                            Name: {roadData.NAME || 'Unnamed Segment'}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                            Location: ({Number(roadData.LAT.split(',')[0]) / 100000}, {Number(roadData.LON.split(',')[0]) / 100000})
                        </Typography>

                        {roughnessData && (
                            <Box sx={{ mb: 1 }}>
                                <Typography 
                                    variant="body2" 
                                    sx={{ 
                                        whiteSpace: 'pre-wrap', 
                                        wordBreak: 'break-word' 
                                    }}
                                >
                                    {JSON.stringify(roughnessData, null, 2)}
                                </Typography>
                            </Box>
                        )}

                    </Paper>
                );
            })()}
        </Box>
    );
};

export default SegmentDetails;
