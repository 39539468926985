import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LatLngLiteral, V8RouteResponse } from "map/types";
import { RootState } from "stores/store";
import { DiscoverItem } from "tools/Autocomplete";
import { RouteKey } from "./types";
import { decode, encode } from "util/flexible-polyline";

const initialState: {
  origin?: LatLngLiteral;
  destination?: LatLngLiteral;
  searchResult?: DiscoverItem;
  routes: {
    [key in RouteKey]?: V8RouteResponse;
  };
  mapBoxRoute?: any;
  waypointApproach?: WaypointApproach;
  activeApproachWaypoint?: number;
  editedWaypointApproach?: WaypointApproach;
  departureWaypoints?: WaypointApproach;
  activeDepartureWaypoint?: number;
  editedDepartureWaypoints?: WaypointApproach;
  segmentOverlay?: Segment[]
} = {
  routes: {},
};

export const routingReducer = createSlice({
  name: "routing",
  initialState,
  reducers: {
    addOrigin: (state, action: PayloadAction<LatLngLiteral>) => {
      state.origin = action.payload;
    },
    removeOrigin: (state) => {
      state.origin = undefined;
    },
    addDestination: (state, action: PayloadAction<LatLngLiteral>) => {
      state.destination = action.payload;
    },
    removeDestination: (state) => {
      state.destination = undefined;
    },
    addSearchResult: (state, action: PayloadAction<DiscoverItem>) => {
      state.searchResult = action.payload;
    },
    removeSearchResult: (state) => {
      state.searchResult = undefined;
    },
    addRoute: (
      state,
      action: PayloadAction<{
        type: RouteKey;
        route: V8RouteResponse;
      }>
    ) => {
      state.routes[action.payload.type] = action.payload.route;
    },
    addMapBoxRoute: (
      state,
      action: PayloadAction<{
        type: RouteKey;
        route: any;
      }>
    ) => {
      state.mapBoxRoute = action.payload.route;
    },
    removeMapBoxRoute: (state) => {
      state.mapBoxRoute = undefined;
    },


    addWaypointApproach: (state, action: PayloadAction<WaypointApproach>) => {
      state.waypointApproach = action.payload;
    },
    removeWaypointApproach: (state) => {
      state.waypointApproach = undefined;
    },

    addActiveApproachWaypoint: (state, action: PayloadAction<number>) => {
      state.activeApproachWaypoint = action.payload;
    },
    removeActiveApproachWaypoint: (state) => {
      state.activeApproachWaypoint = undefined;
    },

    addEditedWaypointApproach: (
      state,
      action: PayloadAction<WaypointApproach>
    ) => {
      state.editedWaypointApproach = action.payload;
    },
    removeEditedWaypointApproach: (state) => {
      state.editedWaypointApproach = undefined;
    },

    insertApproachWaypoint: (state, action: PayloadAction<LatLngLiteral>) => {
      const insertionIndex = state.activeApproachWaypoint ?? 0;
      if (state.editedWaypointApproach) {
        const decodedPolyline = decode(state.editedWaypointApproach.polyline);
        decodedPolyline.polyline.splice(Math.max(insertionIndex, 0), 0, [
          action.payload.lat,
          action.payload.lng,
        ]);
        state.editedWaypointApproach.polyline = encode(decodedPolyline);
        state.activeApproachWaypoint = Math.max(insertionIndex, 0);
      } else {
        window.alert("Enter edit mode first!");
      }
    },

    addDepartureWaypoints: (state, action: PayloadAction<WaypointApproach>) => {
      state.departureWaypoints = action.payload;
    },
    removeDepartureWaypoints: (state) => {
      state.departureWaypoints = undefined;
    },

    addActiveDepartureWaypoint: (state, action: PayloadAction<number>) => {
      state.activeDepartureWaypoint = action.payload;
    },
    removeActiveDepartureWaypoint: (state) => {
      state.activeDepartureWaypoint = undefined;
    },

    addEditedDepartureWaypoints: (
      state,
      action: PayloadAction<WaypointApproach>
    ) => {
      state.editedDepartureWaypoints = action.payload;
    },
    removeEditedDepartureWaypoints: (state) => {
      state.editedDepartureWaypoints = undefined;
    },

    insertDepartureWaypoint: (state, action: PayloadAction<LatLngLiteral>) => {
      const insertionIndex = state.activeDepartureWaypoint ?? 0;
      if (state.editedDepartureWaypoints) {
        const decodedPolyline = decode(state.editedDepartureWaypoints.polyline);
        decodedPolyline.polyline.splice(Math.max(insertionIndex + 1, 1), 0, [
          action.payload.lat,
          action.payload.lng,
        ]);
        state.editedDepartureWaypoints.polyline = encode(decodedPolyline);
        state.activeDepartureWaypoint = Math.max(insertionIndex + 1, 1);
      } else {
        window.alert("Enter edit mode first!");
      }
    },

    clearAllRoutes: (state) => {
      state.origin = undefined;
      state.destination = undefined;
      state.mapBoxRoute = undefined;
      state.routes = {};
      state.waypointApproach = undefined;
      state.activeApproachWaypoint = undefined;
      state.editedWaypointApproach = undefined;
      state.departureWaypoints = undefined;
      state.activeDepartureWaypoint = undefined;
      state.editedDepartureWaypoints = undefined;
      state.segmentOverlay = undefined;
    },

    addSegmentOverlay: (state, action: PayloadAction<Segment[]>) => {
      state.segmentOverlay = action.payload;
    },
    removeSegmentOverlay: (state) => {
      state.segmentOverlay = undefined;
    },
  },
});

export const {
  addOrigin,
  addDestination,
  removeOrigin,
  removeDestination,
  addSearchResult,
  removeSearchResult,
  addRoute,
  clearAllRoutes,
  addMapBoxRoute,
  removeMapBoxRoute,
  addWaypointApproach,
  removeWaypointApproach,
  addActiveApproachWaypoint,
  removeActiveApproachWaypoint,
  addEditedWaypointApproach,
  removeEditedWaypointApproach,
  insertApproachWaypoint,
  addDepartureWaypoints,
  removeDepartureWaypoints,
  addActiveDepartureWaypoint,
  removeActiveDepartureWaypoint,
  addEditedDepartureWaypoints,
  removeEditedDepartureWaypoints,
  insertDepartureWaypoint,
  addSegmentOverlay,
  removeSegmentOverlay,
} = routingReducer.actions;

export const selectOrigin = (state: RootState) => state.routing.origin;
export const selectDestination = (state: RootState) =>
  state.routing.destination;
export const selectSearchResult = (state: RootState) =>
  state.routing.searchResult;
export const selectRoute = (key: RouteKey) => (state: RootState) =>
  state.routing.routes[key];
export const selectMapBoxRoute = () => (state: RootState) =>
  state.routing.mapBoxRoute;
export const selectWaypointApproach = () => (state: RootState) =>
  state.routing.waypointApproach;
export const selectActiveApproachWaypoint = () => (state: RootState) =>
  state.routing.activeApproachWaypoint;
export const selectEditedWaypointApproach = () => (state: RootState) =>
  state.routing.editedWaypointApproach;
export const selectDepartureWaypoints = () => (state: RootState) =>
  state.routing.departureWaypoints;
export const selectActiveDepartureWaypoint = () => (state: RootState) =>
  state.routing.activeDepartureWaypoint;
export const selectEditedDepartureWaypoints = () => (state: RootState) =>
  state.routing.editedDepartureWaypoints;
export const selectSegmentOverlay = () => (state: RootState) =>
  state.routing.segmentOverlay;

export default routingReducer.reducer;
