import React from "react";
import { useHereMap } from "map/Context";
import Marker from "../Marker";

import CampgroundIcon from "assets/svg/park-marker.svg";
import { createIcon } from "map/util/createIcon";
import {
  addDestination,
  addEditedWaypointApproach,
  addOrigin,
  addWaypointApproach,
  addDepartureWaypoints,
  addEditedDepartureWaypoints,
} from "stores/routing";
import { useAppDispatch } from "stores/hooks";
import InfoBubble from "../InfoBubble";
import { Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import { MapObjectEvent } from "map/types";
import { ABSENT, DEFAULT_PRECISION, encode } from "util/flexible-polyline";

const ParkMarker: React.FC<{ park: ParkQueryResult }> = ({ park }) => {
  const dispatch = useAppDispatch();
  const { map } = useHereMap();
  const [parkInfo, setParkInfo] = React.useState<ParkResponse | null>(null);
  const onInfocardOpen = async () => {
    if (Number.isNaN(park.cg_id)) {
      return;
    }
    if (parkInfo) {
      return;
    }
    const axiosInstance = axios.create({
      baseURL:
        process.env.NODE_ENV === "development"
          ? "https://cgr-api.dev-rvlife.com/parks/"
          : "https://cgr-api.rvlife.com/parks/", // I think this endpoint isn't set up in prod yet though.
      headers: { accept: "application/x.rvparkreviews.v2+json, */*; q=0.01" },
    });
    const response = await axiosInstance.get<ParkResponse>(
      `${park.cg_id}?include=waypoint_approaches,waypoint_departures`
    );

    const json = response.data;
    setParkInfo(json);
  };
  const markerNode = (
    <InfoBubble onOpen={onInfocardOpen} maxHeight={1200} maxWidth={1200}>
      <Stack
        spacing={2}
        sx={{ maxHeight: 500, maxWidth: 800, overflow: "auto" }}
      >
        <Typography
          variant="h6"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {park.cg_name}
        </Typography>
        <Stack spacing={2} sx={{ maxWidth: 250 }}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              dispatch(addOrigin({ lat: park.lat, lng: park.lng }));
            }}
            sx={{ width: "auto" }}
          >
            Add as origin
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              dispatch(addDestination({ lat: park.lat, lng: park.lng }));
            }}
            sx={{ width: "auto" }}
          >
            Add as destination
          </Button>
        </Stack>
        {parkInfo && (
          <>
            <Typography variant="body1">Waypoint Approaches</Typography>

            <Typography variant="body2">
              {parkInfo.waypoint_approaches.map((approach) =>
                JSON.stringify(approach, null, 2)
              )}
            </Typography>
            {parkInfo && parkInfo.waypoint_approaches.length > 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  dispatch(
                    addWaypointApproach(parkInfo.waypoint_approaches[0])
                  );

                  map?.getViewModel().setLookAtData({
                    zoom: 18,
                  });
                  dispatch(addDestination({ lat: park.lat, lng: park.lng }));
                }}
              >
                Display Waypoint Approach on Map
              </Button>
            )}
            {parkInfo && parkInfo.waypoint_approaches.length === 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  const currentTime = new Date().toISOString();
                  const newWaypointApproach: WaypointApproach = {
                    created_at: currentTime,
                    updated_at: currentTime,
                    description: null,
                    park_id: park.cg_id,
                    direction: "Default",
                    id: 0, // not sure how to generate id for this
                    user_id: 0, // same here
                    polyline: encode({
                      precision: DEFAULT_PRECISION,
                      thirdDim: ABSENT,
                      thirdDimPrecision: 0,
                      polyline: [],
                    }),
                  };
                  dispatch(addWaypointApproach(newWaypointApproach));
                  dispatch(addEditedWaypointApproach(newWaypointApproach));

                  map?.getViewModel().setLookAtData({
                    zoom: 18,
                  });
                  dispatch(addDestination({ lat: park.lat, lng: park.lng }));
                }}
              >
                Add Waypoint Approach
              </Button>
            )}

            <Typography variant="body1">Departure Waypoints</Typography>

            <Typography variant="body2">
              {parkInfo.waypoint_departures.map((departure) =>
                JSON.stringify(departure, null, 2)
              )}
            </Typography>
            {parkInfo.waypoint_departures.length > 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  dispatch(
                    addDepartureWaypoints(parkInfo.waypoint_departures[0])
                  );

                  map?.getViewModel().setLookAtData({
                    zoom: 18,
                  });
                  dispatch(addOrigin({ lat: park.lat, lng: park.lng }));
                }}
              >
                Display Departure Waypoints on Map
              </Button>
            )}
            {parkInfo.waypoint_departures.length === 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  const currentTime = new Date().toISOString();
                  const newDepartureWaypoints: WaypointApproach = {
                    created_at: currentTime,
                    updated_at: currentTime,
                    description: null,
                    park_id: park.cg_id,
                    direction: "Default",
                    id: 0, // not sure how to generate id for this
                    user_id: 0, // same here
                    polyline: encode({
                      precision: DEFAULT_PRECISION,
                      thirdDim: ABSENT,
                      thirdDimPrecision: 0,
                      polyline: [],
                    }),
                  };
                  dispatch(addDepartureWaypoints(newDepartureWaypoints));
                  dispatch(addEditedDepartureWaypoints(newDepartureWaypoints));

                  map?.getViewModel().setLookAtData({
                    zoom: 18,
                  });
                  dispatch(addOrigin({ lat: park.lat, lng: park.lng }));
                }}
              >
                Add Departure Waypoints
              </Button>
            )}
          </>
        )}
      </Stack>
    </InfoBubble>
  );
  const onPointerEnter = (event: MapObjectEvent) => {
    if (map) map.getViewPort().element.style.cursor = "pointer";
  };
  const onPointerLeave = (event: MapObjectEvent) => {
    if (map) map.getViewPort().element.style.cursor = "";
  };
  return (
    <Marker
      position={{
        lat: park.lat,
        lng: park.lng,
      }}
      icon={createIcon({
        iconUrl: CampgroundIcon,
        iconSize: [30, 35],
      })}
      popup={markerNode}
      key={park.cg_id}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    />
  );
};

export const Parks: React.FC<{ parks: ParkQueryResult[] }> = ({ parks }) => {
  const { map } = useHereMap();
  if (map) {
    const zoom = map.getZoom();
    if (zoom >= 8) {
      const parkMarkers = parks.map((park) => {
        return <ParkMarker park={park} key={park.cg_id} />;
      });
      return <>{parkMarkers}</>;
    }
  }
  return <></>;
};
