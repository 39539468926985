import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "stores/store";
import { GridV2 as Grid, IAvoidArea } from "./types";
import {
  ABSENT,
  DEFAULT_PRECISION,
  Position,
  decode as decodePolyline,
  encode as encodePolyline,
} from "../util/flexible-polyline";
import axios from "axios";
import { getURL } from "util/url";
import { LatLngLiteral } from "map/types";
import _ from "lodash";
import { isPolylineBoundingBox } from "tools/AvoidArea/utils.avoidarea";

import { toast } from "react-toastify";

const initialState: {
  mapGrid: Grid[];
  visible: boolean;
  deletedVisible: boolean;
  editedAvoidArea: IAvoidArea | undefined;
  editedAvoidPolygon: IAvoidArea | undefined;
} = {
  mapGrid: [],
  visible: false,
  deletedVisible: false,
  editedAvoidArea: undefined,
  editedAvoidPolygon: undefined,
};

// base extra grid used for adding an avoid area.
const emptyGridObject: IAvoidArea = {
  id: "",
  coordinates: "BFAAAA",
  desc: "",
  height: 0,
  length: 0,
  name: "New Avoid Area",
  testCoordinates: "",
  weight: 0,
  width: 0,
  zone: "x1",
  state: "",
};

export const mapGridReducer = createSlice({
  name: "mapGrid",
  initialState,
  reducers: {
    toggleVisibility: (state) => {
      state.visible = !state.visible;
      console.log("Visibility toggled:", state.visible);
    },

    importMapGrid: (state, action: PayloadAction<Grid[]>) => {
      state.mapGrid = action.payload;
      const allAreas = state.mapGrid.map((grid) => grid.avoidAreas).flat();
      console.log("Imported map grid:", allAreas);
    },

    setNextAvoidPolygonVertex: (
      state,
      action: PayloadAction<LatLngLiteral>
    ) => {
      if (!state.editedAvoidPolygon) {
        console.log("Enter edit avoid polygon mode first.");
        return;
      }
      const encoded = state.editedAvoidPolygon.coordinates;
      const decoded = decodePolyline(encoded);
      let decodedArray: Position[] = [];
      if (decoded) {
        decodedArray = decoded.polyline;
      }
      decodedArray.push([action.payload.lat, action.payload.lng]);
      const encodedCoordinates = encodePolyline({
        precision: DEFAULT_PRECISION,
        thirdDim: ABSENT,
        thirdDimPrecision: 0,
        polyline: decodedArray,
      });
      state.editedAvoidPolygon.coordinates = encodedCoordinates;
      console.log(
        "Set next avoid polygon vertex:",
        state.editedAvoidPolygon.coordinates
      );
    },

    setTopLeftCoordinate: (state, action: PayloadAction<LatLngLiteral>) => {
      if (!state.editedAvoidArea) {
        state.editedAvoidArea = emptyGridObject;
        console.log("Created new edited avoid area.");
      }
      const encoded = state.editedAvoidArea.coordinates;
      const decoded = decodePolyline(encoded);
      const decodedArray = decoded.polyline;
      decodedArray[0][0] = action.payload.lat;
      decodedArray[1][1] = action.payload.lng;
      const encodedCoordinates = encodePolyline({
        precision: DEFAULT_PRECISION,
        thirdDim: ABSENT,
        thirdDimPrecision: 0,
        polyline: decodedArray,
      });
      state.editedAvoidArea.coordinates = encodedCoordinates;
      console.log(
        "Set top-left coordinate:",
        state.editedAvoidArea.coordinates
      );
    },

    setBottomRightCoordinate: (state, action: PayloadAction<LatLngLiteral>) => {
      if (!state.editedAvoidArea) {
        state.editedAvoidArea = emptyGridObject;
        console.log("Created new edited avoid area.");
      }
      const encoded = state.editedAvoidArea.coordinates;
      const decoded = decodePolyline(encoded);
      const decodedArray = decoded.polyline;
      decodedArray[1][0] = action.payload.lat;
      decodedArray[0][1] = action.payload.lng;
      const encodedCoordinates = encodePolyline({
        precision: DEFAULT_PRECISION,
        thirdDim: ABSENT,
        thirdDimPrecision: 0,
        polyline: decodedArray,
      });
      state.editedAvoidArea.coordinates = encodedCoordinates;
      console.log(
        "Set bottom-right coordinate:",
        state.editedAvoidArea.coordinates
      );
    },

    createEditedAvoidArea: (state) => {
      state.editedAvoidArea = emptyGridObject;
      state.visible = true;
      state.editedAvoidPolygon = undefined;
      console.log("Created new edited avoid area.");
    },

    setEditedAvoidArea: (state, action: PayloadAction<IAvoidArea>) => {
      if (isPolylineBoundingBox(action.payload.coordinates)) {
        state.editedAvoidArea = action.payload;
        state.editedAvoidPolygon = undefined;
        console.log("Set edited avoid area:", state.editedAvoidArea);
      } else {
        state.editedAvoidPolygon = action.payload;
        state.editedAvoidArea = undefined;
        console.log("Set edited avoid polygon:", state.editedAvoidPolygon);
      }
    },

    deleteAvoidPolygonVertex: (state, action: PayloadAction<number>) => {
      if (!state.editedAvoidPolygon) {
        console.log("No edited avoid polygon to delete vertex from.");
        return;
      }
      const encodedCoordinates = state.editedAvoidPolygon;
      const coordinates = decodePolyline(encodedCoordinates.coordinates);
      if (coordinates) {
        const newCoordinates = coordinates.polyline.filter(
          (_, i) => i !== action.payload
        );

        state.editedAvoidPolygon.coordinates = encodePolyline({
          polyline: newCoordinates,
        } as any);
      }
    },

    discardChanges: (state) => {
      state.editedAvoidArea = undefined;
      console.log("Discarded changes to edited avoid area.");
      console.log("Deselected active avoid area.");
      state.editedAvoidPolygon = undefined;
      console.log("Discarded changes to edited avoid polygon.");
    },

    createEditedAvoidPolygon: (state) => {
      const emptyAvoidPolygon = _.cloneDeep(emptyGridObject);
      emptyAvoidPolygon.coordinates = "";
      state.editedAvoidPolygon = emptyAvoidPolygon;
      state.editedAvoidArea = undefined;
      state.visible = true;
      console.log("Created new edited avoid polygon.");
    },
  },
});

export const {
  importMapGrid,
  toggleVisibility,
  discardChanges,
  setTopLeftCoordinate,
  setBottomRightCoordinate,
  setEditedAvoidArea,
  createEditedAvoidArea,
  createEditedAvoidPolygon,
  setNextAvoidPolygonVertex,
  deleteAvoidPolygonVertex,
} = mapGridReducer.actions;

export const isMapGridVisible = (state: RootState) => state.mapGrid.visible;

export const getDeletedVisible = (state: RootState) =>
  state.mapGrid.deletedVisible;

export const returnGrid = (state: RootState) => state.mapGrid.mapGrid;

export const getEditedAvoidArea = (state: RootState) =>
  state.mapGrid.editedAvoidArea;

export const getEditedAvoidPolygon = (state: RootState) =>
  state.mapGrid.editedAvoidPolygon;

export const fetchGrid =
  (bearerToken: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await axios.get(getURL() + "/v2/map/atlas-avoidAreas", {
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
      });
      const jsonData = await response.data;
      const decodedAvoidAreas = JSON.parse(jsonData.avoidAreas);
      console.log("Fetched grid:", decodedAvoidAreas);
      dispatch(importMapGrid(decodedAvoidAreas));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast(
          error.response?.status.toString() +
            " : " +
            error.response?.data.message
        );
      }
      console.error("Failed to fetch grid:", error);
    }
  };

export const createAvoidArea =
  (avoidArea: IAvoidArea, bearerToken: string): AppThunk =>
  async (dispatch) => {
    try {
      await axios.post(getURL() + "/v2/map/avoidAreas", avoidArea, {
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
      });
      console.log("Submitted avoid area:", avoidArea);
      dispatch(discardChanges());
      dispatch(fetchGrid(bearerToken));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast(
          error.response?.status.toString() +
            " : " +
            error.response?.data.message
        );
      }
      console.error("Failed to submit avoid area:", error);
    }
  };

export const editAvoidArea =
  (avoidArea: IAvoidArea, bearerToken: string): AppThunk =>
  async (dispatch) => {
    const {
      editedBy,
      dateEdited,
      updatedBy,
      dateUpdated,
      ...cleanedAvoidArea
    } = avoidArea as any;
    try {
      await axios.put(getURL() + "/v2/map/avoidAreas", cleanedAvoidArea, {
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
      });
      console.log("Updated avoid area:", avoidArea);
      dispatch(discardChanges());
      dispatch(fetchGrid(bearerToken));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast(
          error.response?.status.toString() +
            " : " +
            error.response?.data.message
        );
      }
      console.error("Failed to submit avoid area:", error);
    }
  };

export const deleteAvoidArea =
  (id: string, bearerToken: string): AppThunk =>
  async (dispatch) => {
    if (!id) {
      console.error("No id provided to delete avoid area.");
      dispatch(discardChanges());
      return;
    }
    try {
      await axios.delete(getURL() + `/v2/map/avoidAreas/${id}`, {
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
      });
      console.log("Deleted avoid area with id:", id);
      dispatch(discardChanges());
      dispatch(fetchGrid(bearerToken));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast(
          error.response?.status.toString() +
            " : " +
            error.response?.data.message
        );
      }
      console.error("Failed to delete avoid area:", error);
    }
  };

export const saveChanges =
  (bearerToken: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    if (state.mapGrid.editedAvoidArea && state.mapGrid.editedAvoidPolygon) {
      console.log(
        `This shouldn't happen technically but somehow editedAvoidArea and editedAvoidPolygon are both set. That's no good.`
      );
      return;
    }

    if (state.mapGrid.editedAvoidArea) {
      if (state.mapGrid.editedAvoidArea.id) {
        console.log("Editing existing avoid area.");
        dispatch(editAvoidArea(state.mapGrid.editedAvoidArea, bearerToken));
      } else {
        console.log("Creating new avoid area.");
        dispatch(createAvoidArea(state.mapGrid.editedAvoidArea, bearerToken));
      }
      console.log("Saved changes to avoid area.");
    }

    if (state.mapGrid.editedAvoidPolygon) {
      if (state.mapGrid.editedAvoidPolygon.id) {
        console.log("Editing existing avoid polygon.");
        dispatch(editAvoidArea(state.mapGrid.editedAvoidPolygon, bearerToken));
      } else {
        console.log("Creating new avoid polygon.");
        dispatch(
          createAvoidArea(state.mapGrid.editedAvoidPolygon, bearerToken)
        );
      }
      console.log("Saved changes to avoid polygon.");
    }
  };

export default mapGridReducer.reducer;
