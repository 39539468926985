import React from "react";
import { useHereMap } from "map/Context";
import { debouncedSearchCampgrounds } from "api/campgrounds";
// import { Clusters } from "./Clusters";
import { Parks } from "./Parks";
import { getCampgroundsVisibility } from "stores/ui";
import { useAppSelector } from "stores/hooks";
function roundBoundingBox(bbox: H.geo.Rect) {
  return {
    north: Number(bbox.getTop().toFixed(4)),
    south: Number(bbox.getBottom().toFixed(4)),
    east: Number(bbox.getRight().toFixed(4)),
    west: Number(bbox.getLeft().toFixed(4)),
  };
}
const Campgrounds: React.FC = () => {
  const { map } = useHereMap();

  // const [clusters, setClusters] = React.useState<ClusterData[]>([]);
  const [parks, setParks] = React.useState<ParkQueryResult[]>([]);
  const [excludeList, setExcludeList] = React.useState<number[]>([]);
  const [lastBounds, setLastBounds] = React.useState<H.geo.Rect | null>(null);

  const campgroundsVisible = useAppSelector(getCampgroundsVisibility);
  const handleSearchResults = React.useCallback(
    async (zoom: number, boundingBox: H.geo.Rect) => {
      const results = await debouncedSearchCampgrounds(
        zoom,
        boundingBox,
        excludeList
      );
      if (results) {
        const { parks: newParks } = results;

        // Update clusters
        // setClusters((prevClusters) => [...prevClusters, ...newClusters]);

        const filteredNewParks = newParks.filter((park) => park !== null);

        // Update parks with IDs to prevent refetching
        setParks((prevParks) => {
          const parksMap = new Map();

          // Add existing parks to the map
          for (const park of prevParks) {
            parksMap.set(park.cg_id, park);
          }

          // Add new parks to the map, will overwrite existing ones if cg_id matches
          for (const park of filteredNewParks) {
            parksMap.set(park.cg_id, park);
          }

          // Convert back to array
          return Array.from(parksMap.values());
        });

        // Calculate new IDs to be excluded
        const newExcludeListIds = filteredNewParks
          .map((park) => park?.cg_id)
          .filter((id): id is number => id !== undefined);

        // Use updater function to correctly add new exclude list IDs
        setExcludeList((prevExcludeList) => {
          // Merge the previous exclude list with the new IDs, avoiding duplicates
          const updatedExcludeList = new Set([
            ...prevExcludeList,
            ...newExcludeListIds,
          ]);
          return Array.from(updatedExcludeList);
        });
      }
    },
    [excludeList]
  );

  React.useEffect(() => {
    if (map) {
      const viewChangeEnd = () => {
        const bounds = map?.getViewModel().getLookAtData().bounds;

        const zoom = map?.getZoom();
        if (bounds) {
          const boundingBox = bounds.getBoundingBox();
          if (boundingBox && zoom) {
            if (lastBounds) {
              const roundedBounds = roundBoundingBox(boundingBox);
              const roundedLastBounds = roundBoundingBox(lastBounds);
              if (
                roundedBounds.north === roundedLastBounds.north &&
                roundedBounds.south === roundedLastBounds.south &&
                roundedBounds.east === roundedLastBounds.east &&
                roundedBounds.west === roundedLastBounds.west
              ) {
                return;
              }

              handleSearchResults(zoom, boundingBox);
            }
            setLastBounds(boundingBox);
          }
        }
      };
      map.addEventListener("mapviewchangeend", viewChangeEnd);
      return () => {
        map?.removeEventListener("mapviewchangeend", viewChangeEnd);
      };
    }
  }, [map, lastBounds, handleSearchResults]);

  return (
    <>
      {/* <Clusters clusters={clusters} /> */}
      {campgroundsVisible && <Parks parks={parks} />}
    </>
  );
};

export default Campgrounds;
