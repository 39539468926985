import React from "react";
import { IBusZone } from "stores/types";

export const BusZoneInfoBubbleContent: React.FC<{
  busZone: IBusZone;
  onEditButtonClick?: () => void;
}> = ({ busZone, onEditButtonClick }) => (
  <div
    style={{
      border: 0,
      fontSize: 12,
      maxWidth: 600,
      minWidth: 300,
      maxHeight: 600,
      minHeight: 300,
      overflowWrap: "break-word",
    }}
  >
    <div>Name: {busZone.zoneName}</div>
    <br />
    {onEditButtonClick && (
      <button onClick={onEditButtonClick}>Edit Bus Zone</button>
    )}
  </div>
);
