import { LatLngExpression } from "map/types";

/**
 * Create a Point from a coordinate
 */
export function createPoint(lat: number, lng: number): H.geo.Point;
export function createPoint(latlng: LatLngExpression): H.geo.Point;
export function createPoint(
  latOrLatLng: number | LatLngExpression,
  lng?: number
): H.geo.Point {
  if (typeof latOrLatLng === "number") {
    return new H.geo.Point(latOrLatLng, lng as number);
  }
  if (Array.isArray(latOrLatLng)) {
    return new H.geo.Point(latOrLatLng[0], latOrLatLng[1]);
  }
  return new H.geo.Point(latOrLatLng.lat, latOrLatLng.lng);
}
