import React from "react";
import Polyline from "map/elements/Polyline";
import { Position, decode, encode } from "util/flexible-polyline";
import Marker from "../Marker";
import { createIcon } from "map/util/createIcon";
import CircleIcon from "assets/svg/waypoint-circle.svg";
import ActiveCircleIcon from "assets/svg/active-waypoint-circle.svg";
import { useHereMap } from "map/Context";
import { LatLngLiteral } from "map/types";

const WaypointMarker: React.FC<{
  point: Position;
  index: number;
  activeWaypoint?: number;
  onClick: (index: number) => void;
}> = ({ point, index, activeWaypoint, onClick }) => {
  const { map } = useHereMap();
  const [isHovered, setIsHovered] = React.useState(false);
  const onPointerEnter = () => {
    if (map) {
      map.getViewPort().element.style.cursor = "pointer";
    }
    setIsHovered(true);
  };
  const onPointerLeave = () => {
    if (map) {
      map.getViewPort().element.style.cursor = "";
    }
    setIsHovered(false);
  };
  const isActive = activeWaypoint === index;
  return (
    <Marker
      position={point}
      icon={createIcon({
        iconUrl: isActive ? ActiveCircleIcon : CircleIcon,
        iconSize: [15, 15],
        glow: isHovered || isActive,
        active: isActive,
      })}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onTap={() => onClick(index)}
    />
  );
};

const WaypointPolyline: React.FC<{
  route?: WaypointApproach;
  editedRoute?: WaypointApproach;
  activeWaypoint?: number;
  onClick: (index: number) => void;
  color?: string;
  lineDash?: number[];
  origin?: LatLngLiteral;
  destination?: LatLngLiteral;
}> = ({
  route,
  editedRoute,
  activeWaypoint,
  onClick,
  color,
  lineDash,
  origin,
  destination,
}) => {
  if (route) {
    const decodedPolyline = decode(route.polyline);
    if (origin) {
      decodedPolyline.polyline.unshift([origin.lat, origin.lng]);
    }
    if (destination) {
      decodedPolyline.polyline.push([destination.lat, destination.lng]);
    }
    
    const polyline = (
      <Polyline
        positions={encode(decodedPolyline)}
        lineWidth={8}
        strokeColor={color ?? "rgba(255, 0, 0, 0.75)"}
        lineDash={lineDash}
      />
    );
    const markers = decodedPolyline.polyline.map((point, i) => {
      return (
        <WaypointMarker
          point={point}
          key={JSON.stringify(point)}
          index={i}
          activeWaypoint={activeWaypoint}
          onClick={onClick}
        />
      );
    });
    return (
      <>
        {polyline}
        {!editedRoute && markers}
      </>
    );
  }
  return null;
};

export default WaypointPolyline;
