import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useHereMap } from "../Context";
import { createPoint } from "../geo/util";
import { InfoBubbleProps, PanPadding } from "./InfoBubble.type";

const defaultPanPadding: PanPadding = [
  [60, 100],
  [100, -20],
];

function isSimpleArray(value: PanPadding): value is [number, number] {
  return Array.isArray(value) && typeof value[0] === "number";
}

function eventEffect(
  event: string,
  container?: HTMLElement,
  listener?: () => void
) {
  if (container && listener) {
    container.addEventListener(event, listener);

    return () => {
      container.removeEventListener(event, listener);
    };
  }
}
//@ts-ignore
const InfoBubble: React.FC<InfoBubbleProps> = ({
  anchor = "default",
  autoPanPadding = defaultPanPadding,
  children = undefined,
  className = "",
  closeButton = false,
  hideTail = false,
  maxWidth = undefined,
  moveIntoView = false,
  offset = undefined,
  onClose = undefined,
  onOpen = undefined,
  onPointerEnter = undefined,
  onPointerLeave = undefined,
  position = null,
  visible = true,
}: InfoBubbleProps) => {
  const { ui, map } = useHereMap();
  const [refs, setRefs] = useState<{
    popup: H.ui.InfoBubble;
    container: HTMLElement;
  }>();

  // The tail is automatically hidden if the anchor is anything but 'default'
  const localHideTail = hideTail ?? anchor !== "default";

  // A reference to the current position for use in the creation effect. This
  // lets the effect see the current position without having to depend on it.
  const positionRef = useRef(position);
  positionRef.current = position;

  const panPadding = isSimpleArray(autoPanPadding)
    ? {
        top: autoPanPadding[1],
        left: autoPanPadding[0],
        bottom: autoPanPadding[1],
        right: autoPanPadding[0],
      }
    : Array.isArray(autoPanPadding)
    ? {
        top: autoPanPadding[0][1],
        left: autoPanPadding[0][0],
        bottom: autoPanPadding[1][1],
        right: autoPanPadding[1][0],
      }
    : {
        top: autoPanPadding.topLeft[1],
        left: autoPanPadding.topLeft[0],
        bottom: autoPanPadding.bottomRight[1],
        right: autoPanPadding.bottomRight[0],
      };

  // Create and dispose of the popup
  useEffect(() => {
    if (!ui || !map || visible === false) {
      return;
    }

    const elem = document.createElement("div");
    const localPosition = createPoint(positionRef.current ?? map.getCenter());
    const popup = new H.ui.InfoBubble(localPosition, { content: elem });

    // Close any other open popups
    ui.getBubbles().forEach((popups) => popups.close());

    popup.addClass("map-popup");
    ui.addBubble(popup);

    // Close the popup if the user clicks somewhere on the map
    const handleTap = () => {
      popup.close();
    };
    map.addEventListener("tap", handleTap);

    // Set the container that the portal will render into. This should be
    // an element controlled by the component, _not_ an element controlled
    // by the popup (like popup.getContentElement()).
    setRefs({
      popup,
      container: elem,
    });

    return () => {
      setRefs(undefined);
      map.removeEventListener("tap", handleTap);
      ui.removeBubble(popup);
      popup.dispose();
    };
  }, [map, ui, visible]);

  useEffect(() => {
    if (!refs) {
      return;
    }
    if (closeButton === false) {
      refs.popup.addClass("map-popup-no-close");
    } else {
      refs.popup.removeClass("map-popup-no-close");
    }
  }, [refs, closeButton]);

  useEffect(() => {
    if (!refs || !className) {
      return;
    }
    refs.popup.addClass(className);
  }, [refs, className]);

  useEffect(() => {
    if (!refs || !anchor) {
      return;
    }

    if (anchor === "top-left") {
      const popupElem = refs.popup.getElement();
      if (!popupElem) {
        return;
      }

      const contentElem = refs.popup.getContentElement();
      if (!contentElem) {
        return;
      }

      popupElem.style.top = `${contentElem.offsetHeight + 12}px`;
      popupElem.style.left = `calc(-100% + ${contentElem.offsetWidth}px)`;
    }
  }, [refs, anchor]);

  useEffect(() => {
    if (!refs) {
      return;
    }

    const popupElem = refs.popup.getElement();
    if (!popupElem) {
      return;
    }

    const tail = popupElem.querySelector(".H_ib_tail");
    if (!tail) {
      return;
    }

    (tail as HTMLElement).style.display = localHideTail ? "none" : "";
  }, [refs, localHideTail]);

  // Notify listeners that the popup has opened
  useEffect(() => {
    if (!refs || !onOpen) {
      return;
    }

    onOpen();
  }, [onOpen, refs]);

  useEffect(() => {
    if (!refs || !offset) {
      return;
    }

    // Adjust the popup so it's tail doesn't completely cover the marker
    const popupElem = refs.popup.getElement();
    if (!popupElem) {
      return;
    }

    popupElem.style.top = `${offset.y}px`;
    popupElem.style.left = `calc(-100% + ${offset.x}px`;
  }, [refs, offset]);

  // Notify listeners that the popup has closed
  useEffect(() => {
    if (!refs || !map) {
      return;
    }

    if (visible) {
      refs.popup.close = () => {
        onClose?.();
      };
    } else {
      const handleStateChange = () => {
        if (refs.popup.getState() === H.ui.InfoBubble.State.CLOSED) {
          onClose?.();
        }
      };
      refs.popup.addEventListener("statechange", handleStateChange);

      return () => {
        refs.popup.removeEventListener("statechange", handleStateChange);
      };
    }
  }, [map, onClose, refs, visible]);

  useEffect(() => {
    if (!refs || !map || !position || !visible) {
      return;
    }

    const pos = createPoint(position);
    refs.popup.setPosition(pos);

    if (moveIntoView) {
      // Ensure the popup renders within the map viewport
      const timer = setTimeout(() => {
        // Check the container rect a cycle after the container is set
        // (i.e., after React has rendered content into the portal)
        const rect = refs.container.getBoundingClientRect();
        const viewport = map.getViewPort();

        const minX = viewport.padding.left + panPadding.left;
        const maxX = viewport.width - viewport.padding.right - panPadding.right;
        const minY = viewport.padding.top + panPadding.top;
        const maxY =
          viewport.height - viewport.padding.bottom - panPadding.bottom;

        let offsetX = 0;
        let offsetY = 0;
        if (rect.x < minX) {
          offsetX = minX - rect.x;
        } else if (rect.right > maxX) {
          offsetX = maxX - rect.right;
        }
        if (rect.y < minY) {
          offsetY = minY - rect.y;
        } else if (rect.bottom > maxY) {
          offsetY = maxY - rect.bottom;
        }

        if (offsetX || offsetY) {
          const center = map.geoToScreen(map.getCenter());
          if (center) {
            const newX = center.x - offsetX;
            const newY = center.y - offsetY;
            const newCenter = map.screenToGeo(newX, newY);
            if (newCenter) {
              map.setCenter(newCenter, true);
            }
          }
        }
      });

      return () => {
        clearTimeout(timer);
      };
    }
  }, [
    visible,
    moveIntoView,
    panPadding?.top,
    panPadding?.left,
    panPadding?.right,
    panPadding?.bottom,
    refs,
    map,
    position,
  ]);

  useEffect(() => {
    if (!refs || maxWidth === undefined) {
      return;
    }

    refs.container.style.maxWidth = `${maxWidth}px`;
  }, [refs, maxWidth]);

  useEffect(() => {
    return eventEffect("pointerenter", refs?.container, onPointerEnter);
  }, [refs, onPointerEnter]);

  useEffect(() => {
    return eventEffect("pointerleave", refs?.container, onPointerLeave);
  }, [refs, onPointerLeave]);
  // @ts-ignore
  return refs ? createPortal(children, refs.container) : null;
};

export default InfoBubble;
