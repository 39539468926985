import store from "stores/store";
import {
  // setOneHourTrip,
  setPoints,
  // setTwentyFourHourTrips,
} from "stores/realTime";
import {
  // oneHourStream,
  // twentyFourHourStream,
  getS3Points,
} from "util/awsStream";

// var hourTripStreamInterval: NodeJS.Timeout | undefined = undefined;
// var twentyFourHourStreamInterval: NodeJS.Timeout | undefined = undefined;
var realTimePointsInterval: NodeJS.Timeout | undefined = undefined;
var stream = false;

export const startStreamListener = () => {
  store.subscribe(listener);
};

function listener() {
  // console.log("Stream: Listening");
  if (store.getState().realTime.stream !== stream) {
    // console.log("Stream: adjusting");
    adjustStream(store.getState().realTime.stream);
    stream = store.getState().realTime.stream;
  }
}

const adjustStream = (stream: boolean) => {
  if (stream) {
    // runOneHourStream();
    // runTwentyFourHourStream();
    runRealTimePoints();
    // hourTripStreamInterval = setInterval(runOneHourStream, 30000);
    // twentyFourHourStreamInterval = setInterval(runTwentyFourHourStream, 45000);
    realTimePointsInterval = setInterval(runRealTimePoints, 5000);
  } else {
    // clearInterval(hourTripStreamInterval);
    // clearInterval(twentyFourHourStreamInterval);
    clearInterval(realTimePointsInterval);
    // hourTripStreamInterval = undefined;
    // twentyFourHourStreamInterval = undefined;
    realTimePointsInterval = undefined;
  }
};

// const runOneHourStream = async () => {
//   oneHourStream()
//     .then((response) => {
//       // Process the response
//       const rows = response?.Rows;
//       if (
//         rows &&
//         rows[0] &&
//         rows[0].Data &&
//         rows[0].Data[0] &&
//         rows[0].Data[0].ScalarValue
//       ) {
//         // console.log(rows[0].Data[0].ScalarValue);
//         store.dispatch(setOneHourTrip(parseInt(rows[0].Data[0].ScalarValue)));
//       }

//       // console.log(response);
//     })
//     .catch((error) => {
//       // Handle errors
//       console.error(error);
//     });
// };

// const runTwentyFourHourStream = async () => {
//   twentyFourHourStream()
//     .then((response) => {
//       // Process the response
//       const rows = response?.Rows;
//       if (
//         rows &&
//         rows[0] &&
//         rows[0].Data &&
//         rows[0].Data[0] &&
//         rows[0].Data[0].ScalarValue
//       ) {
//         // console.log(rows[0].Data[0].ScalarValue);
//         store.dispatch(
//           setTwentyFourHourTrips(parseInt(rows[0].Data[0].ScalarValue))
//         );
//       }

//       // console.log(response);
//     })
//     .catch((error) => {
//       // Handle errors
//       console.error(error);
//     });
// };

const runRealTimePoints = async () => {
  const res = await getS3Points();

  if (res.Body) {
    const data = await res.Body.transformToString();
    store.dispatch(setPoints(data));
  }
};
