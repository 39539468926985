// AdjustValueComponent.js
import { SidebarContainer } from "map/Sidebar/SidebarContainer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createEditedBusZone } from "stores/busZones";

export const AddBusZones: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createEditedBusZone());
  }, [dispatch]);

  return <SidebarContainer />;
};
