import { Paper, Typography, Grid } from "@mui/material";
import Graph from "./Graph";
import React, { useEffect, useState } from "react";
import {
  fetchCompletedRoutes,
  fetchHourlyRoutes,
  fetchRoutes,
  TimeWindow,
} from "util/awsStream";
import NumberCard from "./NumberCard";
import AverageGraph, { AverageDataPoint } from "./AverageGraph";
import Papa from "papaparse";

interface FormattedHourlyData {
  hour: string;
  unique_route_hash_ids: number;
}

const timeWindowPlainText = {
  [TimeWindow.ONE_HOUR]: "Hour",
  [TimeWindow.EIGHT_HOURS]: "8 Hours",
  [TimeWindow.ONE_DAY]: "Day",
  [TimeWindow.THREE_DAYS]: "3 Days",
  [TimeWindow.SEVEN_DAYS]: "Week",
};

const formatDateTime = (dateTimeStr: string): string => {
  return dateTimeStr.replace(" ", "T").substring(0, 19); // Cuts off at seconds, removing anything after
};

const Dashboard: React.FC = () => {
  const [data, setData] = useState<FormattedHourlyData[]>([]);

  const [averageData, setAverageData] = useState<AverageDataPoint[]>([]);
  const [staticTimes, setStaticTimes] = useState({
    [TimeWindow.ONE_HOUR]: 0,
    [TimeWindow.EIGHT_HOURS]: 0,
    [TimeWindow.ONE_DAY]: 0,
    [TimeWindow.THREE_DAYS]: 0,
    [TimeWindow.SEVEN_DAYS]: 0,
  });

  const [completedRoutes, setCompletedRoutes] = useState({
    [TimeWindow.ONE_HOUR]: 0,
    [TimeWindow.EIGHT_HOURS]: 0,
    [TimeWindow.ONE_DAY]: 0,
    [TimeWindow.THREE_DAYS]: 0,
    [TimeWindow.SEVEN_DAYS]: 0,
  });

  useEffect(() => {
    const fetchAverageData = async () => {
      try {
        const response = await fetch(
          "https://sk-dynamodb.s3.amazonaws.com/reviews-agg.csv"
        );
        const csvText = await response.text();
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result: { data: { Date: string; Average: string }[] }) => {
            const filteredData = result.data.filter(
              (row) => row.Date !== "Average"
            );
            const formattedData = filteredData.map((row) => ({
              date: row.Date,
              average: parseFloat(row.Average),
            }));
            setAverageData(formattedData);
          },
        });
      } catch (error) {
        console.error("Failed to fetch average data:", error);
      }
    };

    fetchAverageData();
  }, []);

  useEffect(() => {
    const getHourlyData = async () => {
      try {
        const result = await fetchHourlyRoutes();
        if (result.Rows) {
          const formattedData = result.Rows.map((row) => {
            if (row.Data) {
              return {
                hour: formatDateTime(row.Data[0].ScalarValue!),
                unique_route_hash_ids: parseInt(
                  row.Data[1].ScalarValue || "0",
                  10
                ),
              };
            }
            return undefined;
          })
            .filter((data): data is FormattedHourlyData => data !== undefined)
            .sort((a, b) => (a.hour > b.hour ? 1 : -1));

          setData(formattedData);
        }
      } catch (error) {
        console.error("Failed to fetch hourly routes data:", error);
      }
    };

    const intervalId = setInterval(() => {
      getHourlyData();
    }, 60000); // Call every minute

    // Initial invoke
    getHourlyData();

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchData = async (timeWindow: TimeWindow) => {
      const result = await fetchRoutes(timeWindow);
      if (result.Rows) {
        if (result.Rows.length > 0 && result.Rows[0].Data) {
          const routesCountValue =
            parseInt(result.Rows[0]?.Data[0]?.ScalarValue || "0") || 0;
          setStaticTimes((prev) => ({
            ...prev,
            [timeWindow]: routesCountValue,
          }));
        }
      }
    };

    const intervalId = setInterval(() => {
      for (const timeWindow of Object.values(TimeWindow)) {
        fetchData(timeWindow);
      }
    }, 60000); // Call every minute

    // Initial fetch for all time windows
    for (const timeWindow of Object.values(TimeWindow)) {
      fetchData(timeWindow);
    }

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchData = async (timeWindow: TimeWindow) => {
      const result = await fetchCompletedRoutes(timeWindow);
      if (result.Rows) {
        if (result.Rows.length > 0 && result.Rows[0].Data) {
          const routesCountValue =
            parseInt(result.Rows[0]?.Data[0]?.ScalarValue || "0") || 0;
          setCompletedRoutes((prev) => ({
            ...prev,
            [timeWindow]: routesCountValue,
          }));
        }
      }
    };

    const intervalId = setInterval(() => {
      for (const timeWindow of Object.values(TimeWindow)) {
        fetchData(timeWindow);
      }
    }, 60000); // Call every minute

    // Initial fetch for all time windows
    for (const timeWindow of Object.values(TimeWindow)) {
      fetchData(timeWindow);
    }

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        height: "100%",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#ffffff",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
        Mission Control
      </Typography>
      <Grid container spacing={3}>
        {Object.values(TimeWindow).map((timeWindow) => (
          <NumberCard
            key={timeWindow}
            name={`Trips Last ${timeWindowPlainText[timeWindow]}`}
            number={staticTimes[timeWindow]}
          />
        ))}
      </Grid>
      <Graph data={data} />

      <AverageGraph data={averageData} />
      <Grid container spacing={3}>
        {Object.values(TimeWindow).map((timeWindow) => (
          <NumberCard
            key={timeWindow}
            name={`Completed Last ${timeWindowPlainText[timeWindow]}`}
            number={completedRoutes[timeWindow]}
          />
        ))}
      </Grid>
    </Paper>
  );
};

export default Dashboard;
