import H from "@here/maps-api-for-javascript";
import { createMarker, displayOnMap } from "util/mapFunctions";
import FinishMarker from "../assets/svg/finish-marker.svg";
import StartMarker from "../assets/svg/start-marker.svg";
import LocationMarker from "../assets/svg/location-marker.svg";
import CancelMarker from "../assets/svg/cancel-marker.svg";
import CircleDot from "../assets/svg/circle-dot.svg";
import {
  RouteOutlineStyle,
  RouteArrowsStyle,
  MARKER_SIZES,
} from "./styles/MapStyles";
import {
  RouteCancelledReason,
  RouteRecalculationReason,
} from "./models/RouteStopReason";
import { Route } from "./models/Route";
import { RoutePointType } from "./models/Types";
import { calculateRoutingParams } from "util/routeDetailsFunctions";
import { fetchRoute } from "tools/route";

// Type definitions for map object array and any other props you want to pass
type MapObjectArray = H.map.Object[];
// AdditionalProps is a placeholder for other props you might want to pass

// Provide functions to create and display routes, return created objects

export const displayMinimalRoute = (
  fromLat: number,
  fromLng: number,
  destinationLat: number,
  destinationLng: number
): H.map.Group => {
  // Create and display the markers for the start and end points
  const startMarker = createMarker(
    fromLat,
    fromLng,
    StartMarker,
    undefined,
    MARKER_SIZES.width,
    MARKER_SIZES.height
  );
  const endMarker = createMarker(
    destinationLat,
    destinationLng,
    FinishMarker,
    undefined,
    MARKER_SIZES.width,
    MARKER_SIZES.height
  );

  // Example of creating a marker group and adding it to the map - customize as needed
  const markerGroup = new H.map.Group();
  markerGroup.addObjects([startMarker, endMarker]);

  // Return the created objects for further reference
  return markerGroup;
};

export const displayActualRoute = (
  data: RoutePointType[],
  route: Route,
  markerVisibility: number[] // Array of visible marker keys
): { mapObjects: MapObjectArray; bounds: H.geo.Rect | null } => {
  const { polyLine, startMarker, endMarker, bounds } = displayOnMap(data);
  const mapObjects = [polyLine, startMarker, endMarker];
  // Add route recalculations based on visibility
  data.forEach((it: any) => {
    if (
      it.routeStopReason === RouteRecalculationReason &&
      markerVisibility.includes(1)
    ) {
      // Conditionally create and add the Route Recalculation markers
      const marker = createMarker(it.lat, it.lng, LocationMarker, undefined);
      mapObjects.push(marker);
    } else if (
      it.routeStopReason === RouteCancelledReason &&
      markerVisibility.includes(2)
    ) {
      // Conditionally create and add the User Cancelled markers
      const marker = createMarker(it.lat, it.lng, CancelMarker, undefined);
      mapObjects.push(marker);
    }
  });

  // Add the wayPoints to the map based on visibility
  if (markerVisibility.includes(3)) {
    route.wayPoints.forEach((it: any) => {
      const marker = createMarker(it.lat, it.lng, CircleDot, undefined);
      mapObjects.push(marker);
    });
  }

  // Return the created objects so they can be stored in a ref for cleanup
  return { mapObjects, bounds };
};

export const displayCalculatedRoute = async (
  data: RoutePointType[],
  route: Route,
  onRenderComplete: (mapObjects: MapObjectArray) => void,
  token: string
): Promise<void> => {
  const routingParameters = calculateRoutingParams(
    data[0].lat,
    data[0].lng,
    route
  );

  if (routingParameters.transportMode === "truck") {
    routingParameters.transportMode = "rv";
  }

  const routeData = {
    avoidances: {
      toll: route.avoidTolls || false,
      dirtRoad: route.avoidUnpaved || false,
      ferry: route.avoidFerries || false,
      highway: route.avoidHighways || false,
      tunnel: route.avoidTunnels || false,
      propane: route.hasPropane || false,
    },
    rv_info: {
      height: routingParameters["vehicle[height]"] || 100,
      length: routingParameters["vehicle[length]"] || 100,
      weight: routingParameters["vehicle[grossWeight]"] || 100,
    },
    routes: [
      {
        origin: [...routingParameters.origin.split(",")],
        waypoints: [],
        destination: [...routingParameters.destination.split(",")],
      },
    ],
    metric: true,
    routing_type: routingParameters.transportMode || "car",
    user_id: "0000000",
  };

  try {
    const response = await fetchRoute(routeData, token, () => {}); // Assuming getRoute returns a Promise
    const json = response.data;

    const result = json.journeys[0];
    if (result.routes.length) {
      // Map objects that will be created based on fetched data
      const createdMapObjects: MapObjectArray = [];

      result.routes[0].sections.forEach((section: any) => {
        const linestring = H.geo.LineString.fromFlexiblePolyline(
          section.polyline
        );
        const routeOutline = new H.map.Polyline(linestring, {
          style: RouteOutlineStyle,
          data: undefined,
        });
        const routeArrows = new H.map.Polyline(linestring, {
          style: RouteArrowsStyle,
          data: undefined,
        });
        const routeLine = new H.map.Group();
        routeLine.addObjects([routeOutline, routeArrows]);

        createdMapObjects.push(routeLine);
      });

      // When rendering is complete, call onRenderComplete with the created objects
      onRenderComplete(createdMapObjects);
    }
  } catch (error) {
    console.error(error);
  }
};
