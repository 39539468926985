import React from "react";
import {
  addActiveDepartureWaypoint,
  addEditedDepartureWaypoints,
  selectActiveDepartureWaypoint,
  selectEditedDepartureWaypoints,
  selectOrigin,
} from "stores/routing";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import Polyline from "map/elements/Polyline";
import { Position, decode, encode } from "util/flexible-polyline";
import Marker from "../../Marker";
import { createIcon } from "map/util/createIcon";
import CircleIcon from "assets/svg/waypoint-circle.svg";
import ActiveCircleIcon from "assets/svg/active-waypoint-circle.svg";
import { MapObjectEvent } from "map/types";
import { useHereMap } from "map/Context";

let offsetX = 0; // Calculate or set this based on your observations
let offsetY = -50; // Example adjustment based on your description

const DepartureMarker: React.FC<{
  point: Position;
  index: number;
  updateWaypointPosition: (index: number, newPosition: Position) => void;
}> = ({ point, index, updateWaypointPosition }) => {
  const { map, behavior } = useHereMap();
  const dispatch = useAppDispatch();
  const activeDepartureWaypoint = useAppSelector(
    selectActiveDepartureWaypoint()
  );
  const [isHovered, setIsHovered] = React.useState(false);
  const [isDragging, setIsDragging] = React.useState(false); // Track dragging state
  const [currentPosition, setCurrentPosition] = React.useState(point);

  const onPointerEnter = () => {
    if (map) {
      map.getViewPort().element.style.cursor = "pointer";
    }
    setIsHovered(true);
  };

  const onPointerLeave = () => {
    if (map) {
      map.getViewPort().element.style.cursor = "";
    }
    setIsHovered(false);
  };

  const onPointerMove = React.useCallback(
    (event: PointerEvent) => {
      if (!isDragging || !map) return;
      // Convert screen coordinates to geo coordinates
      const pointerPosition = map.screenToGeo(
        event.clientX + offsetX,
        event.clientY + offsetY
      );
      if (!pointerPosition) return;
      setCurrentPosition([pointerPosition.lat, pointerPosition.lng]);
    },
    [isDragging, map]
  );

  const onClick = () => {
    dispatch(addActiveDepartureWaypoint(index));
  };

  const onPointerDown = (event: MapObjectEvent) => {
    setIsDragging(true); // Start dragging

    behavior && behavior.disable(H.mapevents.Behavior.Feature.PANNING);

    event.stopPropagation();
  };

  // Handle pointer up globally to capture event even if it finishes outside the marker
  React.useEffect(() => {
    const handlePointerUp = (event: PointerEvent) => {
      if (!isDragging) return;
      if (!map) return;
      behavior && behavior.enable(H.mapevents.Behavior.Feature.PANNING);
      const pointerPosition = map.screenToGeo(
        event.clientX + offsetX,
        event.clientY + offsetY
      );
      if (!pointerPosition) return;
      const newPosition: Position = [pointerPosition.lat, pointerPosition.lng];

      // Update the position in the global state/store
      updateWaypointPosition(index, newPosition);
      dispatch(addActiveDepartureWaypoint(index));
      setIsDragging(false); // Reset dragging state
    };

    if (isDragging) {
      window.addEventListener("pointermove", onPointerMove);
      window.addEventListener("pointerup", handlePointerUp); // Assuming handlePointerUp is defined
    } else {
      window.removeEventListener("pointermove", onPointerMove);
      window.removeEventListener("pointerup", handlePointerUp);
    }

    return () => {
      window.removeEventListener("pointermove", onPointerMove);
      window.removeEventListener("pointerup", handlePointerUp);
    };
  }, [
    isDragging,
    map,
    index,
    updateWaypointPosition,
    behavior,
    dispatch,
    onPointerMove,
  ]);

  const isActive = activeDepartureWaypoint === index;

  return (
    <Marker
      position={currentPosition}
      icon={createIcon({
        iconUrl: isActive ? ActiveCircleIcon : CircleIcon,
        iconSize: [15, 15],
        glow: isHovered || isActive,
        active: isActive,
      })}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onTap={onClick}
      onPointerDown={onPointerDown}
    />
  );
};

const EditableDepartureWaypoints: React.FC = () => {
  const route = useAppSelector(selectEditedDepartureWaypoints());
  const origin = useAppSelector(selectOrigin);

  const dispatch = useAppDispatch();

  const updateWaypointPosition = (index: number, newPosition: Position) => {
    if (!route) return;

    const decodedPolyline = decode(route.polyline);
    decodedPolyline.polyline[index] = newPosition; // Update the position

    const updatedPolyline = encode({
      // Make sure to correctly apply arguments for encode method
      polyline: decodedPolyline.polyline,
      precision: 5, // Example precision, adjust accordingly,
      thirdDim: 0,
      thirdDimPrecision: 0,
    });

    // Dispatch the updated polyline to your store
    // Assuming a hypothetical action creator "updateRoutePolyline"
    dispatch(
      addEditedDepartureWaypoints({ ...route, polyline: updatedPolyline })
    );
  };

  if (route) {
    const decodedPolyline = decode(route.polyline);

    if (origin) decodedPolyline.polyline.unshift([origin.lat, origin.lng]);
    const polyline = (
      <Polyline
        positions={route.polyline}
        lineWidth={12}
        strokeColor="rgba(128, 0, 128, 0.75)"
        lineDash={[3, 3]}
      />
    );
    const departureMarkers = decodedPolyline.polyline.map((point, index) => {
      return (
        <DepartureMarker
          point={point}
          key={JSON.stringify(point)}
          updateWaypointPosition={updateWaypointPosition}
          index={index}
        />
      );
    });
    return (
      <>
        {polyline}
        {departureMarkers}
      </>
    );
  }
  return null;
};

export default EditableDepartureWaypoints;
