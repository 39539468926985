import React, { useState, useCallback, useEffect } from "react";
import { useHereMap } from "map/Context";
import { LatLngLiteral } from "map/types";
import IncidentMarker from "./IncidentMarker";
import { getIncidentsVisibility } from "stores/ui";
import { useAppSelector } from "stores/hooks";

interface Incident {
  location: { shape: { links: { points: LatLngLiteral[] }[] } };
  incidentDetails: any;
}

const MIN_ZOOM_LEVEL = 10;

const Incidents: React.FC = () => {
  const { platform, map } = useHereMap();
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const [lastBounds, setLastBounds] = useState<H.geo.Rect | null>(null);

  const incidentsVisible = useAppSelector(getIncidentsVisibility);
  const fetchIncidents = useCallback(
    async (zoom: number, boundingBox: H.geo.Rect) => {
      if (zoom < MIN_ZOOM_LEVEL) {
        setIncidents([]);
        return;
      }

      if (platform && map) {
        const trafficService = platform.getTrafficService({}, 7);
        if (trafficService instanceof H.service.traffic.Service7) {
          trafficService.requestIncidentsByArea(
            {
              in: `bbox:${boundingBox.getLeft()},${boundingBox.getBottom()},${boundingBox.getRight()},${boundingBox.getTop()}`,
              locationReferencing: "shape",
            },
            (result: any) => {
              if (result.results) {
                setIncidents(result.results);
              }
            },
            (error) => {
              console.error("Traffic Incidents Request Error:", error);
            }
          );
        }
      }
    },
    [platform, map]
  );

  useEffect(() => {
    if (map) {
      const viewChangeEnd = () => {
        const bounds = map.getViewModel().getLookAtData().bounds;
        const zoom = map.getZoom();
        if (bounds) {
          const boundingBox = bounds.getBoundingBox();
          if (boundingBox && zoom) {
            if (lastBounds) {
              // Check if the lat/lng difference exceeds 1 degree

              if (
                Math.abs(boundingBox.getTop() - boundingBox.getBottom()) > 1 ||
                Math.abs(boundingBox.getRight() - boundingBox.getLeft()) > 1
              ) {
                return;
              }
              if (
                boundingBox.getTop() === lastBounds.getTop() &&
                boundingBox.getBottom() === lastBounds.getBottom() &&
                boundingBox.getLeft() === lastBounds.getLeft() &&
                boundingBox.getRight() === lastBounds.getRight()
              ) {
                return;
              }
            }
            fetchIncidents(zoom, boundingBox);
            setLastBounds(boundingBox);
          }
        }
      };
      map.addEventListener("mapviewchangeend", viewChangeEnd);
      return () => {
        map.removeEventListener("mapviewchangeend", viewChangeEnd);
      };
    }
  }, [map, lastBounds, fetchIncidents]);

  return (
    <>
      {incidentsVisible && incidents.map((incident) => (
        <IncidentMarker key={incident.incidentDetails.id} incident={incident} />
      ))}
    </>
  );
};

export default Incidents;
