import { Button, Stack, TextField } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "stores/AuthContext";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import {
  getEditedBusZone,
  discardChanges,
  saveChanges,
  deleteBusZone,
  setEditedBusZone,
} from "stores/busZones";
import ConfirmationPopup from "support/components/Confirmation";
import {
  decodeBboxFromPolyline,
  formatPoint,
} from "../AvoidArea/utils.avoidarea";
import { IBusZone } from "stores/types";

interface IBusZoneFormValues {
  zoneName: string;
}

const BusZoneForm: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = React.useState(false);

  const editedBusZone = useAppSelector(getEditedBusZone);

  const { keycloak } = React.useContext(AuthContext);

  const defaultValues: IBusZoneFormValues = {
    zoneName: "New Bus Routing Zone",
  };

  if (editedBusZone) {
    (Object.keys(defaultValues) as Array<keyof IBusZoneFormValues>).forEach(
      (key) => {
        if (key in editedBusZone) {
          const value = editedBusZone[key as keyof IBusZone];
          (defaultValues[key] as typeof value) = value;
        }
      }
    );
  }

  const { handleSubmit, control, reset } = useForm({ defaultValues });

  React.useEffect(() => {
    if (editedBusZone) {
      (Object.keys(defaultValues) as Array<keyof IBusZoneFormValues>).forEach(
        (key) => {
          if (key in editedBusZone) {
            const value = editedBusZone[key as keyof IBusZone];
            (defaultValues[key] as typeof value) = value;
          }
        }
      );
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedBusZone]);

  if (!editedBusZone) {
    return;
  }

  const { topLeft, bottomRight } = decodeBboxFromPolyline(
    editedBusZone.polyline
  );

  const onSubmit = async (formData: IBusZoneFormValues) => {
    const newBusZone = { ...editedBusZone };

    newBusZone.zoneName = formData.zoneName;

    dispatch(setEditedBusZone(newBusZone));

    if (keycloak?.token) dispatch(saveChanges(keycloak.token));
  };

  const discardGridChanges = () => {
    dispatch(discardChanges());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2}>
        <TextField
          label="Top Left Coordinate"
          disabled
          value={formatPoint(topLeft)}
        />
        <TextField
          label="Bottom Right Coordinate"
          disabled
          value={formatPoint(bottomRight)}
        />
        <Controller
          name="zoneName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField label="Name" error={!!error} {...field} />
          )}
          rules={{ required: true }}
        />

        <React.Fragment>
          <Button variant="outlined" onClick={discardGridChanges}>
            Discard Changes
          </Button>
        </React.Fragment>

        <Button variant="outlined" type="submit">
          Save Changes
        </Button>
        <Button variant="outlined" onClick={() => setIsDeleting(true)}>
          Delete
        </Button>
        <ConfirmationPopup
          isOpen={isDeleting}
          onDelete={() => {
            if (keycloak?.token)
              dispatch(deleteBusZone(editedBusZone.id, keycloak?.token));
          }}
          onCancel={() => {
            setIsDeleting(false);
          }}
        />
      </Stack>
    </form>
  );
};

export default BusZoneForm;
