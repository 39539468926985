import "./App.css";
import Main from "./map/Main";
import CssBaseline from "@mui/material/CssBaseline";
import store from "./stores/store";
import { Provider } from "react-redux";
import AuthProvider from "stores/AuthProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AuthProvider>
        <Provider store={store}>
          <CssBaseline />
          <Main />
        </Provider>
      </AuthProvider>
    </LocalizationProvider>
  );
}

export default App;
