import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';

const AnimatedNumber: React.FC<{targetValue: number}> = ({ targetValue }) => {
  var startNumber = useRef(0)
  var endNumber = useRef(0)
  const [startValue, setStartValue] = useState(0);
  const [endValue, setEndValue] = useState(0);

  useEffect(() => {
    startNumber.current = endNumber.current
    endNumber.current = targetValue
    setStartValue(startNumber.current);
    setEndValue(endNumber.current)
  }, [targetValue]);

  return (
    <CountUp
      start={startValue}
      end={endValue}
      duration={2.5}
      separator=","
      decimal="."
    />
  );
};

export default AnimatedNumber;