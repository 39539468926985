import { createRoot } from "react-dom/client";
import { useHereMap } from "map/Context";
import {
  Position,
  decode as decodePolyline,
  encode as encodePolyline,
} from "util/flexible-polyline";
import React from "react";
import { useAppDispatch } from "stores/hooks";
import { addDestination, addOrigin } from "stores/routing";
import { setEditedAvoidArea } from "stores/mapGrid";
import { IAvoidArea } from "stores/types";
import { AvoidAreaInfoBubbleContent } from "./AvoidAreaInfoBubble";
import _ from "lodash";
import { LatLngLiteral } from "map/types";
import { createIcon } from "map/util/createIcon";
import CircleIcon from "assets/svg/waypoint-circle.svg";

let linkDataInfoBubble: H.ui.InfoBubble;

const AvoidAreaPolygon: React.FC<{
  avoidArea: IAvoidArea;
  editable?: boolean;
}> = ({ avoidArea, editable = false }) => {
  const dispatch = useAppDispatch();
  const { ui, map, behavior } = useHereMap();

  const shouldRenderPolygon =
    avoidArea.coordinates &&
    decodePolyline(avoidArea.coordinates).polyline.length > 2;

  React.useEffect(() => {
    const { vertexGroup, polygonGroup } = createEditablePolygon();
    polygonGroup.addEventListener("tap", createTapLinkHandler(polygonGroup));
    map?.addObject(vertexGroup);
    map?.addObject(polygonGroup);

    return () => {
      map?.removeObject(vertexGroup);
      map?.removeObject(polygonGroup);
      linkDataInfoBubble?.close();
    };
  });

  const createEditablePolygon = () => {
    const lineString = avoidArea.coordinates
      ? H.geo.LineString.fromFlexiblePolyline(avoidArea.coordinates)
      : new H.geo.LineString();

    const polygon = new H.map.Polygon(lineString, {
      data: { id: avoidArea.id },
      style: { fillColor: "rgba(100, 100, 100, 0.5)", lineWidth: 0 },
    });

    const vertexGroup = new H.map.Group();
    const polygonGroup = new H.map.Group();
    map?.addObject(vertexGroup);
    if (shouldRenderPolygon) polygonGroup?.addObject(polygon);

    if (editable) {
      const vertices = lineString
        .getLatLngAltArray()
        .reduce((acc: LatLngLiteral[], value, index, array) => {
          if (index % 3 === 0) {
            acc.push({ lat: value, lng: array[index + 1] });
          }
          return acc;
        }, []);

      vertices.forEach((vertex, index) => {
        const marker = new H.map.Marker(vertex, {
          data: { id: avoidArea.id + "-vertex-" + index, type: "vertex" },
          volatility: true,
          icon: createIcon({
            iconUrl: CircleIcon,
            iconSize: index + 1 === vertices.length ? [20, 20] : [15, 15],
          }),
        });

        marker.draggable = true;
        marker.setData(vertex);
        vertexGroup.addObject(marker);

        // If you drag a vertex when there are only two, it turns into a bounding box, due to the way the coordinates are encoded and used in the setEditedAvoidArea action that targets either editedAVoidArea or editedAvoidPolygon based on the number of coordinates.
        if (shouldRenderPolygon) {
          marker.addEventListener(
            "drag",
            (evt: {
              currentPointer: { viewportX: number; viewportY: number };
            }) => {
              const geoPos = map?.screenToGeo(
                evt.currentPointer.viewportX,
                evt.currentPointer.viewportY
              );
              if (geoPos) marker.setGeometry(geoPos);
              updatePolygonGeometry();
            }
          );

          marker.addEventListener("dragend", () => {
            behavior?.enable();
            savePolygonGeometry();
          });

          marker.addEventListener("dragstart", () => {
            behavior?.disable();
          });
        }
      });

      updatePolygonGeometry();

      function updatePolygonGeometry() {
        const newLineString = new H.geo.LineString();
        vertexGroup.getObjects().forEach((object) => {
          if (object instanceof H.map.Marker) {
            const geoPos = object.getGeometry();
            if (geoPos instanceof H.geo.Point) {
              newLineString.pushPoint({ lat: geoPos.lat, lng: geoPos.lng });
            } else {
              console.log(
                "Error: object.getGeometry() is not an instance of H.geo.Point"
              );
            }
          } else {
            console.log(object.getData());
            console.log("Error: object is not an instance of H.map.Marker");
          }
        });
        const firstVertex = vertexGroup.getObjects()[0];
        if (firstVertex instanceof H.map.Marker) {
          const geoPos = firstVertex.getGeometry();
          if (geoPos instanceof H.geo.Point)
            newLineString.pushPoint({ lat: geoPos.lat, lng: geoPos.lng }); // Close the polygon
        }
        polygon.setGeometry(new H.geo.Polygon(newLineString));
      }

      function savePolygonGeometry() {
        const newCoordinates = vertexGroup.getObjects().map((marker) => {
          if (marker instanceof H.map.Marker) {
            const geoPos = marker.getGeometry();
            if (geoPos instanceof H.geo.Point)
              return [geoPos.lat, geoPos.lng] as Position;
          }
          return null;
        });

        const newAvoidArea = _.cloneDeep(avoidArea);
        newAvoidArea.coordinates = encodePolyline({
          polyline: newCoordinates,
        } as any);

        dispatch(setEditedAvoidArea(newAvoidArea));
      }
    }

    return { vertexGroup, polygonGroup };
  };

  const createTapLinkHandler = (polygon: H.map.Group) => {
    return () => {
      const bounds = polygon.getBoundingBox();
      const center = bounds.getCenter();

      const avoidance = _.cloneDeep(avoidArea);
      let coordinates: Position[];

      if (avoidance.testCoordinates) {
        coordinates = decodePolyline(avoidance.testCoordinates)?.polyline;
      }
      if (!linkDataInfoBubble) {
        linkDataInfoBubble = new H.ui.InfoBubble(center);
        ui?.addBubble(linkDataInfoBubble);
      }
      const onEditButtonClick = () => {
        dispatch(setEditedAvoidArea(avoidance));
        linkDataInfoBubble.close();
      };

      const onTestCoordinatesButtonClick = () => {
        if (coordinates) {
          dispatch(
            addOrigin({ lat: coordinates[0][0], lng: coordinates[0][1] })
          );
          dispatch(
            addDestination({ lat: coordinates[1][0], lng: coordinates[1][1] })
          );
          linkDataInfoBubble.close();
        }
      };

      const renderInfoBubbleContent = () => {
        const container = document.createElement("div");
        const root = createRoot(container);
        root.render(
          <AvoidAreaInfoBubbleContent
            avoidance={avoidance}
            coordinates={coordinates}
            onEditButtonClick={editable ? undefined : onEditButtonClick}
            onTestCoordinatesButtonClick={onTestCoordinatesButtonClick}
          />
        );
        return container;
      };

      const contentNode = renderInfoBubbleContent();

      // Adding Link data to an InfoBubble with text area formatting

      linkDataInfoBubble.setPosition(center);
      linkDataInfoBubble.setContent(contentNode);

      linkDataInfoBubble.open();
    };
  };

  return null;
};

export default AvoidAreaPolygon;
