import React from "react";
import {
  addOrigin,
  addDestination,
  insertApproachWaypoint,
  insertDepartureWaypoint,
} from "stores/routing";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import { useHereMap } from "map/Context";
import {
  getEditedAvoidArea,
  getEditedAvoidPolygon,
  setBottomRightCoordinate,
  setNextAvoidPolygonVertex,
  setTopLeftCoordinate,
} from "stores/mapGrid";
import {
  getEditedBusZone,
  getEditedBusPolygon,
  setBottomRightCoordinate as setBusBottomRightCoordinate,
  setNextBusPolygonVertex,
  setTopLeftCoordinate as setBusTopLeftCoordinate,
} from "stores/busZones";

const ContextMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const { map, platform } = useHereMap();
  const editedAvoidArea = useAppSelector(getEditedAvoidArea);
  const editedAvoidPolygon = useAppSelector(getEditedAvoidPolygon);
  const editedBusZone = useAppSelector(getEditedBusZone);
  const editedBusPolygon = useAppSelector(getEditedBusPolygon);
  React.useEffect(() => {
    if (map) {
      const contextMenuHandler = (event: H.mapevents.ContextMenuEvent) => {
        const coord = map.screenToGeo(event.viewportX, event.viewportY);

        if (coord) {
          const cleanedCoord = { lat: coord.lat, lng: coord.lng };
          if (editedAvoidArea) {
            event.items.push(
              new H.util.ContextItem({
                label: "Add Top Left Coordinate",
                callback: () => {
                  dispatch(setTopLeftCoordinate(cleanedCoord));
                },
              })
            );
            event.items.push(
              new H.util.ContextItem({
                label: "Add Bottom Right Coordinate",
                callback: () => {
                  dispatch(setBottomRightCoordinate(cleanedCoord));
                },
              })
            );
          }
          if (editedAvoidPolygon) {
            event.items.push(
              new H.util.ContextItem({
                label: "Add Vertex to Avoid Polygon",
                callback: () => {
                  dispatch(setNextAvoidPolygonVertex(cleanedCoord));
                },
              })
            );
          }

          if (editedBusZone) {
            event.items.push(
              new H.util.ContextItem({
                label: "Add Top Left Coordinate",
                callback: () => {
                  dispatch(setBusTopLeftCoordinate(cleanedCoord));
                },
              })
            );
            event.items.push(
              new H.util.ContextItem({
                label: "Add Bottom Right Coordinate",
                callback: () => {
                  dispatch(setBusBottomRightCoordinate(cleanedCoord));
                },
              })
            );
          }
          if (editedBusPolygon) {
            event.items.push(
              new H.util.ContextItem({
                label: "Add Vertex to Avoid Polygon",
                callback: () => {
                  dispatch(setNextBusPolygonVertex(cleanedCoord));
                },
              })
            );
          }

          event.items.push(
            new H.util.ContextItem({
              label: `${coord.lat.toFixed(5)},${coord.lng.toFixed(5)}`,
              callback: () => {
                const decimalCoords = `${coord.lat.toFixed(
                  5
                )},${coord.lng.toFixed(5)}`;
                navigator.clipboard.writeText(decimalCoords);
              },
            }),
            new H.util.ContextItem({
              label: `Google Street View`,
              callback: () => {
                window.open(
                  `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${coord.lat.toFixed(
                    5
                  )},${coord.lng.toFixed(5)}`
                );
              },
            }),
            new H.util.ContextItem({
              label: `Here Map Creator View`,
              callback: () => {
                window.open(
                  `https://mapcreator.here.com/?l=${coord.lat.toFixed(
                    5
                  )},${coord.lng.toFixed(5)},16,normal`
                );
              },
            }),
            H.util.ContextItem.SEPARATOR,
            new H.util.ContextItem({
              label: "Center map here",
              callback: function () {
                map.getViewModel().setLookAtData({
                  zoom: 8,
                  position: coord,
                });
              },
            }),
            H.util.ContextItem.SEPARATOR,
            new H.util.ContextItem({
              label: "Set as route origin",
              callback: () => {
                dispatch(addOrigin({ lat: coord.lat, lng: coord.lng }));
              },
            }),
            new H.util.ContextItem({
              label: "Set as route destination",
              callback: () => {
                dispatch(addDestination({ lat: coord.lat, lng: coord.lng }));
              },
            }),
            new H.util.ContextItem({
              label: "Insert approach waypoint",
              callback: () => {
                dispatch(
                  insertApproachWaypoint({ lat: coord.lat, lng: coord.lng })
                );
              },
            }),
            new H.util.ContextItem({
              label: "Insert departure waypoint",
              callback: () => {
                dispatch(
                  insertDepartureWaypoint({ lat: coord.lat, lng: coord.lng })
                );
              },
            }),
            H.util.ContextItem.SEPARATOR
          );
        }
      };
      map.addEventListener("contextmenu", contextMenuHandler);
      return () => {
        map.removeEventListener("contextmenu", contextMenuHandler);
      };
    }
  }, [
    map,
    platform,
    dispatch,
    editedAvoidArea,
    editedAvoidPolygon,
    editedBusPolygon,
    editedBusZone,
  ]);
  return null;
};

export default ContextMenu;
