import React from "react";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import { AuthContext } from "stores/AuthContext";
import {
  fetchBusZones,
  getDeletedVisible,
  getEditedBusZone,
  getEditedBusPolygon,
  isBusZonesVisible,
  returnZones,
} from "stores/busZones";
import BusZoneRect from "./BusZoneRect";
import BusZonePolygon from "./BusZonePolygon";
import { isPolylineBoundingBox } from "../../AvoidArea/utils.avoidarea";

const BusZones: React.FC = () => {
  const dispatch = useAppDispatch();
  const busZones = useAppSelector(returnZones);
  const busZonesVisible = useAppSelector(isBusZonesVisible);
  const deletedVisible = useAppSelector(getDeletedVisible);
  const editedBusZone = useAppSelector(getEditedBusZone);
  const editedBusPolygon = useAppSelector(getEditedBusPolygon);
  const { keycloak } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (busZonesVisible && busZones.length === 0) {
      dispatch(fetchBusZones(keycloak?.token ?? ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busZonesVisible, busZones]);

  const busZoneElements: JSX.Element[] = [];

  if (editedBusZone) {
    busZoneElements.push(
      <BusZoneRect
        key={editedBusZone.id ?? "edited"}
        busZone={editedBusZone}
        editable
      />
    );
  }

  if (editedBusPolygon) {
    busZoneElements.push(
      <BusZonePolygon key="polygon" busZone={editedBusPolygon} editable />
    );
  }

  if (busZonesVisible) {
    const filteredBusZones = busZones
      .map((busZone) => {
        if (busZone.id === editedBusZone?.id) {
          return null;
        }
        if (busZone.id === editedBusPolygon?.id) {
          return null;
        }
        if (busZone.dateDeleted && !deletedVisible) {
          return null;
        }
        if (isPolylineBoundingBox(busZone.polyline)) {
          return <BusZoneRect key={busZone.id} busZone={busZone} />;
        }
        return <BusZonePolygon key={busZone.id} busZone={busZone} />;
      })

      .flat()
      .filter((busZone) => busZone !== null) as JSX.Element[];
    busZoneElements.push(...filteredBusZones);
  }

  return <>{busZoneElements}</>;
};

export default BusZones;
