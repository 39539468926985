import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "stores/store";
import { RouteKey } from "./types";

const initialState: {
  visibleRoutes: {
    [key in RouteKey]?: boolean;
  };
  campgroundsVisible: boolean;
  incidentsVisible: boolean;
} = {
  visibleRoutes: {
    car0: true,
    truck0: true,
    bus0: true,
    mapBox: true,
  },
  campgroundsVisible: true,
  incidentsVisible: true,
};

export const uiReducer = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleRoute: (state, action: PayloadAction<RouteKey>) => {
      state.visibleRoutes[action.payload] =
        !state.visibleRoutes[action.payload];
    },
    toggleCampgroundVisibility: (state) => {
      state.campgroundsVisible = !state.campgroundsVisible;
    },
    toggleIncidentVisibility: (state) => {
      state.incidentsVisible = !state.incidentsVisible;
    },
  },
});

export const {
  toggleRoute,
  toggleCampgroundVisibility,
  toggleIncidentVisibility,
} = uiReducer.actions;

export const selectVisibleRoutes = (key: RouteKey) => (state: RootState) =>
  state.ui.visibleRoutes[key];
export const getCampgroundsVisibility =  (state: RootState) =>
  state.ui.campgroundsVisible;
export const getIncidentsVisibility = (state: RootState) =>
  state.ui.incidentsVisible;

export default uiReducer.reducer;
