import {
  DynamoDBClient,
  PutItemCommand,
  PutItemCommandInput,
  QueryCommand,
  QueryCommandInput,
  ScanCommand,
  ScanCommandInput,
  UpdateItemCommand,
} from "@aws-sdk/client-dynamodb";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { Route } from "support/models/Route";
import { AwsRecord, RoutePointType } from "support/models/Types";
import { marshall } from "@aws-sdk/util-dynamodb";

const region = "us-east-1";
const client = new DynamoDBClient({
  region,
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: region },
    identityPoolId: "us-east-1:1eb44a49-d3c2-453d-8d62-dda23be50d18",
  }),
});

function awsPointsToRoutePoint(res: AwsRecord[]): RoutePointType[] {
  return res
    .map((it) => ({
      created_at: it.created_at?.S ?? "",
      lat: parseFloat(it.lat?.N ?? "0"),
      lng: parseFloat(it.long?.N ?? "0"),
      routeStopReason: it.route_stop_reason?.S ?? "",
      pointNumber: parseInt(it.point_number?.N ?? "0", 10),
    }))
    .sort((a, b) => a.pointNumber - b.pointNumber);
}

export const putData = async (tableName: string, data: any) => {
  const params: PutItemCommandInput = {
    TableName: tableName,
    Item: data,
  };
  await client.send(new PutItemCommand(params));
};

export const queryUserRouteHash = async (
  key: string
): Promise<AwsRecord[] | undefined> => {
  const params: QueryCommandInput = {
    TableName: "rvlife_mobileapp_route_list",
    IndexName: "route_hash_id-index",
    ExpressionAttributeValues: {
      ":value": { S: key },
    },
    KeyConditionExpression: "route_hash_id = :value",
  };

  const data = await client.send(new QueryCommand(params));
  return data.Items;
};

export const queryUserRoutes = async (
  key: string
): Promise<AwsRecord[] | undefined> => {
  const params: QueryCommandInput = {
    TableName: "rvlife_mobileapp_route_list",
    ExpressionAttributeValues: {
      ":value": { S: key },
    },
    KeyConditionExpression: "pm_user_id = :value",
  };
  const data = await client.send(new QueryCommand(params));
  return data.Items;
};

export const queryUserRoutesWithCommentsAndRating = async (
  ratingValue: number // This should be a single value: 1, 2, 3, 4, or 5
): Promise<AwsRecord[] | undefined> => {
  const params: QueryCommandInput = {
    TableName: "rvlife_mobileapp_route_list",
    IndexName: "rating-index",
    ExpressionAttributeValues: {
      ":ratingValue": { N: ratingValue.toString() },
      ":emptyString": { S: "" },
    },
    ExpressionAttributeNames: {
      "#comment": "comment", // Ensure this is the correct field name for comments
      "#rating": "rating", // Replace with actual field name for the rating if different
    },
    KeyConditionExpression: "#rating = :ratingValue",
    FilterExpression: "attribute_exists(#comment) AND #comment <> :emptyString",
  };

  const data = await client.send(new QueryCommand(params));
  return data.Items;
};

export const queryRoutePoints = async (
  routeHash: string
): Promise<AwsRecord[] | undefined> => {
  const params: QueryCommandInput = {
    TableName: "rvlife_mobileapp_route_points",
    ExpressionAttributeValues: {
      ":value": { S: routeHash },
    },
    KeyConditionExpression: "route_hash_id = :value",
  };
  const data = await client.send(new QueryCommand(params));
  return data.Items;
};

export async function getRoutePoints(
  routeHash: string
): Promise<RoutePointType[]> {
  const returnRoutePoints = awsPointsToRoutePoint(
    (await queryRoutePoints(routeHash)) ?? []
  );
  return returnRoutePoints.slice(0);
}

export const updateRouteFixedStatus = async (
  pmUserId: string,
  routeHash: string,
  isFixed: boolean
) => {
  const params = {
    TableName: "rvlife_mobileapp_route_list",
    Key: marshall(
      { pm_user_id: pmUserId, route_hash_id: routeHash },
      { removeUndefinedValues: true }
    ),
    UpdateExpression: "SET is_route_fixed = :isFixed",
    ExpressionAttributeValues: marshall({
      ":isFixed": isFixed ? "YES" : "",
    }),
  };

  try {
    await client.send(new UpdateItemCommand(params));
    console.log(`Route fixed status updated successfully for ${routeHash}`);
  } catch (error) {
    console.error(
      `Error updating route fixed status for ${routeHash}: `,
      error
    );
  }
};

export function awsResponseToRouteItemList(res: AwsRecord[]): Route[] {
  return res.map((it) => ({
    id: it.route_hash_id?.S ?? "",
    name: it.created_at?.S ?? "",
    commentCreatedAt: it.comment_created_at?.S ?? "",
    destinationName: it.destination_name?.S ?? "",
    cgrUserId: it.cgr_user_id?.S ?? "",
    pmUserId: it.pm_user_id?.S ?? "",
    fromLat: parseFloat(it.from_lat?.N ?? "0"),
    fromLng: parseFloat(it.from_lng?.N ?? "0"),
    destinationLat: parseFloat(it.destination_lat?.N ?? "0"),
    destinationLng: parseFloat(it.destination_lng?.N ?? "0"),
    rvType: it.rv_type?.S ?? "",
    rvtwTripId: it.rvtw_trip_id?.S,
    routingMode: it.routing_mode?.S,
    routingType: it.routing_type?.S,
    vehicleLength: parseFloat(it.vehicle_length?.N ?? "0"),
    vehicleHeight: parseFloat(it.vehicle_height?.N ?? "0"),
    vehicleWeight: parseFloat(it.vehicle_weight?.N ?? "0"),
    avoidFerries: it.avoid_ferries?.BOOL,
    avoidHighways: it.avoid_highways?.BOOL,
    avoidTolls: it.avoid_tolls?.BOOL,
    avoidUnpaved: it.avoid_unpaved?.BOOL,
    avoidTunnels: it.avoid_tunnels?.BOOL,
    avoidTraffic: it.avoid_traffic?.BOOL,
    hasPropane: it.has_propane?.BOOL,
    appVersion: it.app_version?.S,
    rating: it.rating?.N,
    comment: it.comment?.S,
    wayPoints: JSON.parse(it.way_points?.S ?? "[]"),
    processingVersion: parseFloat(it.processing_version?.N ?? "1"),
    isRouteFixed: it.is_route_fixed?.S === "YES",
  }));
}

export async function queryRecentEntries(
  limit: number = 15000
): Promise<any[]> {
  try {
    const params: ScanCommandInput = {
      TableName: "rvlife_mobileapp_route_list_latest",
      Limit: limit,
    };

    let allRecords: any[] = [];
    let lastEvaluatedKey: Record<string, any> | undefined = undefined;

    // Scan the table and accumulate results
    do {
      if (lastEvaluatedKey) {
        params.ExclusiveStartKey = lastEvaluatedKey;
      }

      const command = new ScanCommand(params);
      const result = await client.send(command);
      allRecords = allRecords.concat(result.Items || []);
      lastEvaluatedKey = result.LastEvaluatedKey;
    } while (lastEvaluatedKey && allRecords.length < limit);
    return awsResponseToRouteItemList(allRecords);
  } catch (error) {
    console.error("Error fetching recent records:", error);
    throw error;
  }
}
