import * as React from "react";
import HereMarker, { MarkerProps as HereMarkerProps } from "./HereMarker";
import { MapObjectEvent } from "map/types";
import { InfoBubbleProps } from "./InfoBubble.type";

export interface MarkerProps extends HereMarkerProps {
  popup?: React.ReactElement | false | null | undefined;
  onPopupClose?: () => void;
}

const offset = { x: 0, y: -10 };

const Marker: React.FC<MarkerProps> = ({
  markerId,
  popup = undefined,
  onTap,
  onPopupClose = undefined,
  position,
  testID,
  icon,
  data,
  zIndex,
  onPointerDown,
  onPointerUp,
  onPointerEnter,
  onPointerLeave,
  useDom,
  volatile,
}: MarkerProps) => {
  // const {
  // 	userStore: {
  // 		ui: { clickForMapDetails }
  // 	},
  // 	mapStore: { openInfoCardMarkerId, activeLocationInfoCard },
  // 	mapStore
  // } = React.useContext(StoreContext);
  // const { mobile, tablet } = useResponsive();
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [moveIntoView, setMoveIntoView] = React.useState(false);
  // const overMarker = React.useRef(false);
  // const overPopup = React.useRef(false);
  // const hideTimerRef = React.useRef<number>();

  // React.useEffect(() => {
  // 	if (
  // 		openInfoCardMarkerId &&
  // 		openInfoCardMarkerId === markerId &&
  // 		activeLocationInfoCard
  // 	) {
  // 		setPopupVisible(true);
  // 		setMoveIntoView(false);
  // 	} else {
  // 		setPopupVisible(false);
  // 	}
  // }, [openInfoCardMarkerId, activeLocationInfoCard, markerId]);

  // Handle the pointer leaving the marker or associated popup
  // const handleLeave = React.useCallback(() => {
  // 	if (!clickForMapDetails && !mobile && !tablet) {
  // 		clearTimeout(hideTimerRef.current);
  // 		hideTimerRef.current = window.setTimeout(() => {
  // 			if (!overMarker.current && !overPopup.current) {
  // 				setPopupVisible(false);
  // 				mapStore.setHoverMarker(undefined);
  // 			}
  // 		}, 1000);
  // 	} else {
  // 		mapStore.setHoverMarker(undefined);
  // 	}
  // }, [clickForMapDetails, mapStore, mobile, tablet]);

  // const mouseOverMarker = React.useCallback(() => {
  // 	overMarker.current = true;
  // 	clearTimeout(hideTimerRef.current);

  // 	// Don't update hoverMarker if the current value is this marker's ID,
  // 	// which will be the case if clickForMapDetails is false and the mouse
  // 	// was previously hovering over the marker's popup.
  // 	if (mapStore.hoverMarker !== markerId) {
  // 		mapStore.setHoverMarker(markerId || 'marker');
  // 	}

  // 	if (!clickForMapDetails) {
  // 		setPopupVisible(true);
  // 		setMoveIntoView(false);
  // 	}
  // }, [clickForMapDetails, mapStore, markerId]);

  // const mouseLeaveMarker = React.useCallback(() => {
  // 	overMarker.current = false;
  // 	handleLeave();
  // }, [handleLeave]);

  // const mouseOverPopup = React.useCallback(() => {
  // 	overPopup.current = true;
  // 	clearTimeout(hideTimerRef.current);
  // }, []);

  // const mouseLeavePopup = React.useCallback(() => {
  // 	overPopup.current = false;
  // 	handleLeave();
  // }, [handleLeave]);

  const mousetap = React.useCallback(
    (event: MapObjectEvent) => {
      setMoveIntoView(true);
      setPopupVisible(true);
      onTap?.(event);
    },
    [onTap]
  );

  const handlePopupClose = React.useCallback(() => {
    onPopupClose?.();
    setPopupVisible(false);
  }, [onPopupClose]);

  return ( 
    <>
      <HereMarker
        markerId={markerId}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        onTap={mousetap}
        position={position}
        testID={testID}
        icon={icon}
        data={data}
        zIndex={zIndex}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        useDom={useDom}
        volatile={volatile}
      />
      {popup &&
        React.cloneElement<InfoBubbleProps>(popup, {
          visible: popupVisible,
          moveIntoView,
          position,
          offset,
          onClose: handlePopupClose,
          // onPointerEnter: mouseOverPopup,
          // onPointerLeave: mouseLeavePopup
        })}
    </>
  );
};

export default Marker;
