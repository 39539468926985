import { MapInfo } from "map/Context";
import { GroupInfo } from "map/types";
import { useMemo, useState } from "react";

/**
 * Hook that handles management of map and group context
 */
const useMapContext = () => {
  const [mapContext, setMapContext] = useState<MapInfo>({
    map: undefined,
    ui: undefined,
    platform: undefined,
    behavior: undefined,
    addObject: undefined,
    removeObject: undefined,
  });
  const groupContext: GroupInfo = useMemo(
    () => ({
      group: mapContext.map,
      addObject: mapContext.addObject,
      removeObject: mapContext.removeObject,
    }),
    [mapContext]
  );
  return { mapContext, groupContext, setMapContext };
};

export default useMapContext;
