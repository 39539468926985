import { createContext, useContext } from "react";
import { Layers, RealTimeStats } from "./types";

export type MapInfo = {
  map: H.Map | undefined;
  ui: H.ui.UI | undefined;
  platform: H.service.Platform | undefined;
  behavior: H.mapevents.Behavior | undefined;
  addObject: ((obj: H.map.Object, layer?: Layers) => void) | undefined;
  removeObject: ((obj: H.map.Object, layer?: Layers) => void) | undefined;
};

const MapContext = createContext<MapInfo>({
  map: undefined,
  ui: undefined,
  platform: undefined,
  behavior: undefined,
  addObject: undefined,
  removeObject: undefined,
});

export const MapProvider = MapContext.Provider;

export function useHereMap(): MapInfo {
  return useContext(MapContext);
}

export const RealTimeStatsContext = createContext<RealTimeStats>({
  oneHourTrips: 0,
  twentyFourHourTrips: 0,
});
export const RealTimeStatsDispatchContext = createContext<
  React.Dispatch<React.SetStateAction<RealTimeStats>>
>(() => {});

export function useRealTimeStats(): RealTimeStats {
  return useContext(RealTimeStatsContext);
}
