import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  routeHash: "",
  pmUserId: "",
};

const supportSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setRouteHash: (state, action: PayloadAction<string>) => {
      state.routeHash = action.payload;
    },
    setPMUserId: (state, action: PayloadAction<string>) => {
      state.pmUserId = action.payload;
    },
  },
});

export const { setRouteHash, setPMUserId } = supportSlice.actions;

export default supportSlice.reducer;
