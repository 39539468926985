import React, { useCallback, useEffect, useMemo, useState } from "react";
import SonarAPI from "./SonarAPI";
import RoadReportsTable from "./RoadReportsTable";
import MapInterface from "./MapInterface";
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import CreateRoadReportModal from "./SonarModals/CreateRoadReportModal";
import CreatedRoadReportModal from "./SonarModals/CreatedRoadReportModal";
import { Container } from "@mui/system";
import { AuthContext } from "stores/AuthContext";
import { MapObjectEvent } from "map/types";

import UpdateLocation from "./UpdateLocation";
import UpdateRoadReportModal from "./SonarModals/UpdateRoadReportModal";
import { CreateRoadReport, HazardType, hazardTypeLabels } from "./types/CreateRoadReport";
import { RoadReport, filterRoadReportsByHazardType } from "./types/RoadReport";
import { useHereMap } from "map/Context";
import { Select, MenuItem } from "@mui/material";
import FiltersModal from "./SonarModals/FiltersModal";
import JSONPretty from "react-json-pretty";
import { Filters } from "./types/Filters";
import { useAppSelector } from "stores/hooks";
import { selectRoute } from "stores/routing";
import { RouteKey } from "stores/types";
import { useLocation } from "react-router-dom";
import CheckBoxMapMount from "support/components/CheckBoxList";
import RoadReportEditor from "./RoadReportEditor";

const SonarSupport: React.FC = () => {
  const { map } = useHereMap();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [execute, setExecute] = useState(queryParams.get("execute") ?? "false");

  const authContext = React.useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is not available");
  }

  const { keycloak } = authContext;
  const sonarApi = useMemo(
    () => new SonarAPI(keycloak?.token ?? null),
    [keycloak?.token]
  );

  const [roadReports, setRoadReports] = useState<RoadReport[]>([]);

  const [selectedHazards, setSelectedHazards] = useState<HazardType[]>(
    Object.values(HazardType)
  );

  // Memoize the generation of checkbox items
  const checkBoxItems = useMemo(() => {
    return Object.values(HazardType).map((hazard, index) => ({
      key: index,
      label: hazardTypeLabels[hazard],
      check: selectedHazards.includes(hazard),
      onCheck: () => {
        setSelectedHazards((prevSelectedHazards) =>
          prevSelectedHazards.includes(hazard)
            ? prevSelectedHazards.filter((h) => h !== hazard)
            : [...prevSelectedHazards, hazard]
        );
      },
    }));
  }, [selectedHazards]);

  const filteredRoadReports = useMemo(
    () => filterRoadReportsByHazardType(roadReports, selectedHazards),
    [roadReports, selectedHazards]
  );

  const [, setBoundingBox] = useState<{
    xmin: number;
    xmax: number;
    ymin: number;
    ymax: number;
  }>({
    xmax: 52,
    xmin: 22.273246422050725,
    ymax: -68.47481737499997,
    ymin: -122.43966112499997,
  });
  // values for creating a new Road Report
  const [mapTapped, setMapTapped] = useState<[number, number] | null>(null);
  const [createRoadReportRequest, setCreateRoadReportRequest] = useState("");
  const [renderPolylines, setRenderPolylines] = useState<string[]>([]);

  const [adjustView, setAdjustView] = useState(true);

  const [draggedRoadReport, setDraggedRoadReport] = useState<RoadReport | undefined>(undefined);
  const [oldRoadReport, setOldRoadReport] = useState<RoadReport | undefined>(undefined);

  // values for interacting with markers
  const [selectedMarker, setSelectedMarker] = useState<RoadReport | undefined>(
    undefined
  );

  const [showUpdate, setShowUpdate] = useState(false);
  const [resetDragged, setResetDragged] = useState(false);

  const [addFiltersOpen, setAddFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<Filters | undefined>(undefined);
  const [selectedQuery, setSelectedQuery] = useState<string>("mapBoxRoadReports");
  const [linkedRoadReportId, setLinkedRoadReportId] = useState<string | null>(queryParams.get("id") ?? null);
  const [bufferSize, setBuffer] = useState<number>(0.001);
  const [selectedRouteType, setSelectedRouteType] = useState<RouteKey>(
    (queryParams.get("routeLine") as RouteKey) ?? "car"
  );
  const [groupFactor, setGroupFactor] = useState<number>(400);
  const [showGroupFactorField, setShowGroupFactorField] = useState(false);
  const [includeExpiredFlag, setIncludeExpiredFlag] = useState(false);

  const route = useAppSelector(selectRoute(selectedRouteType));
  // on filters change, refetch the Road Reports

  const updateDraggedRoadReportState = async (
    lat: number,
    lon: number,
    dragID: String
  ) => {
    if (selectedMarker) {
      return;
    }
    setResetDragged(false);
    setDraggedRoadReport(undefined);
    let newRoadReport = roadReports.filter((roadReport) => roadReport.id === dragID)[0];
    setOldRoadReport(roadReports.filter((roadReport) => roadReport.id === dragID)[0]);
    let roadReport = { ...newRoadReport, lat: lat, lon: lon };
    setDraggedRoadReport(roadReport);
  };

  const resetObjects = useCallback(() => {
    setRoadReports([]);
    setRenderPolylines([""]);
  }, []);

  const fetchPolyLineRoadReports = useCallback(
    async (searchQuery: string[], bufferSize: number) => {
      resetObjects();
      const roadReportsData = await sonarApi.getRoadReportsAlongPolylines(
        searchQuery,
        bufferSize,
        filters,
        showGroupFactorField ? groupFactor : undefined,
        includeExpiredFlag
      );
      setRoadReports(roadReportsData);
      setRenderPolylines(searchQuery);
    },
    [
      resetObjects,
      sonarApi,
      filters,
      showGroupFactorField,
      groupFactor,
      includeExpiredFlag,
    ]
  );

  
  const handleBoundingBoxChange = async (boundingBox: {
    xmin: number;
    xmax: number;
    ymin: number;
    ymax: number;
  }) => {
    setBoundingBox(boundingBox);
  };
  // if the linkedRoadReportId is set, then set the map to the bounds of the road report
  useEffect(() => {
    const fetchRoadReport = async () => {
      if (linkedRoadReportId) {
        try {
          const roadReport = await sonarApi.getRoadReportById(linkedRoadReportId);
          console.log(roadReport);
          setRoadReports([roadReport]);
          

          let lat = roadReport.lat;
          let lon = roadReport.lon;
          let position = new H.geo.Point(lat, lon);
         
            setTimeout(() => {
            map?.getViewModel().setLookAtData({
              position: position,
              zoom: 14, // Adjust zoom level as needed
            });
          }, 1000);
        } catch (error) {
          console.error("Error fetching road report:", error);
        }
      }
    };

    fetchRoadReport();
  }, [linkedRoadReportId, sonarApi, map]);

  const getRoadReportsInBoundingBox = useCallback(async () => {
    try {
      console.log("Getting Road Reports in map box");
      setAdjustView(false);
      let bounds = map?.getViewModel().getLookAtData().bounds;
      let box = bounds?.getBoundingBox();

      let left = box?.getLeft();
      let right = box?.getRight();
      let top = box?.getTop();
      let bottom = box?.getBottom();
      if (!left || !right || !top || !bottom) {
        return;
      }
      const roadReportsData = await sonarApi.getRoadReportsWithinBox(
        {
          xmax: top,
          xmin: bottom,
          ymax: right,
          ymin: left,
        },
        filters,
        showGroupFactorField ? groupFactor : undefined,
        includeExpiredFlag
      );
      setRoadReports(roadReportsData);
    } catch (error) {
      console.error("Error fetching Road Reports:", error);
    }
  }, [
    map,
    sonarApi,
    filters,
    showGroupFactorField,
    groupFactor,
    includeExpiredFlag,
  ]);

  const fetchRoadReportsAlongGeneratedRoute = useCallback(async () => {
    // get the route from the map
    const routeData = route;
    if (!routeData) {
      console.error("No route data found");
      alert(
        "No route data found, enter the route first in the route tools tab"
      );
      return;
    }
    //get the polyline
    let polylines = [];
    polylines =
      route.routes[0].sections.map((section: any) => section.polyline) ?? [];
    console.log("Polylines:", polylines);

    polylines = polylines.filter((polyline) => polyline !== undefined);

    let roadReportsData = await sonarApi.getRoadReportsAlongPolylines(
      polylines,
      bufferSize,
      filters,
      showGroupFactorField ? groupFactor : undefined,
      includeExpiredFlag
    );

    setRoadReports(roadReportsData);
    setRenderPolylines(polylines);
  }, [
    route,
    sonarApi,
    bufferSize,
    filters,
    showGroupFactorField,
    groupFactor,
    includeExpiredFlag,
  ]);

  const fetchRoadReports = useCallback(async () => {
    console.log("Selected Query:", selectedQuery);
    switch (selectedQuery) {
      case "mapBoxRoadReports":
        getRoadReportsInBoundingBox();
        break;
      case "polyline":
        fetchPolyLineRoadReports(renderPolylines, bufferSize);
        break;
     
     
      case "getAlongGeneratedRoute":
        fetchRoadReportsAlongGeneratedRoute();
        break;
    }
  }, [
    bufferSize,
    fetchRoadReportsAlongGeneratedRoute,
    fetchPolyLineRoadReports,
    getRoadReportsInBoundingBox,
    renderPolylines,
    selectedQuery,
  ]);

  const recallGetRoadReportsInBoundingBox = async (newFilters: Filters) => {
    let bounds = map?.getViewModel().getLookAtData().bounds;
    let box = bounds?.getBoundingBox();

    let left = box?.getLeft();
    let right = box?.getRight();
    let top = box?.getTop();
    let bottom = box?.getBottom();
    if (!left || !right || !top || !bottom) {
      return;
    }
    const roadReportsData = await sonarApi.getRoadReportsWithinBox(
      {
        xmax: top,
        xmin: bottom,
        ymax: right,
        ymin: left,
      },
      newFilters,
      showGroupFactorField ? groupFactor : undefined,
      includeExpiredFlag
    );
    setRoadReports(roadReportsData);
  };

  const clearRoadReports = () => {
    setRoadReports([]);
  };

  const onMapTapped = (lat: number, lon: number) => {
    if (selectedMarker) {
      return;
    }
    console.log("Map tapped at:", lat, lon);
    setMapTapped([lat, lon]);
  };

  const onMarkerTapped = (event: MapObjectEvent) => {
    setSelectedMarker(event.target?.getData());
    event.stopPropagation();

    setMapTapped(null);
  };

  const deleteMarker = async () => {
    if (!selectedMarker) {
      return;
    }
    try {
      // remove the marker
      const newRoadReports = roadReports.filter((roadReport) => roadReport.id !== selectedMarker.id);
      setRoadReports(newRoadReports);

      setSelectedMarker(undefined);
    } catch (error) {
      console.error("Error deleting Road Report:", error);
    }
  };

  const createNewRoadReport = async (createRoadReport: CreateRoadReport) => {
    setMapTapped(null);

    try {
      const response = await sonarApi.createRoadReport(createRoadReport);
      setCreateRoadReportRequest(JSON.stringify(response.data));
      setCreateRoadReportRequest(JSON.stringify(response.data));
    } catch (error) {
      console.error("Error creating Road Report:", error);
    }
  };
  const resetDraggedRoadReport = () => {
    setDraggedRoadReport(undefined);
    setOldRoadReport(undefined);
    setShowUpdate(false);
    setResetDragged(true);
    setTimeout(() => {
      setResetDragged(false);
    }, 100);
  };

  const updateRoadReport = async (roadReport: RoadReport) => {
    try {
      const response = await sonarApi.updateRoadReport(roadReport);
      console.log("Response:", response);
      if (response.status === 200) {
        //remove the old Road Report
        setRoadReports(roadReports.filter((r) => r.id !== roadReport.id));
        //go get the new data
        const roadReportsData = await sonarApi.getById(roadReport.id);
        console.log("Road Reports:", roadReportsData);

        setRoadReports(roadReports.map((r) => (r.id === roadReport.id ? roadReportsData : r)));
        setDraggedRoadReport(undefined);
      }
      setSelectedMarker(undefined);
    } catch (error) {
      console.error("Error updating Road Report:", error);
    }
  };

  const addFilters = () => {
    console.log("Add Filters");
    setAddFiltersOpen(true);
  };

  const closeFilters = () => {
    setAddFiltersOpen(false);
  };

  const handleDropdownSelect = (event: SelectChangeEvent) => {
    console.log("Event:", event.target.value);
    setSelectedQuery(event.target.value);
  };

  useEffect(() => {
    if (execute === "true") {
      fetchRoadReports();
      setExecute("false");
    }
  }, [execute, fetchRoadReports]);

  // create helpers for fetchPolyLineRoadReports

  return (
    <Container>
      <CheckBoxMapMount checkBoxItems={checkBoxItems} />{" "}
      {/* Add this component */}
      <UpdateRoadReportModal
        open={selectedMarker !== undefined}
        onClose={() => setSelectedMarker(undefined)}
        roadReport={selectedMarker}
        onUpdate={updateRoadReport}
        onDelete={deleteMarker}
      />
      <FiltersModal
        filters={filters}
        isOpen={addFiltersOpen}
        onClose={closeFilters}
        setFilters={setFilters}
        filterUpdate={recallGetRoadReportsInBoundingBox}
      />
      <CreateRoadReportModal
        open={mapTapped !== null && !selectedMarker}
        onClose={() => setMapTapped(null)}
        onCreate={createNewRoadReport}
        jsonData={{
          lat: mapTapped?.[0] ?? 0,
          lon: mapTapped?.[1] ?? 0,
        }}
      />
      <CreatedRoadReportModal
        open={createRoadReportRequest !== ""}
        onClose={() => setCreateRoadReportRequest("")}
        response={createRoadReportRequest}
      />
      <Container>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            md={12}
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            {draggedRoadReport ? (
              <Grid item xs={6} md={12}>
                <Typography variant="h5">You have dragged a Road Report!</Typography>
                <Typography variant="h5">
                  {"Would you like to update it?:"}
                </Typography>
                <Typography variant="h6">{draggedRoadReport.id}</Typography>
                <ButtonGroup variant="contained" aria-label="button group">
                  <Button onClick={() => setShowUpdate(true)}>
                    Show Update
                  </Button>
                  <Button onClick={resetDraggedRoadReport}>Cancel Update</Button>
                </ButtonGroup>
              </Grid>
            ) : (
              <Grid item xs={6} md={12}>
                <Typography variant="h5" gutterBottom>
                  Get Road Reports:
                </Typography>
                <Grid item xs={6} md={12}>
                  <Select
                    value={selectedQuery || "mapBoxRoadReports"}
                    onChange={handleDropdownSelect}
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      Select an option
                    </MenuItem>
                    <MenuItem value="mapBoxRoadReports">
                      Get Road Reports in boundingbox
                    </MenuItem>
                    <MenuItem value="polyline">Get Along Polyline</MenuItem>
                    <MenuItem value="allRoadReports">Get all Road Reports</MenuItem>
                    <MenuItem value="getAlongGeneratedRoute">
                      Get Along Generated Route
                    </MenuItem>
                  </Select>
                  <Grid item xs={6} md={12}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showGroupFactorField}
                            onChange={(e) =>
                              setShowGroupFactorField(e.target.checked)
                            }
                            name="showTextField"
                          />
                        }
                        label="Apply Group by Distance Factor"
                      />
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={includeExpiredFlag}
                              onChange={(e) =>
                                setIncludeExpiredFlag(e.target.checked)
                              }
                              name="showTextField"
                            />
                          }
                          label="Include expired points"
                        />
                      </Grid>
                    </Grid>
                    {showGroupFactorField && (
                      <Grid item xs={12}>
                        <TextField
                          label="Group by a distance factor (in meters)"
                          fullWidth
                          type="number"
                          value={groupFactor}
                          onChange={(e) =>
                            setGroupFactor(parseInt(e.target.value))
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={6} md={12}>
                    {selectedQuery === "getAlongGeneratedRoute" && (
                      <Select
                        value={selectedRouteType}
                        onChange={(e) =>
                          e.target.value
                            ? setSelectedRouteType(e.target.value as RouteKey)
                            : ""
                        }
                        fullWidth
                      >
                        <MenuItem value="car">Car</MenuItem>
                        <MenuItem value="truck">Truck</MenuItem>
                        <MenuItem value="bus">Bus</MenuItem>
                      </Select>
                    )}
                  </Grid>
                  {selectedQuery === "polyline" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Enter Polyline"
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            e.target.value
                              ? setRenderPolylines([e.target.value])
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                  {(selectedQuery === "polyline" ||
                    selectedQuery === "getAlongGeneratedRoute") && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Enter Buffer Size"
                          variant="outlined"
                          type="number"
                          fullWidth
                          value={bufferSize}
                          onChange={(e) => {
                            if (e.target.value) {
                              console.log("Buffer Size:", e.target.value);
                              setBuffer(parseFloat(e.target.value));
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  alignContent="center"
                  justifyContent="center"
                >
                  {filters !== undefined && (
                    <>
                      <Typography variant="h5" gutterBottom>
                        Filters:
                      </Typography>
                      <Typography variant="h6">
                        <JSONPretty data={filters}></JSONPretty>
                      </Typography>
                    </>
                  )}
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={addFilters}
                        fullWidth
                      >
                        Add Filters
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={fetchRoadReports}
                        fullWidth
                      >
                        Fetch Road Reports
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={clearRoadReports}
                        fullWidth
                      >
                        Clear Road Reports
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {draggedRoadReport && (
              <Button onClick={() => setShowUpdate(true)}>Show Update</Button>
            ) && ( // Add this button
              <UpdateLocation
                oldRoadReport={oldRoadReport}
                newRoadReport={{ ...draggedRoadReport }}
                showUpdate={showUpdate}
                setShowUpdate={setShowUpdate}
                onUpdate={updateRoadReport}
              />
            )}
          {!draggedRoadReport && !linkedRoadReportId && <RoadReportsTable roadReports={roadReports} />}
          {linkedRoadReportId && <RoadReportEditor roadReport={roadReports[0]} onSave={updateRoadReport} onCancel={() => setLinkedRoadReportId(null)}/>}
        </Grid>
      </Container>
      <MapInterface
        roadReports={filteredRoadReports}
        resetDragged={resetDragged}
        onBoundingBoxChange={handleBoundingBoxChange}
        onMapTapped={onMapTapped}
        polylines={renderPolylines}
        onMarkerTapped={onMarkerTapped}
        adjustView={adjustView}
        updateRoadReportPosition={(lat, lon, dragID) =>
          updateDraggedRoadReportState(lat, lon, dragID)
        }
        draggedRoadReport={draggedRoadReport}
      />
    </Container>
  );
};

export default SonarSupport;