export enum HazardType {
  LowClearance = "low_clearance",
  NoTrucks = "no_trucks",
  Tunnel = "tunnel",
  UnpavedRoad = "unpaved_road",
  NarrowRoad = "narrow_road",
  SteepGradeUp = "steep_grade_up",
  SteepGradeDown = "steep_grade_down",
  WeightLimit = "weight_limit",
  RoadHazard = "road_hazard",
  HighWinds = "high_winds",
  Snow = "snow",
  Flooding = "flooding",
  RoadConstruction = "road_construction",
  RoadClosure = "road_closure",
  SharpTurn = "sharp_turn",
  RoughRoad = "rough_road",
}

export const hazardTypeLabels: { [key in HazardType]: string } = {
  [HazardType.LowClearance]: "Low Clearance",
  [HazardType.NoTrucks]: "No Trucks",
  [HazardType.Tunnel]: "Tunnel",
  [HazardType.UnpavedRoad]: "Unpaved Road",
  [HazardType.NarrowRoad]: "Narrow Road",
  [HazardType.SteepGradeUp]: "Steep Grade Up",
  [HazardType.SteepGradeDown]: "Steep Grade Down",
  [HazardType.WeightLimit]: "Weight Limit",
  [HazardType.RoadHazard]: "Road Hazard",
  [HazardType.HighWinds]: "High Winds",
  [HazardType.Snow]: "Snow",
  [HazardType.Flooding]: "Flooding",
  [HazardType.RoadConstruction]: "Road Construction",
  [HazardType.RoadClosure]: "Road Closure",
  [HazardType.SharpTurn]: "Sharp Turn",
  [HazardType.RoughRoad]: "Rough Road",
};

export enum RvType {
  Motorhome = "Motorhome",
  TravelTrailer = "Travel Trailer",
  FifthWheel = "Fifth Wheel",
  ToyHauler = "Toy Hauler",
  TentTrailer = "Tent Trailer",
  TruckCamper = "Truck Camper",
}

export type CreateRoadReport = {
  lat: number;
  lon: number;
  rv_height: number | null;
  rv_length: number | null;
  rv_type: RvType | null;
  pm_user_id: number | null;
  hazard_type: HazardType | null;
  rv_weight: number | null;
  low_clearance_height: number | null;
  weight_limit: number | null;
};
