import React from "react";
import {
  Stack,
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import { clearAllRoutes, selectMapBoxRoute, selectRoute } from "stores/routing";
import RouteBox from "./RouteBox";
import RouteMapbox from "./RouteMapBoxBox";
import AvoidAreaToolButtons from "../AvoidArea/AvoidAreaToolButtons";
import { useNavigate } from "react-router-dom";
import BusZonesToolButtons from "tools/BusZones/BusZoneToolButtons";
import { RouteKey } from "stores/types";
import SegmentDetails from "./SegmentDetails";



const RouteResultsSection: React.FC = () => {
  const [selectedRoute, setSelectedRoute] = React.useState<string | null>(null);
  const [selectedRouteForComments, setSelectedRouteForComments] = React.useState<string | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const mapBoxRoute = useAppSelector(selectMapBoxRoute());


  // Manually call useAppSelector for each route key
  const routes = [
    { key: "car0", route: useAppSelector(selectRoute("car0")) },
    { key: "car1", route: useAppSelector(selectRoute("car1")) },
    { key: "car2", route: useAppSelector(selectRoute("car2")) },
    { key: "car3", route: useAppSelector(selectRoute("car3")) },
    { key: "car4", route: useAppSelector(selectRoute("car4")) },
    { key: "car5", route: useAppSelector(selectRoute("car5")) },
    { key: "car6", route: useAppSelector(selectRoute("car6")) },
    { key: "carNP0", route: useAppSelector(selectRoute("carNP0")) },
    { key: "carNP1", route: useAppSelector(selectRoute("carNP1")) },
    { key: "carNP2", route: useAppSelector(selectRoute("carNP2")) },
    { key: "carNP3", route: useAppSelector(selectRoute("carNP3")) },
    { key: "carNP4", route: useAppSelector(selectRoute("carNP4")) },
    { key: "carNP5", route: useAppSelector(selectRoute("carNP5")) },
    { key: "carNP6", route: useAppSelector(selectRoute("carNP6")) },
    { key: "truck0", route: useAppSelector(selectRoute("truck0")) },
    { key: "truck1", route: useAppSelector(selectRoute("truck1")) },
    { key: "truck2", route: useAppSelector(selectRoute("truck2")) },
    { key: "truck3", route: useAppSelector(selectRoute("truck3")) },
    { key: "truck4", route: useAppSelector(selectRoute("truck4")) },
    { key: "truck5", route: useAppSelector(selectRoute("truck5")) },
    { key: "truck6", route: useAppSelector(selectRoute("truck6")) },
    { key: "truckNP0", route: useAppSelector(selectRoute("truckNP0")) },
    { key: "truckNP1", route: useAppSelector(selectRoute("truckNP1")) },
    { key: "truckNP2", route: useAppSelector(selectRoute("truckNP2")) },
    { key: "truckNP3", route: useAppSelector(selectRoute("truckNP3")) },
    { key: "truckNP4", route: useAppSelector(selectRoute("truckNP4")) },
    { key: "truckNP5", route: useAppSelector(selectRoute("truckNP5")) },
    { key: "truckNP6", route: useAppSelector(selectRoute("truckNP6")) },
    { key: "bus0", route: useAppSelector(selectRoute("bus0")) },
    { key: "bus1", route: useAppSelector(selectRoute("bus1")) },
    { key: "bus2", route: useAppSelector(selectRoute("bus2")) },
    { key: "bus3", route: useAppSelector(selectRoute("bus3")) },
    { key: "bus4", route: useAppSelector(selectRoute("bus4")) },
    { key: "bus5", route: useAppSelector(selectRoute("bus5")) },
    { key: "bus6", route: useAppSelector(selectRoute("bus6")) },
    { key: "busNP0", route: useAppSelector(selectRoute("busNP0")) },
    { key: "busNP1", route: useAppSelector(selectRoute("busNP1")) },
    { key: "busNP2", route: useAppSelector(selectRoute("busNP2")) },
    { key: "busNP3", route: useAppSelector(selectRoute("busNP3")) },
    { key: "busNP4", route: useAppSelector(selectRoute("busNP4")) },
    { key: "busNP5", route: useAppSelector(selectRoute("busNP5")) },
    { key: "busNP6", route: useAppSelector(selectRoute("busNP6")) },
  ];

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Button type="submit" variant="outlined">
          Route
        </Button>
        <Button onClick={() => dispatch(clearAllRoutes())} variant="outlined">
          Clear
        </Button>
      </Stack>
      <SegmentDetails />
      {routes.map(({ key, route }) => {
        return (
          route && <RouteBox key={key} route={route} id={key as RouteKey} />
        );
      })}
      <RouteMapbox route={mapBoxRoute as any} id="mapBox" />
      <AvoidAreaToolButtons />
      <BusZonesToolButtons />

      <FormControl>
        <InputLabel>Jump to Sonar</InputLabel>
        <Select
          onChange={(e: SelectChangeEvent) => setSelectedRoute(e.target.value)}
        >
          {routes.map(({ key, route }) => {
            if (route) {
              return (<MenuItem key={key} value={key}>
                {key}
              </MenuItem>)
            } return null;
          })}
        </Select>
      </FormControl>
      <Button
        onClick={() => {
          if (!selectedRoute) {
            alert("Please select a route to jump to Sonar with");
            return;
          }

          navigate(`/sonar?routeLine=${selectedRoute}&execute=true`);
        }}
      >
        Jump to Sonar
      </Button>

      <FormControl>
        <InputLabel>Select Route for Comments</InputLabel>
        <Select
          onChange={(e: SelectChangeEvent) => setSelectedRouteForComments(e.target.value)}
        >
          {routes.map(({ key, route }) => {
            return (
              route && <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            );
          })}

        </Select>
      </FormControl>
      <Button
        onClick={() => {
          if (!selectedRouteForComments) {
            alert("Please select a route to view comments");
            return;
          }

          navigate(`/comments?routeLine=${selectedRouteForComments}`);
        }}
      >
        What are people saying?
      </Button>
    </Stack>
  );
};

export default RouteResultsSection;
