import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import {
  isMapGridVisible,
  toggleVisibility,
  getEditedAvoidArea,
  discardChanges,
  createEditedAvoidArea,
  getEditedAvoidPolygon,
  createEditedAvoidPolygon,
} from "stores/mapGrid";

const AvoidAreaToolButtons: React.FC = () => {
  const dispatch = useAppDispatch();
  const isGridVisible = useAppSelector(isMapGridVisible);
  const editedAvoidBbox = useAppSelector(getEditedAvoidArea);
  const editedAvoidPolygon = useAppSelector(getEditedAvoidPolygon);

  const toggleAvoidAreasVisibility = () => {
    dispatch(toggleVisibility());
  };

  const setAvoidAreaCreating = () => {
    dispatch(createEditedAvoidArea());
  };

  const setAvoidAreaDiscarding = () => {
    dispatch(discardChanges());
  };

  const setAvoidPolygonCreating = () => {
    dispatch(createEditedAvoidPolygon());
  };

  const editedAvoidArea = editedAvoidBbox || editedAvoidPolygon;

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={toggleAvoidAreasVisibility}>
        {isGridVisible ? "Hide " : "Show "} Avoid Areas
      </Button>
      <Button
        variant="outlined"
        component={Link}
        to="/avoid-area-list"
        onClick={() => {
          if (!isGridVisible) {
            toggleAvoidAreasVisibility();
          }
        }}
      >
        List Avoid Areas
      </Button>
      {editedAvoidArea ? (
        <Button variant="outlined" onClick={setAvoidAreaDiscarding}>
          Discard Avoid Area
        </Button>
      ) : (
        <>
          <Button variant="outlined" onClick={setAvoidAreaCreating}>
            Add Avoid Area
          </Button>
          <Button variant="outlined" onClick={setAvoidPolygonCreating}>
            Add Avoid Polygon
          </Button>
        </>
      )}
    </React.Fragment>
  );
};

export default AvoidAreaToolButtons;
