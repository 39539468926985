import React from "react";
import { selectDestination } from "stores/routing";
import { useAppSelector } from "stores/hooks";
import Marker from "map/elements/Marker";

const Destination: React.FC = () => {
  const destination = useAppSelector(selectDestination);

  if (destination) return <Marker position={destination} />;
  return null;
};

export default Destination;
