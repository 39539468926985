
export function decodeLatLngOffset(latString: string, lonString: string) {
    // Parse the strings into arrays, handling potential blanks
    const latArray = latString.split(',').map(num => num === '' ? null : Number(num));
    const lonArray = lonString.split(',').map(num => num === '' ? null : Number(num));

    // Initialize the positions array
    const positions = [];

    // Verify both arrays have the same initial entry count
    if (latArray.length === 0 || lonArray.length === 0) {
        console.error('Both LAT and LON data must have at least one entry.');
        return [];
    }

    // Initialize the first entry as the starting position
    let lat = latArray[0];
    let lon = lonArray[0];

    // Validate the first entry
    if (lat === null || lon === null) {
        console.error('The first entry in LAT and LON must be a valid number.');
        return [];
    }

    // Add the initial positions after dividing by 100000
    positions.push(lat / 100000, lon / 100000);

    // Calculate the absolute positions using offsets
    for (let i = 1; i < latArray.length; i++) {
        // Use the previous value if current is blank/null
        lat += latArray[i] ?? 0;
        lon += lonArray[i] ?? 0;

        // Push the new positions divided by 100000
        positions.push(lat / 100000, lon / 100000);
    }

    return positions;
}