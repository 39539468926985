import { TextField, InputAdornment, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const SearchField: React.FC<{
  searchValue: string;
  setSearchValue: (value: string) => void;
  setQuery: (value: string) => void;
  onSearch: () => void;
  onClear: () => void;
  placeholder: string;
}> = ({
  searchValue,
  setSearchValue,
  setQuery,
  onSearch,
  onClear,
  placeholder,
}) => (
  <TextField
    onChange={(e) => {
      setSearchValue(e.target.value);
      setQuery(e.target.value);
    }}
    onKeyDown={(e) => {
      if (e.key === "Enter") onSearch();
    }}
    placeholder={placeholder}
    value={searchValue}
    size="small"
    sx={{ ml: 1 }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            onClick={() => {
              setSearchValue("");
              onClear();
            }}
            edge="end"
          >
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);
