export const RouteOutlineStyle = {
  lineWidth: 5,
  strokeColor: "#f48fb1",
  lineTailCap: "arrow-tail",
  lineHeadCap: "arrow-head",
};

export const RouteArrowsStyle = {
  lineWidth: 5,
  fillColor: "white",
  strokeColor: "rgba(255, 255, 255, 1)",
  lineDash: [0, 2],
  lineTailCap: "arrow-tail",
  lineHeadCap: "arrow-head",
};

export const NormalRouteStyle = {
  lineWidth: 5,
  strokeColor: "rgba(0, 128, 255, 0.7)",
};

export const MARKER_SIZES = {
  width: 48,
  height: 56
};