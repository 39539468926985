import H from "@here/maps-api-for-javascript";
import React, { useEffect, useState, useRef } from "react";
import { Route } from "./models/Route";
import CheckBoxMapMount, { CheckBoxEntry } from "./components/CheckBoxList";
import { pink } from "@mui/material/colors";
import { useHereMap } from "map/Context";
import LegendMapMount, { LegendEntry } from "./components/Legend";
import { useRouteData } from "./hooks/useRouteData";
import {
  displayMinimalRoute,
  displayActualRoute,
  displayCalculatedRoute,
} from "./displayRouteLogic";
import { createGeoRect } from "util/mapFunctions";
import { AuthContext } from "stores/AuthContext";

const RouteDetails: React.FC<{
  route: Route;
  handleClose: () => void;
}> = ({ route }) => {
  const routeHash = route.id;
  const { fromLat, fromLng, destinationLat, destinationLng } = route;
  const [actualRouteVisible, setActualRouteVisible] = useState(true);
  const [calculatedRouteVisible, setCalculatedRouteVisible] = useState(false);
  const [visibleLegends, setVisibleLegends] = useState<number[]>([1, 2, 3]);

  const handleToggleLegendItem = (key: number) => {
    setVisibleLegends((prevState) =>
      prevState.includes(key)
        ? prevState.filter((k) => k !== key)
        : [...prevState, key]
    );
  };

  const { map } = useHereMap();
  const authContext = React.useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is not available");
  }
  const { keycloak } = authContext;
  if (!keycloak) {
    throw new Error("Keycloak is not available");
  }

  const actualRouteObjectsRef = useRef<H.map.Object[]>([]);
  const calculatedRouteObjectsRef = useRef<H.map.Object[]>([]);

  const removeMapObjects = React.useCallback(
    (objects: H.map.Object[]) => {
      objects.forEach((obj) => {
        try {
          map?.removeObject(obj);
        } catch (e) {
          console.log(obj);
        }
      });
      objects.length = 0; // Clear the array after removing the objects
    },
    [map]
  );

  const routeData = useRouteData(routeHash);

  useEffect(() => {
    // Only display the route if we have the required data
    if (routeData && routeData.length > 1) {
      if (actualRouteVisible) {
        const { mapObjects: actualRouteMapObjects, bounds } =
          displayActualRoute(routeData, route, visibleLegends);

        actualRouteObjectsRef.current = actualRouteMapObjects;
        map?.addObjects(actualRouteObjectsRef.current);
        if (bounds) {
          map?.getViewPort().setPadding(200, 200, 200, 200);
          map?.getViewModel().setLookAtData({ bounds });
        }
      }
      if (calculatedRouteVisible) {
        const handleRenderComplete = (createdMapObjects: H.map.Object[]) => {
          // Here you would use the created map objects, for example, assigning them to a ref
          calculatedRouteObjectsRef.current = createdMapObjects;
          map?.addObjects(calculatedRouteObjectsRef.current);
          // Update view port based on route line
          map?.getViewPort().setPadding(200, 200, 200, 200);

          map?.getViewModel().setLookAtData({
            bounds: createGeoRect(
              routeData[0].lat,
              routeData[0].lng,
              destinationLat,
              destinationLng
            ),
          });
        };
        displayCalculatedRoute(
          routeData,
          route,
          handleRenderComplete,
          keycloak.token!
        );
      }
    } else {
      const markerGroup = displayMinimalRoute(
        fromLat,
        fromLng,
        destinationLat,
        destinationLng
      );
      actualRouteObjectsRef.current.push(markerGroup);
      map?.addObjects(actualRouteObjectsRef.current);
      map?.getViewPort().setPadding(200, 200, 200, 200);
      map
        ?.getViewModel()
        .setLookAtData({ bounds: markerGroup.getBoundingBox() });
    }
    return () => {
      removeMapObjects(actualRouteObjectsRef.current);
      removeMapObjects(calculatedRouteObjectsRef.current);
    };
  }, [
    routeData,
    actualRouteVisible,
    calculatedRouteVisible,
    destinationLat,
    destinationLng,
    fromLat,
    fromLng,
    map,
    removeMapObjects,
    route,
    keycloak.token,
    visibleLegends,
  ]);

  const checkBoxItems: CheckBoxEntry[] = [
    {
      key: 1,
      label: "Actual Route",
      check: actualRouteVisible,
      onCheck: () => setActualRouteVisible(!actualRouteVisible),
    },
    {
      key: 2,
      label: "Calculated Route",
      check: calculatedRouteVisible,
      colorBox: pink[400],
      colorCheck: pink[200],
      onCheck: () => setCalculatedRouteVisible(!calculatedRouteVisible),
    },
  ];

  const legendItems: LegendEntry[] = [
    {
      key: 1,
      color: "#C51616",
      title: "Route Recalculation",
      visible: visibleLegends.includes(1),
    },
    {
      key: 2,
      color: "#000000",
      title: "User Cancelled",
      visible: visibleLegends.includes(2),
    },
    {
      key: 3,
      color: "#AF18B2",
      title: "User Defined Waypoints",
      visible: visibleLegends.includes(3),
    },
  ];

  return (
    <>
      <CheckBoxMapMount checkBoxItems={checkBoxItems} />
      <LegendMapMount
        legendItems={legendItems}
        onToggle={handleToggleLegendItem}
      />
    </>
  );
};

export default RouteDetails;
