import React from "react";
import { selectOrigin } from "stores/routing";
import { useAppSelector } from "stores/hooks";
import Marker from "map/elements/Marker";

const Origin: React.FC = () => {
  const origin = useAppSelector(selectOrigin);
  if (origin) return <Marker position={origin} />;
  return null;
};

export default Origin;
