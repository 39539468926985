import {
  AppBar,
  Autocomplete,
  Box,
  Grid,
  SvgIcon,
  TextField,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import debounce from "@mui/material/utils/debounce";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { DiscoverItem } from "./Autocomplete";
import { addSearchResult } from "stores/routing";
import { useAppDispatch } from "stores/hooks";
import { useHereMap } from "map/Context";
import { algolia } from "api/algolia";
import {
  getCampgroundsVisibility,
  toggleCampgroundVisibility,
  getIncidentsVisibility,
  toggleIncidentVisibility,
} from "stores/ui";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useAppSelector } from "stores/hooks";

export const AutocompleteSearch: React.FC = () => {
  const campgroundsVisible = useAppSelector(getCampgroundsVisibility);
  const incidentsVisible = useAppSelector(getIncidentsVisibility);

  const { map, platform } = useHereMap();

  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState<DiscoverItem[]>([]);
  const [campgroundSearchResults, setCampgroundSearchResults] = React.useState<
    DiscoverItem[]
  >([]);

  const fetch = React.useMemo(
    () =>
      debounce((request: { input: string }) => {
        if (request.input.length > 3) {
          const center = map?.getViewModel().getLookAtData().position;
          const centerString = center ? `${center.lat},${center.lng}` : "0,0";
          const pParks = algolia.parkSuggestion(request.input);
          const searchService = platform?.getSearchService();
          searchService?.discover(
            // use map bounding box as search area? or just map center?
            { q: request.input, at: centerString },
            (results: any) => {
              if (results) {
                setSearchResults(results.items);
              }
            },
            (error: any) => {
              console.error(error);
            }
          );
          // sort / prioritize results that
          // match the search string
          // have ontologyId "here:cm:ontology:camping"
          // closer to map center (use distance from map center)
          pParks.then((parks) => {
            setCampgroundSearchResults(parks);
          });
        }
      }, 400),
    [map, platform]
  );

  const allSearchResults = React.useMemo(() => {
    const unfilteredEntries = [...campgroundSearchResults, ...searchResults];

    const removePlaceDuplicates = (entries: DiscoverItem[]): DiscoverItem[] => {
      function normalizeTitle(title: string) {
        // Convert to lower case and remove trailing 's' (for plural forms)
        return title.toLowerCase().replace(/s\s*$/, "").replace(/\s+/g, "");
      }
      return entries.filter((entry, index, self) => {
        // Keep if resultType is not 'place'
        if (entry.resultType !== "place") return true;

        // Check for an almost-duplicate of type 'park'
        const isDuplicate = self.some((otherEntry) => {
          if (otherEntry.resultType !== "park") return false;

          // Considering a small tolerance for coordinate comparison
          const isCloseEnough =
            Math.abs(entry.position.lat - otherEntry.position.lat) < 0.0001 &&
            Math.abs(entry.position.lng - otherEntry.position.lng) < 0.0001;

          const isTitleSimilar =
            normalizeTitle(entry.title) === normalizeTitle(otherEntry.title);

          return isCloseEnough && isTitleSimilar;
        });

        // Keep the entry if it's NOT a duplicate
        return !isDuplicate;
      });
    };
    return removePlaceDuplicates(unfilteredEntries);
  }, [searchResults, campgroundSearchResults]);

  React.useEffect(() => {
    fetch({ input: searchTerm });
  }, [fetch, searchTerm]);

  // switch to mui autocomplete component and helpers
  return (
    <AppBar
      position="static"
      color="default"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: 64,
        backgroundColor: "white",
      }}
    >
      <Autocomplete
        filterOptions={(x) => x}
        style={{ position: "absolute", top: 10, left: 75, width: 600 }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.title
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add a location"
            fullWidth
            size="small"
            value={searchTerm}
          />
        )}
        onChange={(event, newValue: DiscoverItem | null) => {
          if (newValue) {
            dispatch(addSearchResult(newValue));
            map?.getViewModel().setLookAtData({
              position: new H.geo.Point(
                newValue.position.lat,
                newValue.position.lng
              ),
              zoom: 9,
            });
          }
        }}
        onInputChange={(event, newInputValue) => {
          setSearchTerm(newInputValue);
        }}
        options={allSearchResults}
        noOptionsText="No locations"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  {option.resultType === "park" ? (
                    <SvgIcon>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1841_47)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.9478 0.418905L7.89695 6.69675H16.8642C18.466 6.69675 19.7082 8.07669 19.7082 9.6836V17.3942H17.9645C17.7052 18.395 16.838 19.1655 15.7546 19.1655C14.6714 19.1655 13.8038 18.3952 13.5444 17.3942H5.37502V16.1442H6.80817V12.8427H5.62502V14.7722H4.37502V12.8427H0.0841675L4.9478 0.418905ZM5.62503 11.5927V9.45044H4.37503V11.5927H1.91588L5.05225 3.5811L6.80817 7.31892V11.5927H5.62503ZM8.05817 7.94675V16.1442H9.02722V12.0109H12.4963V16.1442H13.5444C13.8038 15.1432 14.6713 14.3727 15.7546 14.3727C16.8381 14.3727 17.7052 15.1433 17.9645 16.1442H18.4582V9.6836C18.4582 8.69247 17.7033 7.94675 16.8642 7.94675H8.05817ZM11.2463 16.1442V13.2609H10.2772V16.1442H11.2463ZM14.0201 9.64902H16.9344V10.899H14.0201V9.64902ZM15.7546 15.6227C15.2166 15.6227 14.7149 16.099 14.7149 16.7692C14.7149 17.4393 15.2165 17.9155 15.7546 17.9155C16.2925 17.9155 16.7939 17.4395 16.7939 16.7692C16.7939 16.0987 16.2925 15.6227 15.7546 15.6227Z"
                            fill="#2A3241"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1841_47">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </SvgIcon>
                  ) : (
                    <LocationOnIcon sx={{ color: "text.secondary" }} />
                  )}
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  <Box component="span">{option.title}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option.address.label}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 2, position: "absolute", top: 11.25, left: 700, width: 600 }}
      >
        <Button
          variant="outlined"
          onClick={() => dispatch(toggleCampgroundVisibility())}
          startIcon={
            campgroundsVisible ? <VisibilityIcon /> : <VisibilityOffIcon />
          }
        >
          {campgroundsVisible ? "Hide" : "Show"} Campgrounds
        </Button>
        <Button
          variant="outlined"
          onClick={() => dispatch(toggleIncidentVisibility())}
          startIcon={
            incidentsVisible ? <VisibilityIcon /> : <VisibilityOffIcon />
          }
        >
          {incidentsVisible ? "Hide" : "Show"} Incidents
        </Button>
      </Stack>
    </AppBar>
  );
};
