// AdjustValueComponent.js
import { SidebarContainer } from "map/Sidebar/SidebarContainer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "stores/hooks";
import { isBusZonesVisible, toggleVisibility } from "stores/busZones";

export const OpenBusZones: React.FC = () => {
  const isZonesVisible = useAppSelector(isBusZonesVisible);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isZonesVisible) {
      dispatch(toggleVisibility());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SidebarContainer />;
};
