import React from "react";
import { Button, Container, Stack } from "@mui/material";
import Tools from "tools/Tools";
import { AuthContext } from "stores/AuthContext";

export const SidebarContainer: React.FC = () => {
  const { keycloak, logout } = React.useContext(AuthContext);
  const token = keycloak?.token;

  return (
    <Container fixed maxWidth="sm" disableGutters sx={{ ml: 0, p: 6 }}>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Button
          variant="outlined"
          onClick={() => {
            if (token) {
              navigator.clipboard.writeText(token);
              window.alert("Keycloak token copied to clipboard!");
            } else {
              window.alert("No Keycloak token available!");
            }
          }}
        >
          Get Keycloak Token
        </Button>
        <Button variant="outlined" onClick={logout}>
          Logout
        </Button>
      </Stack>
      <Tools />
    </Container>
  );
};
