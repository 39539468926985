/*
 (c) 2017, Vladimir Agafonkin
 Simplify.js, a high-performance JS polyline simplification library
 mourner.github.io/simplify-js
*/

(function () {

	// to suit your point format, run search/replace for '[0]' and '[1]';
	// for 3D version, see 3d branch (configurability would draw significant performance overhead)

	// square distance between 2 points
	function getSqDist(p1, p2) {
		var dx = p1[0] - p2[0],
			dy = p1[1] - p2[1];

		return dx * dx + dy * dy;
	}

	// square distance from a point to a segment
	function getSqSegDist(p, p1, p2) {
		var x = p1[0],
			y = p1[1],
			dx = p2[0] - x,
			dy = p2[1] - y;

		if (dx !== 0 || dy !== 0) {
			var t = ((p[0] - x) * dx + (p[1] - y) * dy) / (dx * dx + dy * dy);

			if (t > 1) {
				x = p2[0];
				y = p2[1];
			} else if (t > 0) {
				x += dx * t;
				y += dy * t;
			}
		}

		dx = p[0] - x;
		dy = p[1] - y;

		return dx * dx + dy * dy;
	}
	// rest of the code doesn't care about point format

	// basic distance-based simplification
	function simplifyRadialDist(points, sqTolerance) {
		var prevPoint = points[0],
			newPoints = [prevPoint],
			point;

		for (var i = 1, len = points.length; i < len; i++) {
			point = points[i];

			if (getSqDist(point, prevPoint) > sqTolerance) {
				newPoints.push(point);
				prevPoint = point;
			}
		}

		if (prevPoint !== point) newPoints.push(point);

		return newPoints;
	}

	function simplifyDPStep(points, first, last, sqTolerance, simplified) {
		var maxSqDist = sqTolerance,
			index;

		for (var i = first + 1; i < last; i++) {
			var sqDist = getSqSegDist(points[i], points[first], points[last]);

			if (sqDist > maxSqDist) {
				index = i;
				maxSqDist = sqDist;
			}
		}

		if (maxSqDist > sqTolerance) {
			if (index - first > 1)
				simplifyDPStep(points, first, index, sqTolerance, simplified);
			simplified.push(points[index]);
			if (last - index > 1)
				simplifyDPStep(points, index, last, sqTolerance, simplified);
		}
	}

	// simplification using Ramer-Douglas-Peucker algorithm
	function simplifyDouglasPeucker(points, sqTolerance) {
		var last = points.length - 1;

		var simplified = [points[0]];
		simplifyDPStep(points, 0, last, sqTolerance, simplified);
		simplified.push(points[last]);

		return simplified;
	}

	// both algorithms combined for awesome performance
	function simplify(points, tolerance, highestQuality) {
		if (points.length <= 2) return points;

		// The points array may contain lat/lng tuples or Point objects. If the
		// array contains objects, convert it to an array of tuples, where each
		// tuple contains the original object as a third value.
		const usesObjects = !Array.isArray(points[0]);
		if (usesObjects) {
			points = points.map(p => [p.x, p.y, p]);
		}

		var sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;

		points = highestQuality
			? points
			: simplifyRadialDist(points, sqTolerance);
		points = simplifyDouglasPeucker(points, sqTolerance);

		if (usesObjects) {
			// Return the original point objects corresponding to the newly
			// simplified list
			return points.map(p => p[2]);
		}

		return points;
	}

	module.exports = simplify;
	module.exports.default = simplify;
})();