import React from "react";
import {
  addDepartureWaypoints,
  addDestination,
  addEditedDepartureWaypoints,
  addEditedWaypointApproach,
  addOrigin,
  addWaypointApproach,
  removeSearchResult,
  selectSearchResult,
} from "stores/routing";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import Marker from "map/elements/Marker";
import { Button, Stack, Typography } from "@mui/material";
import InfoBubble from "map/elements/InfoBubble";
import { useHereMap } from "map/Context";
import axios from "axios";
import { MapObjectEvent } from "map/types";
import { createIcon } from "map/util/createIcon";
import CampgroundIcon from "assets/svg/park-marker.svg";
import { DiscoverItem } from "tools/Autocomplete";
import { encode, DEFAULT_PRECISION, ABSENT } from "util/flexible-polyline";

const SearchedParkMarker: React.FC<{ park: DiscoverItem }> = ({ park }) => {
  const dispatch = useAppDispatch();
  const { map } = useHereMap();
  const [parkInfo, setParkInfo] = React.useState<ParkResponse | null>(null);
  const onInfocardOpen = async () => {
    if (Number.isNaN(park.id)) {
      return;
    }
    if (parkInfo) {
      return;
    }
    console.log(process.env.NODE_ENV);
    const axiosInstance = axios.create({
      baseURL:
        process.env.NODE_ENV === "development"
          ? "https://cgr-api.dev-rvlife.com/parks/"
          : "https://cgr-api.rvlife.com/parks/", // I think this endpoint isn't set up in prod yet though.
      headers: { accept: "application/x.rvparkreviews.v2+json, */*; q=0.01" },
    });
    const response = await axiosInstance.get<ParkResponse>(
      `${park.id}?include=waypoint_approaches,waypoint_departures`
    );

    const json = response.data;
    setParkInfo(json);
  };
  const markerNode = (
    <InfoBubble onOpen={onInfocardOpen} maxHeight={1200} maxWidth={1200}>
      <Stack
        spacing={2}
        sx={{
          maxHeight: 500,
          maxWidth: 800,
          overflow: "auto",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {park.title}
        </Typography>
        <Stack spacing={2} sx={{ maxWidth: 250 }}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              dispatch(addOrigin(park.position));
            }}
          >
            Add as origin
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              dispatch(addDestination(park.position));
            }}
          >
            Add as destination
          </Button>
        </Stack>

        {parkInfo && (
          <>
            <Typography variant="body1">Waypoint Approaches</Typography>

            <Typography variant="body2">
              {parkInfo.waypoint_approaches.map((approach) =>
                JSON.stringify(approach, null, 2)
              )}
            </Typography>
            {parkInfo.waypoint_approaches.length > 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  dispatch(
                    addWaypointApproach(parkInfo.waypoint_approaches[0])
                  );

                  map?.zoomAt(12, park.position.lat, park.position.lng);
                  dispatch(addDestination(park.position));
                }}
              >
                Display Waypoint Approach on Map
              </Button>
            )}
            {parkInfo.waypoint_approaches.length === 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  const currentTime = new Date().toISOString();
                  const newWaypointApproach: WaypointApproach = {
                    created_at: currentTime,
                    updated_at: currentTime,
                    description: null,
                    park_id: Number(park.id),
                    direction: "Default",
                    id: 0, // not sure how to generate id for this
                    user_id: 0, // same here
                    polyline: encode({
                      precision: DEFAULT_PRECISION,
                      thirdDim: ABSENT,
                      thirdDimPrecision: 0,
                      polyline: [],
                    }),
                  };
                  dispatch(addWaypointApproach(newWaypointApproach));
                  dispatch(addEditedWaypointApproach(newWaypointApproach));

                  map?.getViewModel().setLookAtData({
                    zoom: 16,
                  });
                  dispatch(addDestination(park.position));
                }}
              >
                Add Waypoint Approach
              </Button>
            )}

            <Typography variant="body1">Departure Waypoints</Typography>

            <Typography variant="body2">
              {parkInfo.waypoint_departures.map((departure) =>
                JSON.stringify(departure, null, 2)
              )}
            </Typography>
            {parkInfo.waypoint_departures.length > 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  dispatch(
                    addDepartureWaypoints(parkInfo.waypoint_departures[0])
                  );

                  map?.getViewModel().setLookAtData({
                    zoom: 16,
                  });
                  dispatch(addOrigin(park.position));
                }}
              >
                Display Departure Waypoints on Map
              </Button>
            )}
            {parkInfo.waypoint_departures.length === 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  const currentTime = new Date().toISOString();
                  const newDepartureWaypoints: WaypointApproach = {
                    created_at: currentTime,
                    updated_at: currentTime,
                    description: null,
                    park_id: Number(park.id),
                    direction: "Default",
                    id: 0, // not sure how to generate id for this
                    user_id: 0, // same here
                    polyline: encode({
                      precision: DEFAULT_PRECISION,
                      thirdDim: ABSENT,
                      thirdDimPrecision: 0,
                      polyline: [],
                    }),
                  };
                  dispatch(addDepartureWaypoints(newDepartureWaypoints));
                  dispatch(addEditedDepartureWaypoints(newDepartureWaypoints));

                  map?.getViewModel().setLookAtData({
                    zoom: 16,
                  });
                  dispatch(addOrigin(park.position));
                }}
              >
                Add Departure Waypoints
              </Button>
            )}
          </>
        )}
      </Stack>
    </InfoBubble>
  );
  const onPointerEnter = (event: MapObjectEvent) => {
    if (map) map.getViewPort().element.style.cursor = "pointer";
  };
  const onPointerLeave = (event: MapObjectEvent) => {
    if (map) map.getViewPort().element.style.cursor = "";
  };
  return (
    <Marker
      position={park.position}
      icon={createIcon({
        iconUrl: CampgroundIcon,
        iconSize: [30, 35],
      })}
      popup={markerNode}
      key={park.id}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    />
  );
};

const SearchedPlaceMarker: React.FC<{ place: DiscoverItem }> = ({ place }) => {
  const dispatch = useAppDispatch();
  const markerNode = (
    <InfoBubble closeButton={false} maxWidth={450}>
      <div>
        {place.title}
        <Button
          onClick={() => {
            dispatch(addOrigin(place.position));
            dispatch(removeSearchResult());
          }}
        >
          Add as origin
        </Button>
        <Button
          onClick={() => {
            dispatch(addDestination(place.position));
            dispatch(removeSearchResult());
          }}
        >
          Add as destination
        </Button>
      </div>
    </InfoBubble>
  );
  return <Marker position={place.position} popup={markerNode} />;
};

const SearchResult: React.FC = () => {
  const searchResult = useAppSelector(selectSearchResult);
  if (searchResult) {
    if (searchResult.resultType === "park") {
      return <SearchedParkMarker key={searchResult.id} park={searchResult} />;
    } else {
      return <SearchedPlaceMarker key={searchResult.id} place={searchResult} />;
    }
  }
  return null;
};

export default SearchResult;
