import { Button, Stack, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "stores/AuthContext";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import {
  discardChanges,
  saveChanges,
  deleteBusZone,
  getEditedBusPolygon,
  setEditedBusZone,
  deleteBusPolygonVertex,
} from "stores/busZones";
import ConfirmationPopup from "support/components/Confirmation";
import { formatPoint, isSelfIntersecting } from "../AvoidArea/utils.avoidarea";
import { IBusZone } from "stores/types";
import { decode } from "util/flexible-polyline";

interface IBusZoneFormValues {
  zoneName: string;
}

const BusPolygonForm: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = React.useState(false);

  const editedBusPolygon = useAppSelector(getEditedBusPolygon);
  const { keycloak } = React.useContext(AuthContext);

  const defaultValues: IBusZoneFormValues = {
    zoneName: "New Bus Routing Polygon",
  };

  if (editedBusPolygon) {
    (Object.keys(defaultValues) as Array<keyof IBusZoneFormValues>).forEach(
      (key) => {
        if (key in editedBusPolygon) {
          const value = editedBusPolygon[key as keyof IBusZone];

          (defaultValues[key] as typeof value) = value;
        }
      }
    );
  }

  const { handleSubmit, control, reset } = useForm({ defaultValues });

  React.useEffect(() => {
    if (editedBusPolygon) {
      (Object.keys(defaultValues) as Array<keyof IBusZoneFormValues>).forEach(
        (key) => {
          if (key in editedBusPolygon) {
            const value = editedBusPolygon[key as keyof IBusZone];
            (defaultValues[key] as typeof value) = value;
          }
        }
      );
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedBusPolygon]);

  if (!editedBusPolygon) {
    return;
  }

  const coordinates = decode(editedBusPolygon.polyline);

  const onSubmit = async (formData: IBusZoneFormValues) => {
    if (coordinates) {
      if (coordinates.polyline.length < 3) {
        alert("Bus polygon must have at least 3 coordinates.");
        return;
      }

      if (isSelfIntersecting(coordinates.polyline)) {
        alert("Bus zone cannot intersect itself.");
        return;
      }
      const newBusZone = { ...editedBusPolygon };

      newBusZone.zoneName = formData.zoneName;

      dispatch(setEditedBusZone(newBusZone));

      if (keycloak?.token) dispatch(saveChanges(keycloak.token));
    }
  };

  const discardGridChanges = () => {
    dispatch(discardChanges());
  };

  const formattedCoordinates = coordinates?.polyline.map((coord) =>
    formatPoint({ lat: coord[0], lng: coord[1] })
  );

  const coordinateFields = formattedCoordinates?.map((coord, index) => (
    <Stack direction="row" key={coord}>
      <TextField
        key={index}
        label={`Coordinate ${index + 1}`}
        value={coord}
        disabled
        fullWidth
      />
      <Button
        onClick={() => {
          dispatch(deleteBusPolygonVertex(index));
        }}
        variant="outlined"
        sx={{ m: 1 }}
      >
        <DeleteIcon />
      </Button>
    </Stack>
  ));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2}>
        {coordinateFields}
        <Controller
          name="zoneName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField label="Name" error={!!error} {...field} />
          )}
          rules={{ required: true }}
        />

        <React.Fragment>
          <Button variant="outlined" onClick={discardGridChanges}>
            Discard Changes
          </Button>
        </React.Fragment>

        <Button variant="outlined" type="submit">
          Save Changes
        </Button>
        <Button variant="outlined" onClick={() => setIsDeleting(true)}>
          Delete
        </Button>
        <ConfirmationPopup
          isOpen={isDeleting}
          onDelete={() => {
            if (keycloak?.token)
              dispatch(deleteBusZone(editedBusPolygon.id, keycloak?.token));
          }}
          onCancel={() => {
            setIsDeleting(false);
          }}
        />
      </Stack>
    </form>
  );
};

export default BusPolygonForm;
