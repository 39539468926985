import axios from "axios";
import _ from "lodash";
import { getPinsURL } from "util/url";
// const baseUrl = "https://cgr-api.rvlife.com";

function formatParkQuery(zoom: number, bbox: H.geo.Rect) {
  const bounds = [
    [bbox.getBottom(), bbox.getLeft()],
    [bbox.getTop(), bbox.getRight()],
  ];
  // The API throws an error for values less than -180; the application is for the continental U.S.
  // so it doesn't need results past the international date line.
  const clampedLongitudeMin = Math.max(-180, bounds[0][1]);
  return (
    `latitude_min=${bounds[0][0]}` +
    `&longitude_min=${clampedLongitudeMin}` +
    `&latitude_max=${bounds[1][0]}` +
    `&longitude_max=${bounds[1][1]}` +
    `&zoom=${zoom}&preferred_only=0`
  );
}

// const loadClusteredParks = async (zoom: number, bounds: H.geo.Rect) => {
//   // The cluster search endpoint only accepts integer zooms up to 7
//   let localZoom = Math.round(zoom);
//   if (localZoom > 7) {
//     localZoom = 7;
//   }
//   const query = formatParkQuery(localZoom, bounds);
//   const url = `${baseUrl}/parks/search/cluster?${query}`;
//   const response = await axios.get<ClusteredParks>(url, {
//     headers: {
//       accept: "application/x.rvparkreviews.v1+json",
//     },
//   });
//   const json = response.data;
//   const parks = (json && json.parks) || [];
//   return parks.map((park) => ({
//     count: park.count,
//     lat: park.cenlat,
//     lng: park.cenlon,
//   }));
// };

/**
 *
 * @param id - park id that CGR will use
 * Checks to make sure id is a number, then uses that number to make the url for the xhr request
 * @returns json of the park
 */
const loadParks = async (
  zoom: number,
  bounds: H.geo.Rect,
  excludeList?: number[]
) => {
  const formattedExcludeList =
    excludeList && excludeList.length > 0 ? excludeList.join(",") : ``;
  const query = formatParkQuery(zoom, bounds);
  const url = `${getPinsURL()}/parks/search?exclude_list=${formattedExcludeList}&${query}`;
  const response = await axios.get<ParkQueryResponse>(url, {
    headers: {
      accept: "application/x.rvparkreviews.v2+json",
    },
  });

  const json = response.data;

  // Filter out any null park entries, the convert each ParkQueryResponse
  // to a CGRPark
  return json.parks;
};

export const searchCampgrounds = async (
  zoom: number,
  bounds: H.geo.Rect,
  excludeList?: number[]
): Promise<{
  clusters: ClusterData[];
  parks: ParkQueryResult[];
}> => {
  if (zoom < 8) {
    if (zoom > 1) {
      // const clusters = await loadClusteredParks(zoom, bounds);
      return { clusters: [], parks: [] };
    }
    return { clusters: [], parks: [] };
  } else {
    const parks = await loadParks(zoom, bounds, excludeList);
    return { clusters: [], parks };
  }
};

export const debouncedSearchCampgrounds = _.debounce(searchCampgrounds, 300);
