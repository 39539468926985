import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import React from "react";
import JSONPretty from "react-json-pretty";

interface CreatedRoadReportModalProps {
  open: boolean;
  onClose: () => void;
  response: string;
}

const CreatedRoadReportModal: React.FC<CreatedRoadReportModalProps> = ({
  open,
  onClose,
  response,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Response: "}</DialogTitle>
      <JSONPretty data={response} />
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatedRoadReportModal;
