import { Paper, Typography, Grid, CircularProgress } from "@mui/material";
import AnimatedNumber from "map/elements/AnimatedNumber";

interface NumberCardProps {
  name: string;
  number?: number;
}

const NumberCard: React.FC<NumberCardProps> = ({ name, number }) => {
  return (
    <Grid item xs={6}>
      <Paper
        sx={{
          padding: 2,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          {number ? (
            <AnimatedNumber targetValue={number} />
          ) : (
            <CircularProgress />
          )}
        </Typography>
        <Typography variant="body1">{name}</Typography>
      </Paper>
    </Grid>
  );
};

export default NumberCard;
