import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SonarAPI from "sonar/SonarAPI";
import { AuthContext } from "stores/AuthContext";
import { RoadReport } from "sonar/types/RoadReport";

interface StillThereDialogProps {
  stillThereRoadReport: any;
  onClose: () => void;
}

const StillThereDialog: React.FC<StillThereDialogProps> = ({
  stillThereRoadReport,
  onClose,
}) => {
  const [roadReport, setRoadReport] = useState<RoadReport | null>(null);

  useEffect(() => {
    setRoadReport(stillThereRoadReport);
  }, [stillThereRoadReport]);

  const headerStyle = {
    fontWeight: "bold",
  };

  const authContext = React.useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is not available");
  }
  const { keycloak } = authContext;
  const sonarApi = new SonarAPI(keycloak?.token ?? null);

  const handleStillThereDelete = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      // Perform the delete operation
      if (stillThereRoadReport?.id) {
        await sonarApi.deleteStillThere(stillThereRoadReport?.id as String, id);

        let result = await sonarApi.getById(stillThereRoadReport.id);
        console.log("delete still there", result);
        setRoadReport(result);
      }
    }
  };

  const addStillThere = async (uuid: String | null) => {
    // Perform the add operation
    console.log("Add still there entry");
    if (uuid) {
      await sonarApi.addStillThere(uuid, 0);
      let result = await sonarApi.getById(uuid);
      console.log("add still there", result);
      setRoadReport(result);
    }
  };

  return (
    <Dialog
      open={stillThereRoadReport !== undefined}
      onClose={() => {
        setRoadReport(null);
        onClose();
      }}
    >
      <DialogTitle>Still There Entries</DialogTitle>
      <DialogContent>
        {roadReport && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={headerStyle}>Road Report ID</TableCell>
                <TableCell style={headerStyle}>Entry ID</TableCell>
                <TableCell style={headerStyle}>Timestamp</TableCell>
                <TableCell style={headerStyle}>Submitted By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roadReport?.still_there.map((entry: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{entry.roadReport_id}</TableCell>
                  <TableCell>{entry.id}</TableCell>
                  <TableCell>
                    {new Intl.DateTimeFormat("default", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    }).format(new Date(entry.recorded_at))}
                  </TableCell>
                  <TableCell>{entry.recorded_by}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleStillThereDelete(entry.id)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => addStillThere(stillThereRoadReport?.id as string)}>
          Add an Entry
          <AddIcon />
        </Button>
        <Button onClick={() => setRoadReport(null)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StillThereDialog;
