import Destination from "./Destination";
import Origin from "./Origin";
import SearchResult from "./SearchResult";
import RealTime from "./RealTime";

const Markers: React.FC = () => (
  <>
    <Origin />
    <RealTime />
    <Destination />
    <SearchResult />
  </>
);

export default Markers;
