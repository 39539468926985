export function getURL() {
  let BASE_URL = "";
  const { NODE_ENV } = process.env;

  let modifiedNodeEnv = NODE_ENV as any;

  if (modifiedNodeEnv === "local") {
    BASE_URL = "http://127.0.0.1:3001";
  } else if (modifiedNodeEnv === "development") {
    BASE_URL = "https://routing.dev-rvlife.com";
  } else if (modifiedNodeEnv === "staging") {
    BASE_URL = "https://routing.staging-rvlife.com";
  } else {
    BASE_URL = "https://routing.rvlife.com";
  }
  return BASE_URL;
}
export function getPinsURL() {
  let BASE_URL = "";
  const { NODE_ENV } = process.env;

  let modifiedNodeEnv = NODE_ENV as any;

  if (modifiedNodeEnv === "development") {
    BASE_URL = "https://pins.dev-rvlife.com";
  } else if (modifiedNodeEnv === "staging") {
    BASE_URL = "https://pins.staging-rvlife.com";
  } else {
    BASE_URL = "https://pins.rvlife.com";
  }
  return BASE_URL;
}
