// Cache any generated canvas icons. Better performance can be achieved by
// re-using icons wherever possible.
// (https://developer.here.com/documentation/maps/3.1.30.12/dev_guide/topics/best-practices.html)
const iconCache = new Map<string, H.map.Icon>();

/**
 * Setup a canvas element to draw a glow around an icon
 */
function setGlowOptions(options: IconOptions, ctx: CanvasRenderingContext2D) {
	const opacity = typeof options.glow === 'number' ? options.glow : 1;
	ctx.shadowColor = `rgba(${options.active ? 255 : 192}, 0, 255, ${opacity})`;
	ctx.shadowBlur = 20;
}
export type IconOptions = {
  iconUrl: string;
  iconSize: [w: number, h: number] | { w: number; h: number };
  /**
   * If true, apply a full strength glow effect. If a number, a percent
   * strength for the glow effect.
   */
  glow?: boolean | number;
  /**
   * If true, add a full strength "active" glow around the icon. Use the
   * `glow` property to reduce the glow intensity.
   */
  active?: boolean;
  offsetX?: number;
  offsetY?: number;
};

/**
 * Convert a size to a { w, h } object
 */
function toSizeObj(size: [w: number, h: number] | { w: number; h: number }) {
  if (Array.isArray(size)) {
    return { w: size[0], h: size[1] };
  }
  return size;
}

export function createIcon(options: IconOptions): H.map.Icon {
  const key = JSON.stringify(options);

  const cachedIcon = iconCache.get(key);
  if (cachedIcon) {
    return cachedIcon;
  }

  const iconOpts: H.map.Icon.Options = {};

  // Create a canvas to draw the icon image into. This allows the image to be
  // modified using canvas operations.
  const canvas = document.createElement("canvas");

  const size = toSizeObj(options.iconSize);

  // The amount of padding to account for an icon glow effect. This isn't
  // directly tied to pixels. A higher value means a bigger glow.
  const glowSize = options.glow ? 30 : 0;

  // iconOpts.size will be the rendered size of the canvas. Add some room
  // for an optional glow.
  iconOpts.size = {
    w: size.w + glowSize + Math.abs(options.offsetX ?? 0),
    h: size.h + glowSize + Math.abs(options.offsetY ?? 0),
  };

  // Adjust the anchor point of the icon (the specific point that's
  // positioned on the map) to account for the glow padding
  iconOpts.anchor = new H.math.Point(
    iconOpts.size.w / 2,
    iconOpts.size.h - glowSize / 2
  );

  // The canvas width and height defines the coordinates the icon is
  // drawn in. This width and height will be scaled to fit in
  // iconOpts.size.
  canvas.width = iconOpts.size.w * window.devicePixelRatio;
  canvas.height = iconOpts.size.h * window.devicePixelRatio;

  // Load the icon image and draw it into the canvas, potentially adding a
  // glow or making other visual updates.
  const img = new Image();
  img.crossOrigin = "anonymous";
  img.src = options.iconUrl?.startsWith("http")
    ? `${options.iconUrl}?nocache`
    : options.iconUrl;
  img.onload = () => {
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      console.warn(`Unable to get canvas context to draw ${options.iconUrl}`);
      return;
    }

    // Space to leave around the edge of the icon image.
    const inset = (glowSize / 2) * window.devicePixelRatio;
    const imgWidth = size.w * window.devicePixelRatio;
    const imgHeight = size.h * window.devicePixelRatio;

    const drawParams: Parameters<typeof ctx.drawImage> = [
      img,
      0,
      0,
      img.width,
      img.height,
      inset,
      inset,
      imgWidth,
      imgHeight,
    ];

    if (options.glow || options.active) {
      setGlowOptions(options, ctx);
    }

    ctx.drawImage(...drawParams);
  };

  const icon = new H.map.Icon(canvas, iconOpts);
  iconCache.set(key, icon);

  return icon;
}
