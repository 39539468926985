import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { GroupInfo, GroupProviderProps, GroupProps } from "./types";
import { useHereMap } from "./Context";

const GroupContext = createContext<GroupInfo>({
  group: undefined,
  addObject: () => undefined,
  removeObject: () => undefined,
});

export const GroupProvider: React.FC<GroupProviderProps> = ({
  value: { group, addObject, removeObject },
  children = undefined,
}: GroupProviderProps) => {
  const { map, ui } = useHereMap();

  map?.addEventListener("dragstart", function () {
    ui?.getBubbles().forEach((bubble) => {
      bubble.close();
    });
  });

  const value: GroupInfo = useMemo(
    () => ({
      group,
      addObject:
        addObject ??
        ((obj, layer) => {
          if (layer) {
            throw new Error(
              [
                `Tried to add object to layer "${layer}" in a group.`,
                "Only direct children of the map may be added to layers.",
              ].join(" ")
            );
          }
          group?.addObject(obj);
        }),
      removeObject:
        removeObject ??
        ((obj, layer) => {
          if (layer) {
            throw new Error(
              [
                `Tried to remove object from layer "${layer}" in a group.`,
                "Only direct children of the map may be added to layers.",
              ].join(" ")
            );
          }
          group?.removeObject(obj);
        }),
    }),
    [addObject, group, removeObject]
  );

  return (
    <GroupContext.Provider value={value}>{children}</GroupContext.Provider>
  );
};

export function useGroupContext(): GroupInfo {
  return useContext(GroupContext);
}

const Group: React.FC<GroupProps> = ({
  layer = undefined,
  onPointerMove = undefined,
  volatile = undefined,
  children = undefined,
}: GroupProps) => {
  const { addObject, removeObject } = useGroupContext();
  const [group, setGroup] = useState<H.map.Group>();

  const contextValue = useMemo(() => ({ group }), [group]);

  useEffect(() => {
    if (addObject && removeObject) {
      const grp = new H.map.Group();
      addObject(grp, layer);

      setGroup(grp);

      return () => {
        setGroup(undefined);
        removeObject(grp, layer);
        grp.dispose();
      };
    }
    return () => undefined;
  }, [addObject, layer, removeObject]);

  useEffect(() => {
    if (group && onPointerMove) {
      group.addEventListener("pointermove", onPointerMove);
      return () => {
        group.removeEventListener("pointermove", onPointerMove);
      };
    }
    return () => undefined;
  }, [group, onPointerMove]);

  useEffect(() => {
    if (!group || volatile === undefined) {
      return;
    }

    group.setVolatility(volatile);
  }, [group, volatile]);

  return <GroupProvider value={contextValue}>{children}</GroupProvider>;
};

export default Group;
