import { Box, Checkbox, Typography } from "@mui/material";
import { useHereMap } from "map/Context";
import React, { useEffect } from "react";

export type LegendEntry = {
  key: number;
  color: string;
  title: string;
  visible: boolean;
};

const LegendMapMount: React.FC<{
  legendItems: LegendEntry[];
  onToggle: (key: number) => void;
}> = ({ legendItems, onToggle }) => {
  const legendsContainer = React.useRef(null);

  const { ui } = useHereMap();

  useEffect(() => {
    const legends = new H.ui.Control();
    legends.setAlignment(H.ui.LayoutAlignment.RIGHT_BOTTOM);
    ui?.addControl("legends", legends);
    if (legendsContainer.current)
      legends.getElement()?.appendChild(legendsContainer.current);
    return () => {
      ui?.removeControl("legends");
    };
  }, [ui]);

  return (
    <div ref={legendsContainer}>
      <Box
        sx={{
          borderWidth: 1,
          borderColor: "black",
          bgcolor: "white",
          p: 1, // Assuming the theme's spacing unit is 8px, 1 unit * 8px = 8px
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: "bold" }}>
          Legend
        </Typography>
        {legendItems.map((it) => (
          <Box
            key={it.key}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center", // To align items vertically in the center (if needed)
              padding: "4px", // Assuming 4px is the desired padding
            }}
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor: it.color,
              }}
            />
            <Checkbox checked={it.visible} onChange={() => onToggle(it.key)} />
            <Typography
              variant="caption"
              sx={{
                ml: 1, // Assuming the theme's spacing unit is 8px, 1 unit * 8px = 8px for margin start
              }}
            >
              {it.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default LegendMapMount;
