import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import StillThereDialog from "./StillThereDialog";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { HazardType } from "sonar/types/CreateRoadReport";
import { RoadReport } from "sonar/types/RoadReport";
import { metricToImperial } from "util/conversion";

interface UpdateRoadReportModalProps {
  roadReport?: RoadReport;
  onClose: () => void;
  open: boolean;
  onUpdate: (roadReport: RoadReport) => void;
  onDelete: (roadReport: RoadReport) => void;
}

const UpdateRoadReportModal: React.FC<UpdateRoadReportModalProps> = ({
  roadReport,
  onClose,
  open,
  onUpdate,
  onDelete,
}) => {
  const [newRoadReport, setNewRoadReport] = useState<RoadReport | undefined>(undefined);
  const [stillThereRoadReport, setStillThereRoadReport] = useState<RoadReport | undefined>(
    undefined
  );
  const [originalRoadReport, setOriginalRoadReport] = useState<RoadReport | undefined>(undefined);

  useEffect(() => {
    setNewRoadReport(roadReport);
    setOriginalRoadReport(roadReport);
  }, [roadReport]);

  const handleChange = (event: { target: { name: any; value: any } }) => {
    // Check if the field is a number
    if (!isNaN(event.target.value)) {
      //@ts-ignore
      setNewRoadReport({
        ...newRoadReport,
        [event.target.name]: parseInt(event.target.value),
      });
      return;
    }

    //@ts-ignore
    setNewRoadReport({
      ...newRoadReport,
      [event.target.name]: event.target.value,
    });
  };

  const handleStillThereClick = (roadReport: RoadReport) => {
    setStillThereRoadReport(roadReport);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">{"Update this road report?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <ReactDiffViewer
            oldValue={originalRoadReport}
            newValue={newRoadReport}
            splitView={true}
            compareMethod={DiffMethod.JSON}
          />
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        {Object.keys(newRoadReport || {}).map((key) => {
          switch (key) {
            case "still_there":
              return (
                <Button
                  onClick={() => {
                    if (roadReport) handleStillThereClick(roadReport);
                  }}
                >
                  Still There
                </Button>
              );
            case "id":
              return <></>;
            case "lon":
              return <></>;
            case "lat":
              return <></>;
            case "created_at":
              return <></>;
            case "rv_type":
              return (
                <Select
                  key={key}
                  name={key}
                  // @ts-ignore
                  value={newRoadReport[key]}
                  onChange={handleChange}
                  label="RV Type"
                >
                  <MenuItem value={""}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Motorhome"}>Motorhome</MenuItem>
                  <MenuItem value={"Travel Trailer"}>Travel Trailer</MenuItem>
                  <MenuItem value={"Fifth Wheel"}>Fifth Wheel</MenuItem>
                  <MenuItem value={"Toy Hauler"}>Toy Hauler</MenuItem>
                  <MenuItem value={"Tent Trailer"}>Tent Trailer</MenuItem>
                  <MenuItem value={"Truck Camper"}>Truck Camper</MenuItem>
                </Select>
              );
            case "hazard_type":
              return (
                <Select
                  key={key}
                  name={key}
                  // @ts-ignore
                  value={newRoadReport[key]}
                  onChange={handleChange}
                  label="Hazard Type"
                >
                  {Object.entries(HazardType).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {" "}
                      {key}{" "}
                    </MenuItem>
                  ))}
                </Select>
              );
            default:
              if (["rv_height", "rv_length", "rv_weight"].includes(key)) {
                const labelDetails: { [key: string]: string } = {
                  rv_height: "Height (centimeters)",
                  rv_length: "Length (centimeters)",
                  rv_weight: "Weight (kilograms)",
                };

                const unit = key === "rv_weight" ? "kg" : "cm";
                const imperialValue = metricToImperial(
                  // @ts-ignore
                  newRoadReport ? newRoadReport[key] : 0,
                  unit
                );

                return (
                  <Tooltip key={key} title={`(Imperial: ${imperialValue})`}>
                    <TextField
                      id={`outlined-number-${key}`}
                      label={labelDetails[key]}
                      type="number"
                      name={key}
                      // @ts-ignore
                      value={newRoadReport ? newRoadReport[key] : 0}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChange}
                    />
                  </Tooltip>
                );
              }
          }
          // @ts-ignore
          if (roadReport && roadReport[key] && typeof roadReport[key] == "number") {
            return (
              <TextField
                id="outlined-number"
                label={key}
                type="number"
                name={key}
                // @ts-ignore
                value={newRoadReport[key] ?? 0}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
              />
            );
          }
          return (
            <TextField
              key={key}
              name={key}
              label={key}
              // @ts-ignore
              value={newRoadReport[key]}
              onChange={handleChange}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          onClick={() => {
            if (newRoadReport) onUpdate(newRoadReport);
          }}
          autoFocus
        >
          UPDATE
        </Button>
        <Button
          onClick={() => {
            if (newRoadReport) {
              onDelete(newRoadReport);
              onClose();
            }
          }}
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
      <StillThereDialog
        stillThereRoadReport={stillThereRoadReport}
        onClose={() => setStillThereRoadReport(undefined)}
      />
    </Dialog>
  );
};

export default UpdateRoadReportModal;
