import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import {
  isBusZonesVisible,
  toggleVisibility,
  getEditedBusZone,
  discardChanges,
  createEditedBusZone,
  getEditedBusPolygon,
  createEditedBusPolygon,
} from "stores/busZones";

const BusZoneToolButtons: React.FC = () => {
  const dispatch = useAppDispatch();
  const busZonesVisible = useAppSelector(isBusZonesVisible);
  const editedBusBbox = useAppSelector(getEditedBusZone);
  const editedBusPolygon = useAppSelector(getEditedBusPolygon);

  const toggleBusZonesVisibility = () => {
    dispatch(toggleVisibility());
  };

  const setBusZonesCreating = () => {
    dispatch(createEditedBusZone());
  };

  const setBusZonesDiscarding = () => {
    dispatch(discardChanges());
  };

  const setBusPolygonCreating = () => {
    dispatch(createEditedBusPolygon());
  };

  const editedBusZones = editedBusBbox || editedBusPolygon;

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={toggleBusZonesVisibility}>
        {busZonesVisible ? "Hide " : "Show "} Bus Zones
      </Button>
      <Button
        variant="outlined"
        component={Link}
        to="/bus-zone-list"
        onClick={() => {
          if (!busZonesVisible) {
            toggleBusZonesVisibility();
          }
        }}
      >
        List Bus Zones
      </Button>
      {editedBusZones ? (
        <Button variant="outlined" onClick={setBusZonesDiscarding}>
          Discard Bus Zone
        </Button>
      ) : (
        <>
          <Button variant="outlined" onClick={setBusZonesCreating}>
            Add Bus Zone
          </Button>
          <Button variant="outlined" onClick={setBusPolygonCreating}>
            Add Bus Polygon
          </Button>
        </>
      )}
    </React.Fragment>
  );
};

export default BusZoneToolButtons;
