import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid";
import React from "react";
import { AuthContext } from "stores/AuthContext";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import {
  fetchGrid,
  isMapGridVisible,
  returnGrid,
  setEditedAvoidArea,
  toggleVisibility,
} from "stores/mapGrid";
import { IAvoidArea } from "stores/types";
import { metricToImperial } from "util/conversion";
import { decodeBboxFromPolyline, formatPoint } from "./utils.avoidarea";
import { useHereMap } from "map/Context";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import _ from "lodash";
import { decode } from "util/flexible-polyline";

const AvoidAreaList: React.FC<{
  open: boolean;
  setOpen(value: boolean): void;
}> = ({ open, setOpen }) => {
  const { keycloak } = React.useContext(AuthContext);
  const { map } = useHereMap();
  const mapGrid = useAppSelector(returnGrid);
  const dispatch = useAppDispatch();
  const isGridVisible = useAppSelector(isMapGridVisible);

  React.useEffect(() => {
    if (mapGrid.length === 0) {
      dispatch(fetchGrid(keycloak?.token ?? ""));
    }
    if (!open) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const avoidAreas = mapGrid
    .map((grid) => grid.avoidAreas)
    .flat()
    .filter((area) => !area.dateDeleted);

  const dataGridRef = useGridApiRef();

  const columns: GridColDef<IAvoidArea>[] = [
    {
      field: "id",
      headerName: "Actions",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <Button
              component={Link}
              to="/avoid-areas"
              startIcon={<EditIcon />}
              onClick={() => {
                dispatch(setEditedAvoidArea(_.cloneDeep(params.row)));
                const center = H.geo.LineString.fromFlexiblePolyline(
                  params.row.coordinates
                )
                  .getBoundingBox()
                  ?.getCenter();
                if (center) map?.setCenter(center);
                map?.setZoom(15);
                setOpen(false);
              }}
              variant="outlined"
              sx={{ mr: 1 }}
            >
              Edit
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                const center = H.geo.LineString.fromFlexiblePolyline(
                  params.row.coordinates
                )
                  .getBoundingBox()
                  ?.getCenter();
                if (center) map?.setCenter(center);
                map?.setZoom(15);
                setOpen(false);
                if (!isGridVisible) dispatch(toggleVisibility());
              }}
              variant="outlined"
            >
              View
            </Button>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
    },
    {
      field: "desc",
      headerName: "Description",
      width: 375,
      renderCell: (params) => (
        <div
          style={{
            display: "flex", // Enable Flexbox
            alignItems: "center", // Vertically center the content
            justifyContent: "left", // Align content to the left horizontally
            whiteSpace: "normal",
            wordWrap: "break-word",
            lineHeight: "16px",
            minHeight: 52,
            paddingBlock: 4,
          }}
        >
          {params.value}
        </div>
      ),
    },

    {
      field: "coordinates",
      headerName: "Coordinates",
      width: 400,
      renderCell: (params) => {
        const coordinates = decode(params.value).polyline.map((coord) =>
          formatPoint({ lat: coord[0], lng: coord[1] })
        );
        return (
          <div
            style={{
              display: "flex", // Enable Flexbox
              alignItems: "center", // Vertically center the content
              justifyContent: "left", // Align content to the left horizontally
              whiteSpace: "normal",
              wordWrap: "break-word",
              lineHeight: "16px",
              minHeight: 52,
              paddingBlock: 4,
            }}
          >
            {coordinates.join(", ")}
          </div>
        );
      },
    },
    { field: "zone", headerName: "Zone", width: 150 },
    { field: "state", headerName: "State", width: 150 },
    {
      field: "testCoordinates",
      headerName: "Test Coordinates",
      width: 400,
      renderCell: (params) => {
        if (params.value) {
          const { topLeft, bottomRight } = decodeBboxFromPolyline(params.value);
          return (
            <div>{`${formatPoint(topLeft)}, ${formatPoint(bottomRight)}`}</div>
          );
        }
        return <div>None</div>;
      },
    },
    {
      field: "length",
      headerName: "Length",
      width: 100,
      valueFormatter: (params) => metricToImperial(params.value, "cm"),
    },
    {
      field: "height",
      headerName: "Height",
      width: 100,
      valueFormatter: (params) => metricToImperial(params.value, "cm"),
    },
    {
      field: "width",
      headerName: "Width",
      width: 100,
      valueFormatter: (params) => metricToImperial(params.value, "cm"),
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 125,
      valueFormatter: (params) => metricToImperial(params.value, "kg"), // Assuming weight is in pounds
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 100,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (!params.value) return "N/A";
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
    },
    {
      field: "dateEdited",
      headerName: "Date Edited",
      width: 100,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (!params.value) return "N/A";
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "editedBy",
      headerName: "Edited By",
      width: 150,
    },
    {
      field: "dateDeleted",
      headerName: "Date Deleted",
      width: 100,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (!params.value) return "N/A";
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "deletedBy",
      headerName: "Deleted By",
      width: 150,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start", // Aligns items to the start of the cross axis (top), assuming LTR content flow
        p: 0, // No padding
      }}
    >
      <Stack
        direction="row"
        sx={{ my: 1, justifyContent: "flex-end", width: "100%" }}
      >
        <Button
          component={Link}
          to="/"
          onClick={() => {
            if (open) {
              setOpen(false);
            }
          }}
          sx={{ mr: 3 }}
          variant="outlined"
        >
          Return
        </Button>
      </Stack>
      <div
        style={{
          height: "calc(100vh - 121px)",
          width: "100%",
        }}
      >
        <DataGrid
          apiRef={dataGridRef}
          rows={avoidAreas}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
            sorting: {
              sortModel: [
                {
                  field: "dateCreated",
                  sort: "desc",
                },
              ],
            },
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          disableRowSelectionOnClick
          getRowHeight={() => {
            return "auto";
          }}
        />
      </div>
    </Box>
  );
};

export default AvoidAreaList;
