import React from "react";
import {
  Stack,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const RouteParametersSection: React.FC = () => {
  const { control } = useFormContext();

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Controller
          name="height"
          control={control}
          render={({ field }) => <TextField label="height (ft)" {...field} />}
        />
        <Controller
          name="length"
          control={control}
          render={({ field }) => <TextField label="length (ft)" {...field} />}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Controller
          name="width"
          control={control}
          render={({ field }) => <TextField label="width (ft)" {...field} />}
        />
        <Controller
          name="weight"
          control={control}
          render={({ field }) => <TextField label="weight (lbs)" {...field} />}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormControl fullWidth>
          <InputLabel id="traffic-label">Traffic</InputLabel>
          <Controller
            name="traffic"
            control={control}
            defaultValue="longterm"
            render={({ field }) => (
              <Select labelId="traffic-label" label="Traffic" {...field}>
                <MenuItem value="longterm">Longterm</MenuItem>
                <MenuItem value="disabled">Disabled</MenuItem>
                <MenuItem value="current">Current</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormControl fullWidth>
          <InputLabel id="alternatives-label">Alternatives</InputLabel>
          <Controller
            name="alternatives"
            control={control}
            defaultValue={0}
            render={({ field }) => (
              <Select
                labelId="alternatives-label"
                label="Alternatives"
                {...field}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Stack>
      <FormGroup row>
        {[
          { name: "dirtRoad", label: "Dirt Roads" },
          { name: "tunnel", label: "Tunnels" },
          { name: "tollRoad", label: "Toll Roads" },
          { name: "highway", label: "Highways" },
          { name: "ferry", label: "Ferries" },
          { name: "difficultTurns", label: "Difficult Turns (TESTING)" },
          { name: "uTurns", label: "U-Turns (TESTING)" },
          { name: "propane", label: "Propane" },
          { name: "segmentRefs", label: "Get Segment IDs" },
          { name: "compareParams", label: "Compare Parameters" },
          { name: "approachWaypoints", label: "Test Approach Waypoints" },
          { name: "departureWaypoints", label: "Test Departure Waypoints" },
        ].map(({ name, label }) => (
          <FormControlLabel
            key={name}
            control={
              <Controller
                name={name}
                control={control}
                render={({ field }) => <Checkbox {...field} />}
              />
            }
            label={label}
          />
        ))}
      </FormGroup>
    </Stack>
  );
};
