import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface CommentPolylinesState {
  intersection: string | null;
  buffered: string | null;
  comment: string | null;
}

const initialState: CommentPolylinesState = {
  intersection: null,
  buffered: null,
  comment: null,
};

const commentPolylinesSlice = createSlice({
  name: 'commentPolylines',
  initialState,
  reducers: {
    setCommentPolylines: (state, action: PayloadAction<{ intersection: string; buffered: string; comment: string }>) => {
      state.intersection = action.payload.intersection;
      state.buffered = action.payload.buffered;
      state.comment = action.payload.comment;
    },
    clearCommentPolylines: (state) => {
      state.intersection = null;
      state.buffered = null;
      state.comment = null;
    },
  },
});

export const { setCommentPolylines, clearCommentPolylines } = commentPolylinesSlice.actions;
export const selectCommentPolylines = (state: RootState) => state.commentPolylines;
export default commentPolylinesSlice.reducer; 