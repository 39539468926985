import React from "react";
import {
  addActiveDepartureWaypoint,
  selectActiveDepartureWaypoint,
  selectEditedDepartureWaypoints,
  selectDepartureWaypoints,
  selectOrigin,
} from "stores/routing";
import { useAppDispatch, useAppSelector } from "stores/hooks";
import WaypointPolyline from "../WaypointPolyline";

const DepartureWaypoints: React.FC = () => {
  const dispatch = useAppDispatch();
  const route = useAppSelector(selectDepartureWaypoints());
  const editedRoute = useAppSelector(selectEditedDepartureWaypoints());
  const activeDepartureWaypoint = useAppSelector(
    selectActiveDepartureWaypoint()
  );
  const onClick = (index: number) => {
    dispatch(addActiveDepartureWaypoint(index));
  };
  const origin = useAppSelector(selectOrigin);
  return (
    <WaypointPolyline
      route={route}
      editedRoute={editedRoute}
      activeWaypoint={activeDepartureWaypoint}
      onClick={onClick}
      color="rgba(0, 128, 128, 0.75)"
      lineDash={[3, 3]}
      origin={origin}
    />
  );
};

export default DepartureWaypoints;
