import React, { useEffect, useRef, useState } from "react";
import H from "@here/maps-api-for-javascript";
import GeoShape, { GeoShapeProps } from "./GeoShape";

type PolylineProps = Omit<GeoShapeProps, "shape"> & {
  positions?: string;
};

const Polyline: React.FC<PolylineProps> = ({ positions, ...shapeProps }) => {
  const [polyline, setPolyline] = useState<H.map.Polyline>();
  const positionsRef = useRef<string>();

  useEffect(() => {
    return () => {
      setPolyline(undefined);
      positionsRef.current = undefined;
    };
  }, []);

  useEffect(() => {
    if (!positions) {
      return;
    }

    if (polyline && positionsRef.current === positions) {
      return;
    }

    try {
      const linestring = H.geo.LineString.fromFlexiblePolyline(positions);
      if (!polyline) {
        setPolyline(new H.map.Polyline(linestring));
      } else {
        polyline.setGeometry(linestring);
      }
    } catch (error) {
      console.warn("Error creating Polyline with:", positions, error);
    }

    positionsRef.current = positions;
  }, [polyline, positions]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <GeoShape shape={polyline} {...shapeProps} />;
};

export default Polyline;
