import React, {  useState } from "react";
import { RoadReport } from "./types/RoadReport";
import { TextField, Button, Grid, Typography } from "@mui/material";

interface RoadReportEditorProps {
  roadReport: RoadReport;
  onSave: (updatedRoadReport: RoadReport) => void;
  onCancel: () => void;
}

const RoadReportEditor: React.FC<RoadReportEditorProps> = ({
  roadReport,
  onSave,
  onCancel,
}) => {
  const [editedRoadReport, setEditedRoadReport] = useState<Partial<RoadReport>>({});

  const handleChange = (field: keyof RoadReport, value: any) => {
    setEditedRoadReport((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = () => {
    console.log("save", editedRoadReport);

    onSave({
      id: roadReport.id,
      lat: editedRoadReport.lat ?? roadReport.lat,
      lon: editedRoadReport.lon ?? roadReport.lon,
      rv_height: editedRoadReport.rv_height ?? roadReport.rv_height,
      rv_length: editedRoadReport.rv_length ?? roadReport.rv_length,
      rv_type: editedRoadReport.rv_type ?? roadReport.rv_type,
      pm_user_id: editedRoadReport.pm_user_id ?? roadReport.pm_user_id,
      hazard_type: editedRoadReport.hazard_type ?? roadReport.hazard_type,
      rv_weight: editedRoadReport.rv_weight ?? roadReport.rv_weight,
      low_clearance_height: editedRoadReport.low_clearance_height ?? roadReport.low_clearance_height,
      weight_limit: editedRoadReport.weight_limit ?? roadReport.weight_limit,
      still_there: editedRoadReport.still_there ?? roadReport.still_there,
    });
  };

  console.log(roadReport?.lat);

  return (
    roadReport?.lat &&
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Edit Road Report</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Latitude"
          type="text"
          fullWidth
          value={editedRoadReport.lat ?? roadReport.lat}
          onChange={(e) => handleChange("lat", parseFloat(e.target.value))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Longitude"
          type="number"
          fullWidth
          value={editedRoadReport.lon ?? roadReport.lon}
          onChange={(e) => handleChange("lon", parseFloat(e.target.value))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="RV Height"
          type="number"
          fullWidth
          value={editedRoadReport.rv_height ?? roadReport.rv_height}
          onChange={(e) => handleChange("rv_height", parseFloat(e.target.value))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="RV Length"
          type="number"
          fullWidth
          value={editedRoadReport.rv_length ?? roadReport.rv_length}
          onChange={(e) => handleChange("rv_length", parseFloat(e.target.value))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="RV Type"
          fullWidth
          value={editedRoadReport.rv_type ?? roadReport.rv_type}
          onChange={(e) => handleChange("rv_type", e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="PM User ID"
          type="number"
          fullWidth
          value={editedRoadReport.pm_user_id ?? roadReport.pm_user_id}
          onChange={(e) => handleChange("pm_user_id", parseInt(e.target.value))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Hazard Type"
          fullWidth
          value={editedRoadReport.hazard_type ?? roadReport.hazard_type}
          onChange={(e) => handleChange("hazard_type", e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="RV Weight"
          type="number"
          fullWidth
          value={editedRoadReport.rv_weight ?? roadReport.rv_weight}
          onChange={(e) => handleChange("rv_weight", parseFloat(e.target.value))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Low Clearance Height"
          type="number"
          fullWidth
          value={editedRoadReport.low_clearance_height ?? roadReport.low_clearance_height}
          onChange={(e) => handleChange("low_clearance_height", parseFloat(e.target.value))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Weight Limit"
          type="number"
          fullWidth
          value={editedRoadReport.weight_limit ?? roadReport.weight_limit}
          onChange={(e) => handleChange("weight_limit", parseFloat(e.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="outlined" color="secondary" onClick={onCancel} style={{ marginLeft: 8 }}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default RoadReportEditor;
