import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RouteKey } from "stores/types";
import { selectVisibleRoutes, toggleRoute } from "stores/ui";
import { useAppDispatch, useAppSelector } from "stores/hooks";

const RouteMapBoxBox: React.FC<{
  route?: any;
  label?: string;
  id: RouteKey;
}> = ({ route, label, id }) => {
  const dispatch = useAppDispatch();
  const isRouteVisible = useAppSelector(selectVisibleRoutes(id));
  if (!route) {
    return null;
  }
  return (
    <Stack direction="row">
      <div>
        <Checkbox
          checked={isRouteVisible}
          onChange={() => dispatch(toggleRoute(id))}
        />
      </div>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>MapBox Route</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div id="car-panel">
            <Typography>Nothing to see here</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default RouteMapBoxBox;
