import React from "react";
import { IAvoidArea } from "stores/types";
import { Position } from "util/flexible-polyline";

export const AvoidAreaInfoBubbleContent: React.FC<{
  avoidance: IAvoidArea;
  coordinates?: Position[];
  onEditButtonClick?: () => void;
  onTestCoordinatesButtonClick?: () => void;
}> = ({
  avoidance,
  coordinates,
  onEditButtonClick,
  onTestCoordinatesButtonClick,
}) => (
  <div
    style={{
      border: 0,
      fontSize: 12,
      maxWidth: 600,
      minWidth: 300,
      maxHeight: 600,
      minHeight: 300,
      overflowWrap: "break-word",
    }}
  >
    <div>Name: {avoidance.name}</div>
    <br />
    <div>Description: {avoidance.desc ?? "none"}</div>
    <br />
    <div>
      Height: {avoidance.height === 0 ? "any" : avoidance.height}
      <br />
      Length: {avoidance.length === 0 ? "any" : avoidance.length}
      <br />
      Width: {avoidance.width === 0 ? "any" : avoidance.width}
      <br />
      Weight: {avoidance.weight === 0 ? "any" : avoidance.weight}
      <br />
      Test Coordinates: {coordinates}
      <br />
      Zone: {avoidance.zone}
    </div>
    <br />
    {onTestCoordinatesButtonClick && coordinates && (
      <button onClick={onTestCoordinatesButtonClick}>
        Set Origin/Dest to Test Coords
      </button>
    )}
    <br />
    {onEditButtonClick && (
      <button onClick={onEditButtonClick}>Edit Avoid Area</button>
    )}
  </div>
);
