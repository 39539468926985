import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RouteParams {
  dirtRoad: boolean;
  tollRoad: boolean;
  highway: boolean;
  tunnel: boolean;
  ferry: boolean;
  propane: boolean;
  height: string;
  length: string;
  width: string;
  weight: string;
  car: boolean;
  bus: boolean;
  truck: boolean;
  compareParams: boolean;
  approachWaypoints: boolean;
  departureWaypoints: boolean;
}

const initialState: RouteParams = {
  dirtRoad: false,
  tollRoad: false,
  highway: false,
  tunnel: false,
  ferry: false,
  propane: false,
  height: "",
  length: "",
  width: "",
  weight: "",
  car: true,
  bus: true,
  truck: true,
  compareParams: false,
  approachWaypoints: false,
  departureWaypoints: false,
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setFormValues(state, action: PayloadAction<Partial<RouteParams>>) {
      return { ...state, ...action.payload };
    },
    resetFormValues() {
      return initialState;
    },
  },
});

export const { setFormValues, resetFormValues } = formSlice.actions;
export default formSlice.reducer;