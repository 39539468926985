import { useAppSelector } from "stores/hooks";
import Marker from "map/elements/Marker";
import React from "react";
import { getRealTimePoints } from "stores/realTime";

const RealTime: React.FC = () => {
  const points = useAppSelector(getRealTimePoints());

  return (
    <div>
      {points.map((point) => (
        <Marker
          position={{
            lat: point.lat,
            lng: point.lng,
          }}
          volatile={true}
          icon={point.icon}
          key={point.lat}
        />
      ))}
    </div>
  );
};

export default RealTime;
