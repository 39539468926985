import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { makeStyles } from "@mui/styles";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { DateTime } from "luxon";

Chart.register(CategoryScale);

const useStyles = makeStyles((theme) => ({
  graphContainer: {
    width: "80%",
    margin: "auto",
    marginTop: 4,
    marginBottom: 4,
  },
}));

interface DataItem {
  hour: string;
  unique_route_hash_ids: number;
}

const Graph: React.FC<{ data: DataItem[] }> = ({ data }) => {
  const classes = useStyles();
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: any[];
  }>({ labels: [], datasets: [] });

  useEffect(() => {
    const formattedData = data.map((item) => {
      const dateTime = DateTime.fromISO(item.hour, { zone: "utc" }).setZone(
        "America/Chicago"
      );
      const formattedHour = dateTime.toFormat("h a");
      const formattedTime = `${formattedHour}`;
      return {
        hour: formattedTime,
        unique_route_hash_ids: item.unique_route_hash_ids,
      };
    });

    const labels = formattedData.map((item) => item.hour);
    const values = formattedData.map((item) => item.unique_route_hash_ids);

    setChartData({
      labels: labels,
      datasets: [
        {
          label: "Trips Per Hour - Last 24 Hours",
          data: values,
          fill: false,
          borderColor: "#3f51b5",
          tension: 0.4,
        },
      ],
    });
  }, [data]);

  return (
    <div className={classes.graphContainer}>
      <Line data={chartData} />
    </div>
  );
};

export default Graph;
